export const directBenefFields =   [
    {
      label: "Sector",
      placeholder: "Select Sector",
      name: "clusterId",
      id: "clusterId",
      inputType: "async-dropdown",
      // url: "/Cluster",
      searchAttrib: "clusterId",
      labelAttribute: "name",
      valueAttribute: "id",
      onChangeMethod: "dropdown-in-table",
      className: "px-2",
      isEnum: true,
      inForm: true,
      isMulti: false,
    },
    {
      label: "District",
      placeholder: "Select District",
      name: "districtId",
      id: "districtId",
      inputType: "async-dropdown",
      // url: "/District",
      searchAttrib: "Search.value",
      labelAttribute: "name",
      valueAttribute: "id",
      onChangeMethod: "dropdown-in-table",
      inForm: true,
      isMulti: false,
      className: "px-2",
      isEnum: true,

      //  options: [
      //     {
      //       label: "Rawalpindi",
      //       value: "1",
      //     },
      //     {
      //       label: "Mianwali",
      //       value: "2",
      //     },
      //   ],
    },

    {
      label: "Households",
      placeholder: "Enter Households",
      name: "houseHolds",
      onChangeMethod: "text-in-table",
      inForm: true,
      isEnum: true,
    },
    {
      label: "No of Male",
      placeholder: "Enter No of Male",
      name: "noOfMales",
      onChangeMethod: "text-in-table",
      inForm: true,
      isEnum: true,
    },
    {
      label: "No of Female",
      placeholder: "Enter No of Female",
      name: "noOfFemales",
      onChangeMethod: "text-in-table",
      inForm: true,
      isEnum: true,
    },
  ]