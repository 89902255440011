export const loginFields = [
  {
    name: "email",
    inputType: "email",
    type: "email",
    inForm: true,
    required: true,
    icon: "FaEnvelopeOpen",
    className: "input-icon-float",
    placeholder: "Enter Email Address...",
    wrapperClass: "mb-0 col-12",
  },
  {
    name: "password",
    inputType: "password",
    required: true,
    type: "password",
    showPassword: true,
    icon: "FaLock",
    className: "input-icon-float",
    placeholder: "Enter Password",
    wrapperClass: "form-outline mb-3 col-12 mt-2",
    inForm: true,
  },
  {
    name: "signin",
    inputType: "button",
    buttonType: "submit",
    children: "LOGIN",
    inForm: true,
    wrapperClass: "btn-wrapper col-12",
    height : "48px",
  },
];
