import React, { useState } from "react";
import "./organizationdetails.scss";
import ReadableTable from "../readableTable/ReadableTable";
import ReactSelect from "react-select";
import Modal from "react-modal";
import WithPageHeader from "../ui/withPageHeader";
import { Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

// import ReadableTable from "../../readableTable/ReadableTable";

const OrganizationDetails = ({ organizationData }) => {
  const [modalShow, setModalShow] = useState(false);
  const header = ["Office", "Email Address", "Phone No.", "Fax No."];
  const keyStafHeader = [
    "Key staff type ",
    "Staff Level",
    "Name",
    "Email",
    "Phone No.",
    "Cell No.",
    "Fax No.",
  ];
  const foriegnEmpHeader = [
    "Name",
    "Copy of Passport",
    "Address in Pakistan",
    "Address in Home Country",
  ];
  const existingLocalsHeader = [
    "Name",
    "Father Name",
    "CNIC",
    "Address (Permanent/Temporary)",
    "Contact No.",
  ];
  const projectAreaHeader = [
    "Project Title",
    "Project Area",
    "Cluster/line department",
    "Donor Organization",
  ];

  return (
    <div className="organization-details d-flex flex-column gap-1 mt-2">
      {organizationData ? (
        <>
          <div className="bg-white px-4 py-3 rounded-top-2">
            <p className="label">Name And Type</p>
            <div className="d-flex flex-column justify-content-between gap-2 mb-2">
              <div className="header-name">
                <p>Organization Name:</p>
                <p>{organizationData.organization?.name}</p>
              </div>
              <div className="header-name">
                <p>NGO Type:</p>
                <p>{organizationData.organization?.type}</p>
              </div>
            </div>
          </div>

          <div className="bg-white px-4 py-3 rounded-top-2">
            <p className="label">Esential Contacts</p>
            <p className="header-child">Head Of NGO</p>
            <div className="d-flex flex-column justify-content-between gap-3 ">
              <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                <div className="header-name col-md-4 ">
                  <p> Name:</p>
                  <p>{organizationData.organization?.headOfNGOInfo?.name}</p>
                </div>
                <div className="header-name col-md-4">
                  <p>Email:</p>
                  <p>{organizationData.organization?.headOfNGOInfo?.email}</p>
                </div>
                <div className="header-name col-md-4">
                  <p>Designation:</p>
                  <p>
                    {organizationData.organization?.headOfNGOInfo?.designation}
                  </p>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                <div className="header-name col-md-4 ">
                  <p> Phone No:</p>
                  <p>{organizationData.organization?.headOfNGOInfo?.phoneNo}</p>
                </div>
                <div className="header-name col-md-4">
                  <p>Mobile No:</p>
                  <p>
                    {organizationData.organization?.headOfNGOInfo?.mobileNo}
                  </p>
                </div>
                <div className="header-name col-md-4">
                  <p>Cnic Copy:</p>
                  <p className="underlined_text">
                    {organizationData.organization?.headOfNGOInfo?.cnic}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white px-4 py-3 rounded-top-2">
            <p className="header-child mt-2">Focal Person Information</p>
            <div className="d-flex flex-column justify-content-between gap-3 ">
              <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                <div className="header-name col-md-4 ">
                  <p> Name:</p>
                  <p>{organizationData.organization?.focalPersonInfo?.name}</p>
                  {/* focalPersonInfo */}
                </div>
                <div className="header-name col-md-4">
                  <p>Email:</p>
                  <p>{organizationData.organization?.focalPersonInfo?.email}</p>
                </div>
                <div className="header-name col-md-4">
                  <p>Designation:</p>
                  <p>
                    {
                      organizationData.organization?.focalPersonInfo
                        ?.designation
                    }
                  </p>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                <div className="header-name col-md-4 ">
                  <p> Phone No:</p>
                  <p>
                    {organizationData.organization?.focalPersonInfo?.phoneNo}
                  </p>
                </div>
                <div className="header-name col-md-4">
                  <p>Mobile No:</p>
                  <p>
                    {organizationData.organization?.focalPersonInfo?.mobileNo}
                  </p>
                </div>
                <div className="header-name col-md-4">
                  <p>Cnic Copy:</p>
                  <p className="underlined_text">
                    {organizationData.organization?.focalPersonInfo?.cnic}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 py-3 rounded-top-2">
            <p className="header-child mt-3">Registration Details</p>
            <div className="d-flex flex-column justify-content-between gap-4 ">
              <div className=" col-md-12 ">
                <div className="header-name col-md-12  mt-3">
                  <p> Where Registered:</p>
                  <p>
                    {
                      organizationData.organization?.registerationDetails
                        ?.whereRegistered
                    }
                  </p>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                <div className="header-name col-md-6 ">
                  <p> Registration Certificate:</p>
                  <p className="underlined_text">
                    {
                      organizationData.organization?.registerationDetails
                        ?.registerationCertificate
                    }
                  </p>
                </div>
                <div className="header-name col-md-6">
                  <p>NTN Number:</p>
                  <p>
                    {
                      organizationData.organization?.registerationDetails
                        ?.ntnNumber
                    }
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                <div className="header-name col-md-6 ">
                  <p>Start date:</p>
                  <p>
                    {
                      organizationData.organization?.registerationDetails
                        ?.startDate
                    }
                  </p>
                </div>
                <div className="header-name col-md-6">
                  <p>End date:</p>
                  <p>
                    {
                      organizationData.organization?.registerationDetails
                        ?.endDate
                    }
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                <div className="header-name col-md-6 ">
                  <p>Previous Year Tax Return:</p>
                  <p className="underlined_text">
                    {
                      organizationData.organization?.registerationDetails
                        ?.previousYearTax
                    }
                  </p>
                </div>
                <div className="header-name col-md-6">
                  <p>Year of Tax Return:</p>
                  <p>
                    {
                      organizationData.organization?.registerationDetails
                        ?.yearOfTaxReturns
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 py-3  rounded-top-2">
            <ReadableTable
              label="office Information"
              headers={header}
              data={organizationData?.organization?.informationOfOffice}
            />
            <ReadableTable
              label="Key Staff Information"
              headers={keyStafHeader}
              data={organizationData?.organization?.keyStaffInformation}
            />
          </div>
          <div className="bg-white px-4 py-3  rounded-top-2">
            <ReadableTable
              label="Details of Foreigners working in the NGO/INGO"
              headers={foriegnEmpHeader}
              data={organizationData?.organization?.detailsOfForeigners}
            // topHeader="Details of Foreigners working in the NGO/INGO"
            />
          </div>
          <div className="bg-white px-4 py-3  rounded-top-2">
            <ReadableTable
              label=" Details of Existing locals working in the NGO/INGO"
              headers={existingLocalsHeader}
              data={organizationData?.organization?.existingLocals}
            // topHeader=" Details of Existing locals working in the NGO/INGO"
            />
          </div>
          <div className="bg-white px-4 py-3  rounded-top-2">
            <ReadableTable
              label="Details of Project Undertaken by the NGO/iNGO in the Project Area"
              headers={projectAreaHeader}
              data={organizationData?.organization?.projectAreaData}
            // topHeader="Details of Project undertaken by the NGO/INGO in the project area"
            />
          </div>

          <div style={{ gap: "32px" }} className="d-flex justify-content-end">
            <button onClick={() => setModalShow(true)}  className="btn-backward">SEND BACK</button>
            <button  className="btn-forward">
              APPROVE
            </button>
          </div>

          <Modal
            isOpen={modalShow}
            onRequestClose={() => setModalShow(false)}
            contentLabel="Send Back"
            overlayClassName="modal-xs"
            ariaHideApp={false}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p className="heading1 m-0">Send Back</p>
              <Icon.X
                size={32}
                color="black"
                onClick={() => setModalShow(!modalShow)}
              />
            </div>

            <div className="seprator"></div>

            <div className="mt-4">
              <p className="m-1">Sending Back Comments</p>
              <textarea
                className="form-control h-25"
                rows="5"
                placeholder="Enter Your Comments"
              ></textarea>
            </div>
            <div className="mt-3">
              <ReactSelect
                className="dropdown-input"
                options={[
                  { value: "1", label: "M Irfan" },
                  { value: "2", label: "Hadi M" },
                  { value: "3", label: "Mehran" },
                  { value: "4", label: "Umar" },
                ]}
                placeholder="Select Recipient"
              />
            </div>
            <div className="d-flex justify-content-end">
              <button onClick={() => { }} className="btn-forward">
                Send
              </button>
            </div>
          </Modal>
        </>
      ) : (
        <>No Data Founds</>
      )}

    
    </div>
  );
};

export default OrganizationDetails;
