
export const customStyles = {
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderColor: "#dcdff3",
      backgroundColor: "#F8F8F8",
      height: "48px",
      borderRadius: "8px",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      color: "#000000",
      fontWeight: "500",
      paddingLeft: "16px",
      fontFamily: "Roboto Flex, sans-serif",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
      },
      "&:last-of-type": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
        borderTopRightRadius: "6px",
      },
      "&:first-of-type": {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dcdff3",
        borderTopLeftRadius: "6px",
      },
    },
  },
  cells: {
    style: {
      height: "48px",
      fontSize: "13px",
      paddingLeft: "16px",
      color: "#1E1F23",
      fontFamily: "Roboto Flex, sans-serif",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
      },
      "&:last-of-type": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
      },
      "&:first-of-type": {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dcdff3",
      },
    },
  },
};