import React, { useEffect, useState } from "react";
import { applicationFields } from "../../../assets/fields/applicationFields";
import { extensionFields } from "../../../assets/fields/extensionFields";
import { getFieldsByCategory } from "../../../utils/util";
import Form from "../../form/Form";
import ProjectRelates from "../ProjectRelates";
import OperationalCostTable from "../OperationalCostTable";
import { getRecords } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import CheckboxGrid from "./CheckboxGrid";

const ProjectSummaryForm = ({ extension }) => {
  const dispatch = useDispatch();

  const directBenefFields = getFieldsByCategory(
    extension ? extensionFields : applicationFields,
    "directBeneficiaries"
  );

  const [fields, setFields] = useState(directBenefFields ?? []);

  const { responsePlan, cluster, thematicArea } = useSelector(
    (state) => state.records.current
  );

  const { values, validations, lookups } = useSelector((state) => state.form);
  const districtsLookup = lookups?.districtId;
  const clusterValues = values?.applicationClusters?.value;
  const geographicLocations = values?.geographicLocations?.value;

  const projectSummaryFields = getFieldsByCategory(
    extension ? extensionFields : applicationFields,
    "summaryOfProjectCost"
  );
  const [formFields, setFormFields] = React.useState(
    projectSummaryFields
  );

  useEffect(() => {

    if (clusterValues?.length > 0) {
      const formmattedFields = fields?.map((field) => {
        // return field
        if (field?.name === "projectBeneficiaries") {
          const formatedHeaderFields = field?.header?.map((fieldItem) => {
            if (fieldItem?.name === "clusterId") {
              return {
                ...fieldItem,
                options: cluster
                  ?.filter((item) =>
                    clusterValues?.some(
                      (selectedCluster) =>
                        item.id === selectedCluster?.clusterId
                    )
                  )
                  .map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  })),
              };
            } else if (fieldItem?.name === "districtId") {
              return {
                ...fieldItem,
                options: districtsLookup
                  ?.filter((item) =>
                    geographicLocations?.some(
                      (geoLocation) =>
                        item.id === geoLocation?.districtId?.value?.value
                    )
                  )
                  .map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  })),
              };
            } else {
              return fieldItem;
            }
          });
          return {
            ...field,
            header: formatedHeaderFields,
          };
        } else {
          return field;
        }
      });
      setFields(formmattedFields);
    }
  }, [clusterValues, values, geographicLocations]);



  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (values?.fundsCurrencyId) {
      const updatedFields = formFields.map((field) => {
        if (field.name === "amountinUSD") {
          field.label = `Amount in ${
            values?.fundsCurrencyId?.label ||
            values?.fundsCurrencyId?.value?.label
          }`;
          field.placeholder = `Enter Amount in ${values?.fundsCurrencyId?.label}`;
        }
        return field;
      });
      setFormFields(updatedFields);
    }
  }, [values?.fundsCurrencyId]);

  function fetchInitialData() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/ResponsePlan",
        appendCurrentReducerKey: "responsePlan",
      },
    });
    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: "/Cluster",
          appendCurrentReducerKey: "cluster",
        },
      });
    }, 100);
    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: "/ThematicArea",
          appendCurrentReducerKey: "thematicArea",
        },
      });
    }, 200);
  }

  return (
    <>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        <Form
          formData={getFieldsByCategory(
            extension ? extensionFields : applicationFields,
            "projectInformation"
          )}
          withValidation={true}
          formValidation={validations}
        />
      </div>
      {!extension && (
        <>
          <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
            <Form
              formData={getFieldsByCategory(
                applicationFields,
                "fundDetailsInNGO"
              )}
              withValidation={true}
              formValidation={validations}
            />
          </div>
          <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
            <ProjectRelates initialData={values?.projectClassification} />
          </div>
        </>
      )}
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        <CheckboxGrid
          label="Plan Under Which Project Falls (If Applicable)"
          instruction="Please Tick relevant boxes, Multiple selection possible"
          fields={responsePlan ?? []}
          name="responsePlans"
          objectFormate="responsePlanId"
          initialChecked={values?.responsePlans}
        />
      </div>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        <CheckboxGrid
          label="Cluster Under Which Project Falls"
          instruction="Please tick (√) relevant boxes, Multiple selection possible. Vetting
        from relevant cluster head will be required"
          fields={cluster ?? []}
          name="applicationClusters"
          objectFormate="clusterId"
          initialChecked={values?.applicationClusters}
        />
      </div>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded inputTableOverflow">
        <Form
          formData={getFieldsByCategory(
            extension ? extensionFields : applicationFields,
            "geographicLocation"
          )}
        />
      </div>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        <CheckboxGrid
          label="Thematic Areas Under  Which The  Project Falls"
          instruction="Please tick (√) relevant boxes"
          fields={thematicArea ?? []}
          name="thematicAreas"
          objectFormate="thematicAreaId"
          initialChecked={values?.thematicAreas}
        />
      </div>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded inputTableOverflow">
        <Form
          formData={fields ?? []}
          withValidation={true}
          formValidation={validations}
        />
      </div>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        <Form
          formData={getFieldsByCategory(
            extension ? extensionFields : applicationFields,
            "proposedTimeFrame"
          )}
          withValidation={true}
          formValidation={validations}
        />
      </div>

      {extension && (
        <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
          <Form
            formData={getFieldsByCategory(
              extensionFields,
              "agreementtimeframe"
            )}
            formValidation={[]}
          />
        </div>
      )}

      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        <Form
          formData={[...formFields] ?? []}
          withValidation={true}
          formValidation={validations}
        />
        <OperationalCostTable />
      </div>
    </>
  );
};

export default ProjectSummaryForm;
