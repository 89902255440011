import React from 'react';
import "./NocButton.scss";
const NocButton = ({icon: Icon,btnTitle,active,...props}) => {
  return (
    <div  className={`nav-button ${active ? 'active' : ''}`} >
    <span ><Icon className={`nav-button-icon ${active ? 'active' : ''} `} width={30} height={30}/></span>
    <span className='nav-button-text user-select-none'>{btnTitle}</span>
</div>
  )
}

export default NocButton