import React from "react";

const AddUpdateNoc = () => {
  return (
    <div className="px-4 py-2">
     
    </div>
  );
};

export default AddUpdateNoc;
