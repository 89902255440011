
import * as React from "react"
const SubmitIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={14}
    fill="none"
    {...props}
  >
    <path
    
     
      d="M0 1.795A1.295 1.295 0 0 1 1.863.63l10.12 4.924a1.516 1.516 0 0 1 0 2.726l-10.12 4.923A1.295 1.295 0 0 1 0 12.039c0-.113-.008-.227.026-.336l1.166-3.791a.583.583 0 0 1 .416-.395l2.403-.6-2.403-.601a.583.583 0 0 1-.416-.394L.027 2.13C-.007 2.02 0 1.907 0 1.795Zm1.295-.128a.128.128 0 0 0-.128.128v.076l1.045 3.393L6.559 6.35a.583.583 0 0 1 0 1.132L2.212 8.569l-1.045 3.394v.076a.128.128 0 0 0 .184.115l10.12-4.923a.349.349 0 0 0 0-.628L1.351 1.68a.128.128 0 0 0-.056-.013Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SubmitIcon
