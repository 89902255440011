import axios from 'axios';
import { getAuthToken } from '../../utils/util';
import store from '../store';
const token = getAuthToken();
// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEwMDAzIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImFtbWFkQGNlbnRhbmdsZS5jb20iLCJGdWxsTmFtZSI6IkFtbWFkIEtoYWxpZCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImFtbWFkQGNlbnRhbmdsZS5jb20iLCJJbWFnZVVybCI6Inh5eiIsImp0aSI6IjNiYzg5N2YzLTM3YmItNDI5Ny1iMjBiLTYxMDc2OGQyMDgzYiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiZXhwIjoxNjYxMzY5NDY0LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjY0OTgiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjQyMDAifQ.Sq_K0rd8jViW-VWcrZvv50uqyffcWxm-SIWx-8O4YKU";
//const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2NTQ0OTc0MDgsImV4cCI6MTY4NjAzMzQwOCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoiMSIsImFwcGxpY2F0aW9uX0lEIjoiMSIsImJyYW5jaF9JRCI6IjEiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjoiTWFuYWdlciJ9.NAyUsmyRXMmm15iybX3sBOfN05tkJSlT2BYMpgkAm8Q";
axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const token = store.getState().setting.user.token;
    config.headers.Authorization =  `Bearer ${token}`;
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
