import React from "react";
import * as Icon from "react-bootstrap-icons";
import {getFormatedDate, handleDownloadFile } from "../../Utility";
import { Download } from "react-bootstrap-icons";
import { useDispatch } from 'react-redux';
import { formatDate } from "../../utils/util";



const ExtensionSupportingDocs = ({ data }) => {
  const dispatch = useDispatch();
  return (
    <div className="col-md-12 bg-white shadow-sm mb-2 p-4 mt-3 rounded">
      <p className="heading2">Supporting Documents</p>
      <div className="container-fluid gx-1">
        <div className="row g-4">
          {data?.applicationDocuments?.map((item) => (
            <div className={`col-md-6`} key={item?.id}>
              <p className="mb-2 text-black label-text">{item?.documentName}</p>
              <div className="border px-3 rounded d-flex align-items-center justify-content-between p-2">
                <p className="px-0 my-0 general-text">{item?.appDoc.split('/').pop() ?? "Download File"}</p>
                <label htmlFor="files" className="upload-btn">
                  <button
                    className="download-btn"
                    onClick={(e) => handleDownloadFile(e, item?.appDoc)}
                  >
                    <Download size={20} color="white" /> Download
                  </button>
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="row  gx-5">
          <div className="d-flex  align-items-center flex-row justify-content-end my-1 gap-1 px-2 py-2 col-md-12">
            <div className="d-flex flex-row gap-2 justify-content-center align-items-center col-md-3">
              <p>Start Date:</p><p>{getFormatedDate(data?.agreementStartDate ?? "0")}</p>
            </div>
            <div className="d-flex flex-row gap-2 justify-content-center align-items-center col-md-3">
              <p>End Date:</p><p>{getFormatedDate(data?.agreementEndDate ?? "0")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtensionSupportingDocs;
