import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { tabData, tabsList } from "../../assets/fieldsData";
import { Table } from "react-bootstrap-icons";

const CrudFields = ({addEnteryIndex}) => {
  // const CrudFields = ({ headingName }) => {

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(1);
  const [index, setIndex] = useState(0);

  const handleTabClick = (i, ind) => {
    setSelectedTab(i.id);
    setIndex(ind);
    dispatch({ type: "form/clearForm" });
  };

  return (
    
      <div className="panel-content-container border-0 rounded-0 ">
        <div className="panel-tab-heading">
          {/* <h5 className="panel-heading-content">{headingName}</h5> */}
        </div>
        {/* <div className="panel-tab-content pl-0 pb-0">
            <ul className="tabs pl-2">
              {tabsList.map((tabItem, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => handleTabClick(tabItem, index)}
                    className={`btn text-center ${tabItem.id === selectedTab && "active"}`}
                  >
                    {tabItem.title}
                  </li>
                );
              })}
            </ul>
        </div> */}
        <div className="card table-container table-container-anim border-0 ">
          <div className="card-body p-0">
            {tabData[addEnteryIndex]}
            {/* <Table data={tabData[index]}/> */}
             {/* {tabData[index]} */}
          </div>
        </div>
      </div>
    
  );
};

export default CrudFields;
