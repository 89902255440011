import React, { useEffect, useState } from "react";
import "./ActivityAndSubActivityTable.scss";
import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import { PencilSquare, PlusCircle, Trash } from "react-bootstrap-icons";
import ToolTip from "../../tooltip/Tooltip";
import { formateDate } from "../../../Utility";
import { formatDate } from "../../../utils/util";

const activityFields = [
  {
    name: "workType",
    type: "select",
    label: "Work Type",
    lookupKey: "workType",
    labelKey: "name",
    async: true,
  },
  {
    name: "description",
    type: "text",
    label: "Description",
    placeholder: "Enter Description",
  },
  { name: "startDate", type: "date", label: "Start Date" },
  { name: "endDate", type: "date", label: "End Date" },
  {
    name: "responsible",
    type: "text",
    label: "Responsible",
    placeholder: "Enter Responsible",
  },
  {
    name: "verificationMeanId",
    type: "select",
    label: "Means of Verification",
    lookupKey: "verificationMeanId",
    labelKey: "mean",
    async: true,
    isSearch: false,
  },
];

const ActivityAndSubActivityTable = ({ extension }) => {
  const [rows, setRows] = useState([]);
  const [currentSubActivityRow, setCurrentSubActivityRow] = useState(null);
  const [editingSubActivity, setEditingSubActivity] = useState(null);
  const [editingActivity, setEditingActivity] = useState(null);

  const dispatch = useDispatch();
  const { lookups, values } = useSelector((state) => state.form);
  const [newActivity, setNewActivity] = useState({
    workType: "",
    description: "",
    startDate: "",
    endDate: "",
    responsible: "",
    verificationMeanId: "",
  });
  const [newSubActivity, setNewSubActivity] = useState({
    workType: "",
    description: "",
    startDate: "",
    endDate: "",
    responsible: "",
    verificationMeanId: "",
  });

  useEffect(() => {
    setTimeout(() => {
      getLookUps();
    }, 100);
  }, []);

  const getLookUps = () => {
    dispatch({
      type: "form/fetchLookup",
      payload: { key: "workType", url: "/Lookup/WorkType" },
    });
    dispatch({
      type: "form/fetchLookup",
      payload: { key: "verificationMeanId", url: "/VerificationMean" },
    });
  };

  const formateData = (data) => {
    return data?.map((item) => ({
      ...item,
      startDate: formatDate(item?.startDate),
      endDate: formatDate(item?.endDate),
      workPlanSubActivities: item.workPlanSubActivities.map((subItem) => ({
        ...subItem,
        startDate: formatDate(subItem.startDate),
        endDate: formatDate(subItem.endDate),
      })),
    }));
  };

  useEffect(() => {
    if (values?.workPlans?.value) {
     setRows(formateData(values?.workPlans?.value));
    }
  }, [values?.workPlans]);

  const handleInputChange = (name, value) => {
    setNewActivity((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubActivityChange = (name, value) => {
    setNewSubActivity((prevState) => ({ ...prevState, [name]: value }));
  };

  const addActivity = () => {
    // if (Object.values(newActivity).some((value) => !value)) {
    //   alert("Please fill in all fields before adding an activity.");
    //   return;
    // }
    if (!validateFields(newActivity)) {
      return;
    }
    const updatedRows = [
      ...rows,
      {
        ...newActivity,
        // id: rows.length + 1,
        workPlanSubActivities: [],
        workType: newActivity.workType?.label,
        verificationMeanId: newActivity.verificationMeanId?.value,
      },
    ];

    setRows(updatedRows);

    dispatch({
      type: "form/setFormValues",
      payload: {
        workPlans: {
          name: "workPlans",
          value: updatedRows,
        },
      },
    });
    setNewActivity({
      workType: "",
      description: "",
      startDate: "",
      endDate: "",
      responsible: "",
      verificationMeanId: "",
    });
  };

  const addSubActivity = (rowIndex) => {
    if (Object.values(newSubActivity).some((value) => !value)) {
      alert("Please fill in all fields before adding a sub-activity.");
      return;
    }

    const newRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          workPlanSubActivities: [
            ...row.workPlanSubActivities,
            {
              // id: `${row.id}.${row.workPlanSubActivities.length + 1}`,
              ...newSubActivity,
              workType: newSubActivity.workType.label,
              verificationMeanId: newSubActivity.verificationMeanId.value,
            },
          ],
        };
      }
      return row;
    });

    setRows(newRows);

    dispatch({
      type: "form/setFormValues",
      payload: {
        workPlans: {
          name: "workPlans",
          value: newRows,
        },
      },
    });

    setNewSubActivity({
      workType: "",
      description: "",
      startDate: "",
      endDate: "",
      responsible: "",
      verificationMeanId: "",
    });
    setCurrentSubActivityRow(null);
  };

  const editSubActivity = (rowIndex, subIndex) => {
    const subActivity = rows[rowIndex].workPlanSubActivities[subIndex];
    setNewSubActivity({
      ...subActivity,
      workType: {
        label: subActivity.workType,
        value: subActivity.workType,
      },
      verificationMeanId: {
        label: lookups.verificationMeanId.find(
          (item) => item.id === subActivity.verificationMeanId
        )?.mean,
        value: subActivity.verificationMeanId,
      },
    });
    setCurrentSubActivityRow(rowIndex);
    setEditingSubActivity(subIndex);
  };

  const saveEditedSubActivity = (rowIndex, subIndex) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        const updatedSubActivities = row.workPlanSubActivities.map((sub, i) =>
          i === subIndex
            ? {
                ...newSubActivity,
                workType: newSubActivity.workType?.label,
                verificationMeanId: newSubActivity.verificationMeanId?.value,
              }
            : sub
        );
        return { ...row, workPlanSubActivities: updatedSubActivities };
      }
      return row;
    });

    setRows(updatedRows);
    dispatch({
      type: "form/setFormValues",
      payload: { workPlans: { name: "workPlans", value: updatedRows } },
    });

    setNewSubActivity({
      workType: "",
      description: "",
      startDate: "",
      endDate: "",
      responsible: "",
      verificationMeanId: "",
    });
    setCurrentSubActivityRow(null);
    setEditingSubActivity(null);
  };

  const deleteActivity = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
    dispatch({
      type: "form/setFormValues",
      payload: { workPlans: { name: "workPlans", value: updatedRows } },
    });
  };

  const deleteSubActivity = (rowIndex, subIndex) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        const updatedSubActivities = row.workPlanSubActivities.filter(
          (_, i) => i !== subIndex
        );
        return { ...row, workPlanSubActivities: updatedSubActivities };
      }
      return row;
    });

    setRows(updatedRows);
    dispatch({
      type: "form/setFormValues",
      payload: { workPlans: { name: "workPlans", value: updatedRows } },
    });
  };

  const loadOptions = (key, inputValue, callback, label) => {
   
    const options = lookups[key]
      ?.filter((item) =>
        item[label].toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: item[label],
        value: item.id,
      }));
    callback(options);
  };

  const editActivity = (index) => {
    const activity = rows[index];
    setNewActivity({
      ...activity,
      workType: {
        label: activity.workType,
        value: activity.workType,
      },
      verificationMeanId: {
        label: lookups.verificationMeanId.find(
          (item) => item.id === activity.verificationMeanId
        )?.mean,
        value: activity.verificationMeanId,
      },
    });
    setEditingActivity(index);
  };

  const saveEditedActivity = () => {
    const updatedRows = rows.map((row, index) =>
      index === editingActivity
        ? {
            ...newActivity,
            // id: row.id,
            workPlanSubActivities: row.workPlanSubActivities,
            workType: newActivity.workType?.label,
            verificationMeanId: newActivity.verificationMeanId?.value,
          }
        : row
    );

    setRows(updatedRows);
    dispatch({
      type: "form/setFormValues",
      payload: { workPlans: { name: "workPlans", value: updatedRows } },
    });

    setNewActivity({
      workType: "",
      description: "",
      startDate: "",
      endDate: "",
      responsible: "",
      verificationMeanId: "",
    });
    setEditingActivity(null);
  };

  const [errors, setErrors] = useState({});

  const validateFields = (fields) => {
    const errors = {};
    activityFields.forEach((field) => {
      if (!fields[field.name]) {
        errors[field.name] = `${field?.label} require`;
      }
    });
    setErrors(errors);
    return !(Object.keys(errors).length > 0);
  };

  return (
    <div className="container-fluid mt-4 mx-0 px-0 inputTableFieldAuto inputTableOverflow">
      <div className="mb-3">
        <h3>{editingActivity === null ? "Add Activity" : "Edit Activity"}</h3>
        <table className="table table-bordered">
          <thead className="bg-light">
            <tr>
              <th>Sr. No</th>
              {activityFields.map((field) => (
                <th key={field.name}>{field.label}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              {activityFields.map((field) => (
                <td key={field.name} >
                  <FormInput
                    field={field}
                    value={newActivity[field.name]}
                    onChange={handleInputChange}
                    lookups={lookups}
                    loadOptions={loadOptions}
                    {...field}
                  />
                  {errors[field.name] && (
                    <td className="text-danger">{errors[field.name]}</td>
                  )}
                </td>
              ))}
              <td>
                {editingActivity === null ? (
                  <button className="btn btn-success" onClick={addActivity}>
                    <PlusCircle />
                    Activity
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={saveEditedActivity}
                  >
                    Save
                  </button>
                )}
              </td>
            </tr>
            {rows.map((row, rowIndex) => (
              <React.Fragment key={row.id}>
                <tr>
                  <th scope="row">{rowIndex + 1}</th>
                  {activityFields.map((field) => (
                    <>
                      {field.type === "select" ? (
                        <td key={field.name}>
                          {field.name === "workType"
                            ? row[field.name]
                            : lookups[field.lookupKey]?.find(
                                (item) => item.id == row[field.name]
                              )?.[field.labelKey]}
                        </td>
                      ) : (
                        <td key={field.name}>{row[field.name]}</td>
                      )}
                    </>
                  ))}
                  <td >
                    <ToolTip label="Add Sub Activity">
                      <button
                        className="btn btn-secondary m-1"
                        onClick={() => setCurrentSubActivityRow(rowIndex)}
                      >
                        <PlusCircle />
                      </button>
                    </ToolTip>
                    <ToolTip label="Edit Activity">
                      <button
                        className="btn btn-secondary m-1"
                        onClick={() => editActivity(rowIndex)}
                      >
                        <PencilSquare />
                      </button>
                    </ToolTip>
                    <ToolTip label="Delete Activity">
                      <button
                        className="btn btn-danger m-1"
                        onClick={() => deleteActivity(rowIndex)}
                      >
                        <Trash />
                      </button>
                    </ToolTip>
                  </td>
                </tr>
                {currentSubActivityRow === rowIndex && (
                  <tr>
                    <td colSpan="7">
                      <table>
                        <tbody>
                          <tr>
                            {activityFields.map((field) => (
                              <td key={field.name} >
                                <FormInput
                                  field={field}
                                  value={newSubActivity[field.name]}
                                  onChange={handleSubActivityChange}
                                  lookups={lookups}
                                  loadOptions={loadOptions}
                                  {...field}
                                />
                              </td>
                            ))}
                            <td>
                              {editingSubActivity !== null ? (
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    saveEditedSubActivity(
                                      rowIndex,
                                      editingSubActivity
                                    )
                                  }
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success"
                                  onClick={() => addSubActivity(rowIndex)}
                                >
                                  Add
                                </button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
                {row.workPlanSubActivities.map((sub, subIndex) => (
                  <tr key={sub.id}>
                    <th scope="row">{`${rowIndex + 1}.${subIndex + 1}`}</th>
                    {activityFields.map((field) => (
                      <>
                        {field.type === "select" ? (
                          <td key={field.name}>
                            {field.name === "workType"
                              ? sub[field.name]
                              : lookups[field.lookupKey]?.find(
                                  (item) => item.id == sub[field.name]
                                )?.[field.labelKey]}
                          </td>
                        ) : (
                          <td key={field.name}>{sub[field.name]}</td>
                        )}
                      </>
                    ))}
                    <td>
                      <ToolTip label="Edit Sub Activity">
                        <button
                          className="btn btn-secondary m-1"
                          onClick={() => editSubActivity(rowIndex, subIndex)}
                        >
                          <PencilSquare />
                        </button>
                      </ToolTip>
                      <ToolTip label="Delete Sub Activity">
                        <button
                          className="btn btn-danger m-1"
                          onClick={() => deleteSubActivity(rowIndex, subIndex)}
                        >
                          <Trash />
                        </button>
                      </ToolTip>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivityAndSubActivityTable;

const FormInput = ({
  field,
  value,
  onChange,
  lookups,
  loadOptions,
  labelKey,
}) => {
  const handleChange = (selectedOption) => {
    onChange(field.name, {
      label: selectedOption.label,
      value: selectedOption?.value?.toString(),
    });
  };

  if (field.type === "select") {
    return (
      <AsyncSelect
        cacheOptions
        loadOptions={(inputValue, callback) =>
          loadOptions(field.lookupKey, inputValue, callback, labelKey)
        }
        defaultOptions={lookups[field.lookupKey]?.map((item) => ({
          label: item[labelKey],
          value: item.id,
        }))}
        onChange={handleChange}
        className="input-form-fields w-100 p-2"
        
        value={value ?? { label: value.label, value: value.value }}
      />
    );
  }

  return (
    <input
      type={field.type}
      name={field.name}
      placeholder={field.placeholder}
      className="bg-light rounded py-2 input-form-fields "      
      value={value}

      onChange={(e) => onChange(field.name, e.target.value)}
    />
  );
};
