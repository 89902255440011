export const geographicLocationFields = [
    {
      label: "District",
      placeholder: "Select District",
      name: "districtId",
      id: "districtId",
      inputType: "async-dropdown",
      url: "/District",
      searchAttrib: "Search.value",
      labelAttribute: "name",
      valueAttribute: "id",
      onChangeMethod: "dropdown-in-table",
      inForm: true,
      isEnum: true,
      isMulti: false,
      className: "px-2",
    },
    {
      label: "Tehsil",
      placeholder: "Select Tehsil",
      name: "tehsil",
      inputType: "text",
      onChangeMethod: "text-in-table",
      inForm: true,
      isEnum: true,
    },
    {
      label: "Union Council",
      placeholder: "Enter Union Council",
      name: "unionCouncil",
      inputType: "text",
      onChangeMethod: "text-in-table",
      inForm: true,
      isEnum: true,
    },
    {
      label: "Village",
      placeholder: "Enter Village",
      inputType: "text",
      name: "village",
      onChangeMethod: "text-in-table",
      inForm: true,
      isEnum: true,
    },
  ]