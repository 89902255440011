import React, { useEffect } from "react";
import Loader from "../loader/loader";
import Form from "../form/Form";
import { montlyReportFields } from "../../assets/fields/monthlyReportFields";
import { getFieldsByCategory } from "../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import { getRecords, setFormValues } from "../../redux/reducers";

const ProgressDetailTableInput = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { values, lookups } = useSelector((state) => state.form);
  const formValues = values;
  const { current } = useSelector((state) => state.records);
  const nocWorkPlanList = current?.nocWorkPlanList;

  const [formFields, setFormFields] = React.useState(
    getFieldsByCategory(montlyReportFields, "progressDetailTableInputx")
  );

  useEffect(() => {
    if (nocWorkPlanList && Array.isArray(nocWorkPlanList)) {
      const updatedFields = formFields.map((field, index) => {
        if (field.name === "reportDetails") {
          field.header[0].options = nocWorkPlanList
            .filter((i) => i.isSubActivity === true)
            .map((item) => {
              return {
                label: item.workDescription,
                value: item.id,
              };
            });
        }
        return field;
      });
      setFormFields(updatedFields);
    }
  }, [nocWorkPlanList]);

  useEffect(() => {
    if (formValues?.nocApplicationId) {
      getListOfWorkPlans(formValues?.nocApplicationId?.value?.value);
    }
  }, [formValues?.nocApplicationId]);

  const getListOfWorkPlans = (id) => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/GetNocWorkPlan`,
        appendCurrentReducerKey: "nocWorkPlanList",
      },
    });
  };
  return (
    <>
      <Loader loading={loading} />
      {formValues?.nocApplicationId && (
        <div className="col-md-12 bg-white shadow-sm mb-2 px-4 py-4 rounded">
          <Form
            formData={formFields}
            withValidation={false}
            formValidation={[]}
            extraInputClass="inputTableFieldAuto inputTableOverflow"
          />
          {/* <ProogressDetailTable /> */}
        </div>
      )}
    </>
  );
};

export default ProgressDetailTableInput;
