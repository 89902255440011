import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import RobotoLight from "../../assets/RobotoLight.ttf";
import RobotoRegular from "../../assets/RobotoRegular.ttf";
import RobotoMedium from "../../assets/RobotoMedium.ttf";
import RobotoBold from "../../assets/RobotoBold.ttf";
import { formatDate } from "../../utils/util";
Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoBold, fontWeight: 700 },
  ],
});
// Define styles for the PDF document
const styles = StyleSheet.create({
  body: {
    fontFamily: "Helvetica",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  subtitle: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    textDecoration: "underline",
    marginBottom: 10,
  },
  list: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Roboto",
    fontWeight: 400,
    marginBottom: 4,
  },
  table: {
    width: "100%",
    display: "table",
    marginTop: 5,
    marginBottom: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    textAlign: "justify",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: 500,
  },
  bold: {
    fontFamily: "Roboto",
    fontWeight: 500,
  },
  underline: {
    textDecoration: "underline",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
  signature: {
    // marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  signatureLine: {
    borderBottomWidth: 1,
    borderColor: "black",
    width: 260,
    marginTop: 20,
  },
  centered: {
    display: "flex",
    // justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    wordWrap: "break-word",
    textAlign: "center",
  },
  copyForwarded: {
    marginTop: 20,
    marginBottom: 10,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 12,
  },
  pageNumbers: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    marginBottom: 20,
  },
  topSerial: {
    position: "absolute",
    fontSize: 12,
    top: 12,
    right: 8,
    // textAlign: "center",
    // marginBottom: 20,
  },
});

//return 1st if 1, 2nd if 2, 3rd if 3, else return th
const getOrdinalSuffix = (num) => {
  if (num > 3 && num < 21) return "th";
  switch (num % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const ReportDocumentTemplate = ({ projectInfo, organizationInfo }) => {
  console.log("🚀 ~ ReportDocumentTemplate ~ projectInfo:", projectInfo);
  const districts = projectInfo?.geographicLocations
    ?.map((i) => i?.districtName)
    ?.join(", ");
  return (
    <Document>
      <Page size="LEGAL" style={styles.body}>
        <Text style={styles.topSerial}>Tracking No: {projectInfo?.id}</Text>
        {/* Header Section */}
        <View style={styles.header}>
          <Image
            source={require("../../assets/kpk-govt-logo.png")}
            style={{ width: 72, height: 72 }}
          />
          <Text style={styles.title}>
            GOVERNMENT OF KHYBER PAKHTUNKHWA{"\n"}
            RELIEF REHABILITATION & SETTLEMENT DEPARTMENT
          </Text>
        </View>
        <View style={{ borderBottomWidth: 1, marginBottom: 20 }} />

        {/* NOC Title Section */}
        <Text style={styles.subtitle}>
          NO OBJECTION CERTIFICATE (
          {projectInfo?.extensionCount < 1
            ? "Fresh"
            : `${projectInfo?.extensionCount}${getOrdinalSuffix(
                projectInfo?.extensionCount
              )} Extension`}
          )
        </Text>

        {/* Main List Section */}
        <View style={styles.section}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>1.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              In pursuance of Expression of Interest (EOI) submitted by the
              Organization(s) mentioned in sub-Para (A), the Relief,
              Rehabilitation & Settlement Department is pleased to issue No
              Objection Certificate (NOC) to your organization on the following
              terms:
            </Text>
          </View>

          {/* Table */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCol]}>
                A.{" "}
                <Text style={styles.bold}>
                  Name of organization applying for NOC
                </Text>
              </Text>
              <Text style={[styles.tableCol, styles.bold, styles.underline]}>
                {organizationInfo?.organizationName}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>
                B. <Text style={styles.bold}>Project applied for</Text>
              </Text>
              <Text style={[styles.tableCol, styles.bold]}>
                {projectInfo?.projectTitle}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>
                C. <Text style={styles.bold}>Sector(s):</Text>
              </Text>
              <Text style={[styles.tableCol, styles.bold]}>
                {projectInfo?.applicationClusters
                  ?.map((cluster) => cluster?.clusterName)
                  ?.join(", ")}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>
                D. <Text style={styles.bold}>Donor</Text>
              </Text>
              <Text style={[styles.tableCol, styles.bold]}>
                {projectInfo?.fundDetail?.fundType === "Self Funded"
                  ? "Self Funded"
                  : projectInfo?.donorOrganization ?? ""}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>
                E. <Text style={styles.bold}>Area of intervention</Text>
              </Text>
              <Text style={[styles.tableCol, styles.bold]}>
                {projectInfo?.geographicLocations
                  ?.map((i) => i?.districtName)
                  ?.join(", ")}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>
                F. <Text style={styles.bold}>NOC issuance period</Text>
              </Text>
              <Text style={[styles.tableCol, styles.bold, styles.underline]}>
                {formatDate(projectInfo?.nocStartDate)} -{" "}
                {formatDate(projectInfo?.nocEndDate)}
              </Text>
            </View>
          </View>

          {/* List Items */}
          <View
            style={{
              flexDirection: "row",
              marginTop: 12,
            }}
          >
            <Text style={styles.list}>2.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              The organization’s operation in the specified area shall be in
              accordance with the TORs specified.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>3.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              The organization is allowed to work till the validity period
              issued by this Department.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>4.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              NOC is issued subject to satisfactory performance and a three-week
              prior request.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>5.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              All ongoing and planned interventions/projects will be coordinated
              with concerned Provincial/Divisional/District Authorities
              (Commissioner, Deputy Commissioner, Additional Deputy Commissioner
              (Relief & HR), Head of concerned line department) and
              representatives of local bodies.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>6.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Monthly progress reports and all other required documents shall be
              shared with the Relief Department in line with the reporting
              templates already provided by this Department.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>7.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Facilitation of the Relief Department team to visit the project
              area for evaluation/monitoring at any time.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>8.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              There will be no financial/legal implication on the part of the
              Relief Department at any stage and at any level.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>9.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              The NGO shall submit a statement of its Accounts/Financial Report
              on a quarterly basis and annual accounts duly audited by the close
              of its financial year.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>10.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              This NOC does not mean to seek funds from donors.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>11.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Staff working with the children must understand and abide by the
              guidelines outlined in the Relief Department code of conduct for
              working with children.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>12.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              No Geological, Seismic and other studies may be allowed.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>13.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Use of wireless equipment, surveillance devices, and intelligence
              gathering shall be disallowed to NGOs/INGOs/Organizations/Staff of
              UN bodies.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>14.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Visiting staff/persons should not be allowed to visit restricted
              areas/installations.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>15.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              No photography of sensitive and restricted areas be allowed.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>16.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Visit to areas situated up to 10 miles distance near the
              International border, wherever applicable, is not allowed.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>17.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Visit to areas located in close proximity to Frontier Regions (FR)
              with the district concerned, if any, is NOT allowed.
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>18.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              NOC is issued subject to the direct implementation of the project
              by the organization{" "}
              <Text style={styles.underline}>
                {organizationInfo?.organizationName}
              </Text>
            </Text>
          </View>
        </View>

        {/* Signature and Date Section */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={styles.signature}>
            <Text style={styles.list}>Date: ______/______/________</Text>
          </View>

          <View style={styles.centered}>
            <View style={styles.signatureLine} />
            <Text style={[styles.list, styles.bold]}>
              Additional Secretary (Relief, Rehabilitation & Settlement
              Department)
            </Text>
          </View>
        </View>

        {/* Copy Forwarded Section */}
        <Text style={styles.copyForwarded}>
          Copy forwarded for information to the:
        </Text>
        <View style={styles.section}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>1.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Commissioner {districts}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>2.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Deputy Commissioner {districts}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>3.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              DPO, Peshawar {districts}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>4.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              Additional Deputy Commissioners (Relief & HR) {districts}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>5.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              PS to Secretary Home & TA Department {districts}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.list}>6.</Text>
            <Text style={[styles.list, { marginLeft: 12 }]}>
              NGO Concerned.
            </Text>
          </View>

          <View style={(styles.centered, { marginTop: 20 })}>
            <View style={styles.signatureLine} />
            <Text style={[styles.list, styles.bold]}>
              Focal Person (NOC) RR&S Department
            </Text>
          </View>
        </View>

        {/* <View style={styles.centered}>
        <Text style={styles.bold}>____________________________________</Text>
      </View>

      <View style={styles.centered}>
        <Text style={styles.bold}></Text>
      </View> */}

        {/* Footer */}
        {/* <Text
        style={styles.pageNumbers}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      /> */}
      </Page>
    </Document>
  );
};

export default ReportDocumentTemplate;
