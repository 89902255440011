import React from 'react'
import ReadableTable from '../readableTable/ReadableTable';
import { getFormatedDate } from '../../Utility';

const NocWorkPlan = ({ nocWorkPlan }) => {

  const {nocProjectDetail,nocLastExtensionDetail} = nocWorkPlan;
  const mainHeader = [
    "Work Type",
    "Description",
    "Start Date",
    "End Date",
    "Responsibility",
    "Means Of Verification",
  ];
  // "Progress"
  const subHeader = [
    "Work Type",
    "Description",
    "Start Date",
    "End Date",
    "Responsibility",
    "Means Of Verification",
  ];
  // "Progress"



  const subData = [
    {
      "Work Type": "Outcome",
      "Description": "Initiate Project Activities Approved Under The NOC",
      "Start Date": "31-Jul-2024",
      "End Date": "31-Jul-2024",
      "Responsibility": "Administrative Assistant",
      "Means Of Verification": "Project Initiation Report, Jan 2024, Feb 2021",
      "Work Plan": "Approved Project Work Plan"
    },
    {
      "Work Type": "Output",
      "Description": "Inform Stakeholders (Local Authorities, Community Leaders) About Project Start",
      "Start Date": "01-Jul-2024",
      "End Date": "05-Jul-2024",
      "Responsibility": "Communications Officer",
      "Means Of Verification": "Emails, Meeting Attendance Sheets, Jan 2024, Feb 2021",
      "Work Plan": "Communication Plan"
    },
    {
      "Work Type": "Outcome",
      "Description": "Mobilize Resources (Staff, Materials) To Project Site",
      "Start Date": "06-Jul-2024",
      "End Date": "15-Jul-2024",
      "Responsibility": "Logistics Coordinator",
      "Means Of Verification": "Inventory Records, Transport Logs, Jan 2024, Feb 2021",
      "Work Plan": "Logistics Plan"
    },
    {
      "Work Type": "Outcome",
      "Description": "Conduct Training For Staff And Volunteers On Project Implementation",
      "Start Date": "16-Jul-2024",
      "End Date": "20-Jul-2024",
      "Responsibility": "Training Coordinator",
      "Means Of Verification": "Training Attendance Sheets, Jan 2024, Feb 2021",
      "Work Plan": "Training Plan"
    },
    {
      "Work Type": "Outcome",
      "Description": "Begin Project Implementation As Per NOC Guidelines",
      "Start Date": "21-Jul-2024",
      "End Date": "31-Dec-2024",
      "Responsibility": "Field Operations Manager",
      "Means Of Verification": "Progress Reports, Field Visit Logs, Jan 2024, Feb 2021",
      // "Work Plan": "Implementation Plan"
    }
  ]


  return (
    <div className="noc-project-summary">
      <div className="header p-0">Work Plan</div>

      <div className=" d-flex flex-column gap-2 col-md-12 px-5 py-3">
        <div className=" col-md-12">
        {/* TODO Added Table with collapseable rows for sub activities */}
          <ReadableTable
            label="Initial Work Plan"
            headers={mainHeader}
            // data={nocWorkPlan?.map(({ workPlanSubActivities, id, verificationMeanId, startDate, endDate, description, responsible, verificationMean, workType, ...rest }) => {
            //   startDate = getFormatedDate(startDate);
            //   endDate = getFormatedDate(endDate);
            //   return { workType, description, startDate, endDate, responsible, verificationMean, ...rest };
            // })
            data={
            nocProjectDetail?.workPlans?.map(({ id, verificationMeanId, ...rest }) => ({

            workType: rest?.workType,
            description: rest?.description,
            startDate: getFormatedDate(rest?.startDate),
            endDate: getFormatedDate(rest?.endDate),
            responsible: rest?.responsible,
            verificationMean: rest?.verificationMean,

            workPlanSubActivities: rest?.workPlanSubActivities?.map(({id, verificationMeanId, ...items}) => ({
              workType: items?.workType,
              description: items?.description,
              startDate: getFormatedDate(items?.startDate),
              endDate: getFormatedDate(items?.endDate),
              responsible: items?.responsible,
              verificationMean: items?.verificationMean,
            }))}))
              ??
              []
              
              }

              isCollapsible={true}
          />
        </div>

       
       {nocLastExtensionDetail && <div className=" col-md-12">
           <ReadableTable
            label="Extension Work Plan"
            headers={subHeader}
            data={
              nocLastExtensionDetail?.workPlans?.map(({ id, verificationMeanId, ...rest }) => ({

            workType: rest?.workType,
            description: rest?.description,
            startDate: getFormatedDate(rest?.startDate),
            endDate: getFormatedDate(rest?.endDate),
            responsible: rest?.responsible,
            verificationMean: rest?.verificationMean,

            workPlanSubActivities: rest?.workPlanSubActivities?.map(({id, verificationMeanId, ...items}) => ({
              workType: items?.workType,
              description: items?.description,
              startDate: getFormatedDate(items?.startDate),
              endDate: getFormatedDate(items?.endDate),
              responsible: items?.responsible,
              verificationMean: items?.verificationMean,
            }))}))
              ??
              []
              
              }
              isCollapsible={true}
          /> 

        </div>}
      </div>
    </div>
  )
}

export default NocWorkPlan