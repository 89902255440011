import { getFormatedDate } from "../../Utility";
import AsyncDropdown from "./form-elements/AsyncDropdown";
import Button from "./form-elements/Button";
import Dropdown from "./form-elements/Dropdown";
import SelectableTableInput from "./form-elements/SelectableTableInput";
import TableInput from "./form-elements/TableInput";
import Upload from "./form-elements/Upload";
import DateInput from "./form-elements/DateInput";
import UploadImages from "./form-elements/UploadImages";
import MultiInput from "./form-elements/MultiInput";
import UploadMultiFiles from "./form-elements/UploadMultiFiles";
import CheckBox from "./form-elements/CheckBox";
import Switch from "./form-elements/Switch";
import RadioOption from "./form-elements/RadioOption";
import ProgressDetailTable from "../ProgressDetailTable/ProgressDetailTable";
import { toast } from "react-toastify";

export const inputTypes = {
  text: <input />,
  textarea: <textarea style={{}}></textarea>,
  h1: <h1></h1>,
  h2: <h2></h2>,
  h3: <h3></h3>,
  h4: <h4></h4>,
  h5: <h5></h5>,

  p: <p></p>,
  file: <Upload />,
  checkbox: <CheckBox />,
  switch: <Switch />,
  radioOption: <RadioOption />,
  multiFiles: <UploadMultiFiles />,
  date: <DateInput />,
  button: <Button type="button"></Button>,
  hidden: <input type="hidden"></input>,
  submit: <button></button>,
  dropdown: <Dropdown />,
  tableInput: <TableInput />,
  SelectableTable: <SelectableTableInput />,
  "async-dropdown": <AsyncDropdown />,
  image: <UploadImages />,
  multiInput: <MultiInput />,
  progressDetailTable: <ProgressDetailTable />,
};
export const getInputType = ({ inputType }) => {
  const output = inputTypes[inputType] ? inputTypes[inputType] : <input />;
  return output;
};
export const checkValidation = (
  key,
  { value } = "",
  rules,
  label = "",
  form
) => {

  const {
    min,
    max,
    required,
    isEmail,
    isNumber,
    isEndDate,
    compareWith,
    mustMatch,
  } = rules;
  let message = "";
  if (value && value.length > max) {
    message = `Value too long. max(${max})`;
  }
  if (value && value.length < min) {
    message = `Value too short. min(${min})`;
  }
  if (required && !value) {
    message = `${label} is required`;
  }
  if (isEmail && value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    message = `${label} must be a valid email address`;
  }
  if (isNumber && value && !/^\d+$/.test(value)) {
    message = `${label} must be a all numbers`;
  }
  if (isEndDate && compareWith) {
    const startDate = new Date(form[compareWith]?.value);
    const endDate = new Date(value);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      message = `Both ${label} and Start Date must be valid dates`;
    } else if (endDate < startDate) {
      message = `${label} must be greater than Start Date`;
    }
  }
  if (mustMatch && value !== form[mustMatch]?.value) {
    message = `${label} must match ${mustMatch}`;
  }
  if (!message) return {};
  return { [key]: { message } };
};
export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
export const handleChangeStrategies = (key = "default", state, setState) => {
  const stretegies = {
    default: (evt, def) => {
      const { name, value } = evt.target;
      setState({ ...state, [name]: { name, value } });
    },
    dropdown: (evt, def) => {
      const { name, isMulti = false } = def;

      setState({
        ...state,
        // [name]: isMulti ? evt : evt?.value,
        [name]: isMulti
          ? { name: name, value: evt }
          : { name: name, value: evt?.value },
        // [name]: isMulti ? { name: name, value: evt } : { name: name, value: { label: name, value: evt?.value } },
      });
    },
    asyncdropdown: (evt, def) => {
      // const { value, label } = evt ? evt : {label: 'Select', value: ''};
      const { value, label } = evt;

      const { name, isMulti, isEnum = false } = def;
      setState({
        ...state,
        [name]: isMulti
          ? { name, value: evt }
          : { name, value: { label, value }, isEnum },
        // label,
      });
    },
    "text-in-table": (evt, def) => {
      const { name, value } = evt.target;
      setState({
        ...state,
        [name]: { ...def, label: value, name, value },
      });
    },
    "dropdown-in-table": (evt, def) => {
      const { value, label } = evt;
      const { name } = def;
      setState({
        ...state,
        [name]: {
          ...def,
          label,
          value: { value, label },
        },
      });
    },
    onTableSelection: (evt, def) => {
      const { value } = evt;
      const { name } = def;
      setState({
        ...state,
        [name]: { name, value: { name, value } },
      });
    },
    onFileChange: (evt, def) => {
      const { name, value } = evt?.target;
      // const fileObject = evt?.target?.files[0];
      setState({ ...state, [name]: value });
    },
    onFileChangeTable: (evt, def) => {
      const { name, value, label } = evt?.target;
      setState({ ...state, [name]: { ...def, name, value, label: value } });
    },
    onMultiFileChange: (evt) => {
      const { name, value, isDeleted = false, updatedFiles = [] } = evt.target;
      if (isDeleted) {
        setState({ ...state, [name]: { value: updatedFiles } });
      } else
        setState({
          ...state,
          [name]: {
            value: state?.[name]
              ? [...state[name]?.value, ...evt.target.files]
              : [...evt.target.files],
          },
        });
    },
    // tableInput: (evt, def) => {

    //   const { name, value } = evt.target;
    //   setState({ ...state, [name]: { name, value } });
    // },
  };
  return stretegies[key];
};

export const formatFormValue = (
  type = "default",
  entry,
  inputType = "text",
  field
) => {
  const format = {
    default: (entry) => {
      if (typeof entry == "object") {
        if (
          inputType === "tableInput" ||
          inputType === "progressDetailtableInput"
        ) {
          const tableValues = entry?.value;

          const tableColumns = field?.header;
          const modifiedTable = tableValues?.map((tableValue) => {
            if (tableValue?.id) {
              let tableRow = {};
              tableColumns.forEach((column) => {
                const columnValue = tableValue[column?.name];
                const columnLabel =
                  typeof columnValue == "object"
                    ? columnValue?.name
                    : columnValue;
                tableRow = {
                  ...tableRow,
                  [column?.name]: {
                    ...column,
                    value: columnValue,
                    label: columnLabel,
                  },
                };
              });
              return { ...tableRow, edit: false };
            }
            return { ...tableValue };
          });
          return modifiedTable;
        }
        if (Array.isArray(entry)) return entry;
        else return entry.value;
      }
      // else if (inputType == "file")
      return entry;
    },
    date: (entry) => {
      return getFormatedDate(entry.value).split("/").reverse().join("-");
    },
    tableInput: (entry) => {
      return { ...entry, type: "tableInput" };
    },
    progressDetailtableInput: (entry) => {
      return { ...entry, type: "progressDetailtableInput" };
    },
  };
  if (entry === undefined) return "";
  const typeFunc = format[type];

  if (typeof typeFunc === "function") {
    return typeFunc(entry);
  }
  if (inputType === "tableInput") {
    return entry;
  }
  if (inputType === "progressDetailtableInput") {
    return entry;
  }
  if (inputType === "async-dropdown") {
    return { ...entry };
  }
  // if (inputType == 'file') {
  //   return { ...entry }
  // }
  return format.default(entry);
};

export const getConditionalFields = (fields, values) => {
  const implementFieldAction = (
    field,
    condition,
    parentFieldValue,
    parentFieldName
  ) => {
    if (condition?.action === "hide") {
      parentFieldValue =
        typeof parentFieldValue !== "undefined"
          ? parentFieldValue
          : getDefaultValue(fields, parentFieldName, "defaultIsChecked");

      if (condition?.hideOnLabel) {
        if (parentFieldValue?.value?.label === condition?.matchValue) {
          return { ...field, condition, hidden: true };
        }
      }

      if (
        parentFieldValue?.name === condition?.fieldName &&
        parentFieldValue?.value === condition?.matchValue
      ) {
        return { ...field, condition, hidden: true };
      }

      if (parentFieldValue?.value?.value === condition?.matchValue) {
        return { ...field, condition, hidden: true };
      }
    } else if (condition?.action === "useValue") {
      if (field?.inputType === "async-dropdown") {
        const ddParentFieldObject = values[parentFieldName]?.value;
        let ddParentFieldValue = ddParentFieldObject?.value
          ? ddParentFieldObject?.value
          : ddParentFieldObject?.id;
        const conditionalOperator = field?.url.includes("?") ? "&" : "?";
        const params = ddParentFieldValue
          ? `${conditionalOperator}${condition?.paramField}=${ddParentFieldValue}`
          : "";
        return {
          ...field,
          url: `${field?.url}${params}`,
        };
      }
      return field;
    }
    return field;
  };

  return fields?.map((field) => {
    if (field.condition) {
      const parentFieldName = field.condition.fieldName;
      const parentFieldValue = values[parentFieldName];
      field = implementFieldAction(
        field,
        field.condition,
        parentFieldValue,
        parentFieldName
      );
    }

    if (field.conditions && Array.isArray(field.conditions)) {
      field.conditions.forEach((condition) => {
        const parentFieldName = condition.fieldName;
        const parentFieldValue = values[parentFieldName];
        field = implementFieldAction(
          field,
          condition,
          parentFieldValue,
          parentFieldName
        );
      });
    }

    return field;
  });
};

function getDefaultValue(fields, name, key) {
  const searchedField = fields.find((field) => field.name === name);
  if (searchedField) return searchedField[key];
}

export const validateForm = (formData, form, dispatch) => {
  let newFormData = getConditionalFields(formData, form);
  let validation = {}; //custom validations
  newFormData?.map((i) => {
    if (i.validation && !i?.hidden) {
      let validationRes = checkValidation(
        i.name,
        typeof form[i.name] !== "object" && typeof form[i.name] !== "undefined"
          ? { value: form[i.name] }
          : form[i.name],
        i.validation,
        i.label,
        form
      );
      validation = { ...validation, ...validationRes };
    }
  });
  if (!isEmpty(validation)) {
    const notify = ({
      type = "error",
      message = "",
      position = toast.POSITION.TOP_RIGHT,
    } = {}) => {
      toast[type](<div dangerouslySetInnerHTML={{ __html: message }} />, {
        position: position,
      });
    };

    let message = Object.values(validation)
      .map((v) => v.message)
      .join("<br>");
    notify({ message });
    dispatch({ type: "form/setValidations", payload: validation });
  }
  return Object.keys(validation).length == 0;
};
