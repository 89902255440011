import React from "react";

const ClockIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.917 13.027a8.241 8.241 0 0 0 1.166-4.236c0-1.498-.4-2.905-1.098-4.12a.578.578 0 0 0-.06-.102A8.353 8.353 0 0 0 13.05 1.68a.57.57 0 0 0-.11-.065A8.241 8.241 0 0 0 8.792.5 8.24 8.24 0 0 0 4.76 1.549a.566.566 0 0 0-.108.06A8.353 8.353 0 0 0 1.66 4.568a.571.571 0 0 0-.06.104A8.241 8.241 0 0 0 .5 8.791a8.24 8.24 0 0 0 1.03 4c.01.02.019.04.03.059.007.013.015.024.024.036a8.353 8.353 0 0 0 2.897 2.986.58.58 0 0 0 .053.036l.043.022a8.24 8.24 0 0 0 4.214 1.153 8.24 8.24 0 0 0 4.092-1.082l.043-.02a.595.595 0 0 0 .055-.037 8.353 8.353 0 0 0 2.87-2.808.564.564 0 0 0 .066-.109Zm-3.077 1.64-.196-.343a.577.577 0 0 0-1 .572l.198.348c-.926.44-1.96.686-3.05.686a7.096 7.096 0 0 1-3.163-.74l.297-.5a.576.576 0 1 0-.992-.589l-.293.495a7.196 7.196 0 0 1-1.775-1.828l.432-.247a.576.576 0 0 0-.572-1l-.425.241a7.096 7.096 0 0 1-.648-2.97c0-1.1.25-2.144.697-3.075l.368.218a.574.574 0 0 0 .79-.202.577.577 0 0 0-.202-.79l-.375-.222a7.198 7.198 0 0 1 1.854-1.835l.195.342a.576.576 0 1 0 1.001-.572l-.193-.34a7.096 7.096 0 0 1 3.003-.663c1.114 0 2.168.256 3.108.713l-.125.21a.577.577 0 0 0 .992.589l.126-.213a7.2 7.2 0 0 1 1.755 1.76l-.237.136a.576.576 0 1 0 .572 1.001l.247-.141c.45.934.7 1.98.7 3.083a7.095 7.095 0 0 1-.755 3.195l-.262-.155a.577.577 0 0 0-.588.991l.25.15a7.202 7.202 0 0 1-1.734 1.695Z" />
      <path d="M10.666 6.376 8.905 8.138a.569.569 0 0 0-.055.064.574.574 0 0 0-.174-.29c-.936-.844-3.418-3.077-3.548-3.198a.577.577 0 0 0-.807.824c.031.031.315.288 3.584 3.23a.574.574 0 0 0 .848-.085.573.573 0 0 0 .56.439.575.575 0 0 0 .407-.169l1.761-1.761a.576.576 0 1 0-.815-.816Z" />
      <path d="M9.848 8.792a1.057 1.057 0 1 1-2.113-.002 1.057 1.057 0 0 1 2.113.002Z" />
    </svg>
  );
};

export default ClockIcon;
