import { directBenefFields } from "./directBenefFields";
import { geographicLocationFields } from "./geographicLocationFields";
import { logicalFrameworkFields } from "./logicalFrameworkFields";

export const applicationFields = [
  {
    category: "projectInformation",
    children: "Project Information",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "projectInformation",
    label: "Project Title",
    placeholder: "Project Title",
    name: "projectTitle",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-12",
    validation: {
      required: true,
    },
  },
  {
    category: "fundDetailsInNGO",
    children: "Fund Details",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "fundDetailsInNGO",
    label: "Fund Type",
    placeholder: "Select Fund Type",
    name: "fundTypeId",
    id: "fundTypeId",
    inputType: "async-dropdown",
    url: "/FundType",
    searchAttrib: "Search.value",
    labelAttribute: "type",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    isEnum: true,
    isMulti: false,
    validation: {
      required: true,
    },

    wrapperClass: "col-md-4",
  },
  {
    category: "fundDetailsInNGO",
    inputType: "hide",
    wrapperClass: "col-md-8",
    className: "bg-white",
    disabled: true,
  },
  {
    category: "fundDetailsInNGO",
    label: "Donor Type",
    name: "donorType",
    inputType: "radioOption",
    id: "donarType",
    labelAttribute: "name",
    valueAttribute: "id",
    url: "/Lookup/DonorType",
    inForm: true,
    wrapperClass: "col-md-12 mt-3",
    validation: {
      required: true,
    },
    condition: {
      fieldName: "fundTypeId",
      matchValue: "Self Funded",
      action: "hide",
      hideOnLabel: true,
    },

    // hidden:true,
  },
  {
    category: "fundDetailsInNGO",
    label: "Project Specific MOU",
    placeholder: "Upload Project MOU with EAD",
    inputType: "file",
    name: "mouDoc",
    id: "mouDoc",
    url: "/FileUpload/nocApplication/file",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-4",
    conditions: [
      {
        fieldName: "donorType",
        matchValue: 2,
        action: "hide",
      },
      {
        fieldName: "fundTypeId",
        matchValue: "Self Funded",
        action: "hide",
        hideOnLabel: true,
      },
    ],
    validation: {
      required: true,
    },
  },
  {
    category: "fundDetailsInNGO",
    label: "Start Date",
    placeholder: "Start Date of MOU",
    name: "mouStartDate",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-4",
    inputType: "date",
    conditions: [
      {
        fieldName: "donorType",
        matchValue: 2,
        action: "hide",
      },
      {
        fieldName: "fundTypeId",
        matchValue: "Self Funded",
        action: "hide",
        hideOnLabel: true,
      },
    ],
    validation: {
      required: true,
    },
  },
  {
    category: "fundDetailsInNGO",
    label: "End Date",
    placeholder: "End Date of MOU",
    name: "mouEndDate",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-4",
    inputType: "date",
    conditions: [
      {
        fieldName: "donorType",
        matchValue: 2,
        action: "hide",
      },
      {
        fieldName: "fundTypeId",
        matchValue: "Self Funded",
        action: "hide",
        hideOnLabel: true,
      },
    ],
    validation: {
      required: true,
      compareWith: "mouStartDate",
      isEndDate: true,
    },
  },
  {
    category: "planUnderProject",
    children: "Plan Under Which Project Falls (If Applicable)",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "planUnderProject",
    children: "Please Tick relevant boxes, Multiple selection possible",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "planUnderProject",
    text: "Pakistan Humanitarian Response Plan (PHRP)",
    name: "phrp",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "planUnderProject",
    text: "Non-Pakistan Humanitarian Response Plan (Non-PHRP)",
    name: "nonPhrp",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "planUnderProject",
    text: "Flood Emergency Response Plan (FERP)",
    name: "ferp",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "planUnderProject",
    text: "Non-Flood Emergency Response Plan (Non-FERP)",
    name: "nonFerp",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "planUnderProject",
    text: "Other",
    name: "otherPlan",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
    condition: {
      fieldName: "nonFerp",
      matchValue: false,
      action: "hide",
    },
  },
  {
    category: "planUnderProject",
    name: "otherPlanSpecify",
    placeholder: "Specify Other Here",
    inForm: true,
    wrapperClass: "col-md-6 mt-2",
  },
  {
    category: "clusterUnderProject",
    children: "Cluster  Under Which  Project Falls",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "heading2",
    validation: {
      required: true,
    },
  },
  {
    category: "clusterUnderProject",
    children:
      "Please tick (√) relevant boxes, Multiple selection possible. Vetting from relevant cluster head will  be required",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "clusterUnderProject",
    text: "Agriculture",
    name: "agriculture",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Camp Coordination",
    name: "campCoordination",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Child Protection (Sub-cluster)",
    name: "childProtection",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Community Restoration",
    name: "communityRestoration",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Education",
    name: "education",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Food Security",
    name: "foodSecurity",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Gender",
    name: "gender",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Health",
    name: "health",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Nutrition",
    name: "nutrition",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Protection",
    name: "protection",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },

  {
    category: "clusterUnderProject",
    text: "Rule of Law & Justice",
    name: "ruleOfLawAndJustice",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Skill Development",
    name: "skillDevelopment",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Shelter and Non-Food Items",
    name: "shelterAndNonFoodItems",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "DRR / CCA",
    name: "drrCca",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Wash",
    name: "wash",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "clusterUnderProject",
    text: "Other",
    name: "other",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "geographicLocation",
    children: "Geographic Location & Beneficiaries",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "geographicLocation",
    children: "",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "geographicLocation",
    label: "",
    name: "geographicLocations",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: geographicLocationFields,
  },
  {
    category: "thematicArea",
    children: "Thematic Areas Under  Which the  Project Falls",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "thematicArea",
    children: "Please tick (√) relevant boxes",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "thematicArea",
    text: "Legislation, Policy, Institutional Mandate & Institutional Development",
    name: "legislationDevelopment",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Hazard, Vulnerability and Risk Assessment",
    name: "hazardVulnerabilityRiskAssessment",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Public Awareness, Education and Training",
    name: "publicAwarenessEducationTraining",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Community Resilience through CBDRM",
    name: "communityResilienceThroughCbdrm",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Mainstreaming Disaster Risk Reduction into Development Planning",
    name: "mainstreamingPlanning",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Early Warning System",
    name: "earlyWarningSystem",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Preparedness and Response Planning",
    name: "preparednessAndResponsePlanning",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "thematicArea",
    text: "Post Disaster Recovery Planning",
    name: "postDisasterRecoveryPlanning",
    inputType: "checkbox",
    inForm: true,
    wrapperClass: "col-md-6",
  },

  {
    category: "directBeneficiaries",
    children: "Estimated  Number Of  Direct  Beneficiaries",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "directBeneficiaries",
    children:
      "Please fill in the total number of direct HH/beneficiaries.For More than one  beneficiaries add in separate",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "directBeneficiaries",
    name: "projectBeneficiaries",
    id: "projectBeneficiaries",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: directBenefFields,
  },
  {
    category: "proposedTimeFrame",
    children: "Proposed Timeframe For Project",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "proposedTimeFrame",
    children: "Please indicate the duration of the project:  ",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "proposedTimeFrame",
    label: "Start",
    placeholder: "Choose Start Date",
    name: "projectStartDate",
    inForm: true,
    wrapperClass: "col-md-4",
    inputType: "date",
    isEnum: true,
    validation: {
      required: true,
    },
  },
  {
    category: "proposedTimeFrame",
    label: "End",
    placeholder: "Choose End Date",
    name: "projectEndDate",
    inForm: true,
    wrapperClass: "col-md-4",
    inputType: "date",
    isEnum: true,
    validation: {
      required: true,
      compareWith: "projectStartDate",
      isEndDate: true,
    },
  },

  {
    category: "summaryOfProjectCost",
    children: "Summary Of  Estimated  Project Cost",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "heading2",
  },
  {
    category: "summaryOfProjectCost",
    children:
      "Please fill in the total cost in USDs. If project is covering more than one sector. Indicate separate project costs for separate sectors",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "summaryOfProjectCost",
    label: "Fund Currency",
    placeholder: "Select Currency of Fund",
    name: "fundsCurrencyId",
    id: "fundsCurrencyId",
    inputType: "async-dropdown",
    url: "/Currency",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    validation: {
      required: true,
    },
    inForm: true,
    isMulti: false,
    isEnum: true,
  },
  {
    category: "summaryOfProjectCost",
    children: "Program Cost",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "heading2",
  },
  {
    category: "summaryOfProjectCost",
    label: "Amount In USD",
    placeholder: "Enter Amount In USD",
    name: "amountinUSD",
    inForm: true,
    wrapperClass: "col-md-4",
    isEnum: true,
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "summaryOfProjectCost",
    label: "Equivalent PKR amount",
    placeholder: "Enter Equivalent PKR amount",
    name: "amountInPKR",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
    condition: {
      fieldName: "fundsCurrencyId",
      matchValue: "PKR",
      action: "hide",
      hideOnLabel: true,
    },
  },
  {
    category: "summaryOfProjectCost",
    label: "% Age to Total",
    placeholder: "Enter Age to Total",
    name: "totalPercentage",
    onChangeMethod: "text-in-table",
    wrapperClass: "col-md-4",
    inForm: true,
    isEnum: true,
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "summaryOfProjectCost",
    label: "Remarks",
    placeholder: "Enter Remarks",
    name: "remarks",
    onChangeMethod: "text-in-table",
    wrapperClass: "col-md-8",
    inForm: true,
    isEnum: true,
    validation: {
      required: true,
    },
  },

  // work Plan
  {
    category: "workPlan",
    children: "Work Plan",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "workPlan",
    children: "Enter Work Plan for the Project",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "workPlan",
    name: "workPlans",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Work Type",
        placeholder: "Select Work Type",
        name: "workType",
        id: "workType",
        inputType: "async-dropdown",
        url: "/Lookup/WorkType",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        className: "px-2",
        inForm: true,
        isMulti: false,
        isEnum: true,
      },
      {
        label: "Work Description",
        placeholder: "Enter Work Description",
        name: "description",
        id: "description",
        inForm: true,
        isMulti: false,
        className: "px-2",
        onChangeMethod: "text-in-table",
        isEnum: true,
      },

      {
        label: "Start Date",
        placeholder: "Start Date",
        name: "startDate",
        inForm: true,
        wrapperClass: "col-md-4",
        inputType: "date",
        onChangeMethod: "text-in-table",
        isEnum: true,
      },
      {
        label: "End Date",
        placeholder: "End Date",
        name: "endDate",
        inForm: true,
        wrapperClass: "col-md-4",
        inputType: "date",
        onChangeMethod: "text-in-table",
        isEnum: true,
      },
      {
        label: "Responsibility",
        placeholder: "Enter Responsible Entity",
        name: "responsible",
        onChangeMethod: "text-in-table",
        inForm: true,
        isEnum: true,
      },

      {
        label: "Means of Verification",
        placeholder: "Select MoV",
        name: "verificationMeanId",
        id: "verificationMeanId",
        inputType: "async-dropdown",
        url: "/VerificationMean",
        searchAttrib: "Search.value",
        labelAttribute: "mean",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        className: "px-2",
        isEnum: true,
        inForm: true,
        isMulti: false,
        style: { zIndex: 999 },
      },
    ],
  },
  {
    category: "logicalFramework",
    children: "Logical Framework",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "logicalFramework",
    children: "Enter Logical Framework For The Project",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "logicalFramework",
    name: "logicalFrameworks",
    id: "logicalFrameworks",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: logicalFrameworkFields,
  },
  {
    category: "projectDetail",
    children: "Project Details",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "projectDetail",
    label: "Project Summary",
    placeholder: "Project Summary",
    name: "projectSummary",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    validation: {
      required: true,
    },
    isEnum: true,
  },
  {
    category: "projectDetail",
    label: "Project Background",
    placeholder: "Project Background",
    name: "projectBackground",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    isEnum: true,
    validation: {
      required: true,
    },
  },
  {
    category: "projectDetail",
    label: "Project Rational",
    placeholder: "Project Rational",
    name: "projectRational",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
      isEnum: true,
    },
    validation: {
      required: true,
    },
  },
  {
    category: "projectDetail",
    label: "Implementation Strategy",
    placeholder: "Implementation Strategy",
    name: "implementationStratergy",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    validation: {
      required: true,
    },
  },
  {
    category: "projectDetail",
    label: "Project Goal",
    placeholder: "Project Goal",
    name: "projectGoal",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    isEnum: true,
    validation: {
      required: true,
    },
  },
  {
    category: "projectDetail",
    label: "Outcomes & Output",
    placeholder: "Outcomes & Output",
    name: "outcome",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    isEnum: true,
    validation: {
      required: true,
    },
  },
  {
    category: "projectDetail",
    label: "Coordination Mechanism",
    placeholder: "Coordination Mechanism",
    name: "cordinationMechanism",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    isEnum: true,
    validation: {
      required: true,
    },
  },
  {
    category: "projectDetail",
    label: "Monitoring Strategy",
    placeholder: "Monitoring Strategy",
    name: "monitoringStratergy",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
    style: {
      height: "120px",
      maxHeight: "140px",
    },
    isEnum: true,
    validation: {
      required: true,
    },
  },

  // supporting documents
  {
    category: "supportingDocument",
    children: "Supporting documents List",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "supportingDocument",
    children: "The following documents are enclosed with the project proposal.",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "supportingDocument",
    label: "Covering letter from the Organization to Secretary RR&SD*",
    placeholder: "Upload Covering Letter",
    inputType: "file",
    name: "coveringLetter",
    id: "coveringLetter",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Proposed Project Staff",
    placeholder: "Upload Proposed Project Staff (Excel)",
    inputType: "file",
    name: "proposedProjectStaff",
    id: "proposedProjectStaff",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Documentary proof / evidence of the of source of funding",
    placeholder: "Upload Funding Proof",
    inputType: "file",
    name: "fundingProof",
    id: "fundingProof",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Application vetted by the concerned ADC Relief or Line Department",
    placeholder: "Upload Application",
    inputType: "file",
    name: "applicationVetted",
    id: "applicationVetted",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Detail budget estimate",
    placeholder: "Upload Application",
    inputType: "file",
    name: "detailBudgetEstimate",
    id: "detailBudgetEstimate",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Agreement between donor and implementing organization",
    placeholder: "Upload Agreement",
    inputType: "file",
    name: "agreementDonorImplementingOrganization",
    id: "agreementDonorImplementingOrganization",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Start Date of Agreement between Donor & Implementing Organization",
    placeholder: "",
    inputType: "date",
    name: "agreementStartDate",
    id: "agreementStartDate",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "End Date of Agreement between Donor & Implementing Organization",
    placeholder: "",
    inputType: "date",
    name: "agreementEndDate",
    id: "agreementEndDate",
    inForm: true,
    wrapperClass: "col-md-6",
  },

  {
    category: "supportingDocument",
    inputType: "hide",
    wrapperClass: "col-md-12 mt-4",
    className: "bg-white",
  },

  {
    category: "supportingDocument",
    label: "Annex A, B, C",
    placeholder: "Upload Unex",
    inputType: "file",
    name: "annexABC",
    id: "annexABC",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "One Page Summary",
    placeholder: "Upload One Page Summary of Project",
    inputType: "file",
    name: "onePageSummaryOfProject",
    id: "onePageSummaryOfProject",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "supportingDocument",
    label: "Affidavit on Stamp Paper",
    placeholder: "Upload Affidavit",
    inputType: "file",
    name: "affidavitOnStampPaper",
    id: "affidavitOnStampPaper",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
  },

  {
    category: "agreementDates",
    label: "Start Date of Agreement between Donor & Implementing Organization",
    placeholder: "Add Start Date Of Agreement",
    inputType: "date",
    name: "agreementStartDate",
    id: "agreementStartDate",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "agreementDates",
    label: "End Date of Agreement between Donor & Implementing Organization",
    placeholder: "Add End Date Of Agreement",
    inputType: "date",
    name: "agreementEndDate",
    id: "agreementEndDate",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "agreementDates",
    label: "Donor Organization",
    placeholder: "Name of Donor Organization",
    name: "donorOrganization",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-6",
    condition: {
      fieldName: "fundTypeId",
      matchValue: "Self Funded",
      action: "hide",
      hideOnLabel: true,
    },
    validation: {
      required: true,
    },
  },
];
