import React from "react";
import ReadableTable from "../../readableTable/ReadableTable";
import { formateDate, getFormatedDate } from "../../../Utility";

const WorkPlan = ({ extension, data = {} }) => {
  return (
    <div
      className={` my-5 ${
        extension ? "col-md-12 shadow-sm bg-white mb-2 py-2 px-4 rounded" : ""
      }`}
    >
      <ReadableTable
        headers={[
          "Work Type",
          "Description",
          "Start Date",
          "End Date",
          "Responsibility",
          "Means Of Verification",
        ]}
        data={data?.map(({ id, verificationMeanId, ...rest }) => ({
          workType: rest?.workType,
          description: rest?.description,
          startDate: getFormatedDate(rest?.startDate),
          endDate: getFormatedDate(rest?.endDate),
          responsible: rest?.responsible,
          verificationMean: rest?.verificationMean,

          workPlanSubActivities: rest?.workPlanSubActivities?.map(
            ({ id, verificationMeanId, ...items }) => ({
              workType: items?.workType,
              description: items?.description,
              startDate: getFormatedDate(items?.startDate),
              endDate: getFormatedDate(items?.endDate),
              responsible: items?.responsible,
              verificationMean: items?.verificationMean,
            })
          ),
        }))}
        isCollapsible={true}
        label={`${extension ? "Initial Work Plan" : "Revised Work Plan"}`}
      />
    </div>
  );
};

export default WorkPlan;
