import { Link } from "react-router-dom";
import Badge from "../../components/badge/Badge";
import { getStatusBadge } from "../../Utility";

const UserManagementColumns = [
  {
    name: "Organization Name",
    selector: (row) => (
      <>
       
        
        <div className="text-capitalize">
      
        {
          row?.role === "NGO User" ?
            <div >
            <Link to={`/user/ngo-user-details/${row?.userId}`} >
            {row?.organizationName}
            </Link>
            </div>
            :
           <div >  {row?.organizationName ?? "KP Government"}</div>
        }
        </div>
      </>
    ),
  },
  {
    name: "User Name",
    selector: (row) => (
      <>
        {/* <Link to={`${row?.id}`} className="text-capitalize"> */}
        {
          row?.role === "NGO User" ?
            <div className="text-capitalize">{row?.name}</div>
            :
            <Link to={`/user-management/${row?.userId}`} className="text-capitalize">
              {row?.name}
            </Link>
        }



      </>
    ),

  },
  {
    name: "Email",
    selector: (row) => row?.email,

  },
  {
    name: "Phone No.",
    selector: (row) => row?.phone,

  },
  {
    name: "Status",
    selector: (row) => (
      <Badge
        className="m-1 text-center btn btn-sm"
        style={{ lineHeight: "10px", fontSize: "11px" }}
      >
        {getStatusBadge(row?.status)}



      </Badge>
    ),
  },

  // {
  //   name: "Image URL",
  //   selector: (row) => row?.imageUrl,
  // },
  // {
  //   name: "Device ID",
  //   selector: (row) => row?.deviceId,
  // },
  // {
  //   name: "isApproved",
  //   selector: (row) =>
  //     <label className={getStatusStyle(row?.isApproved = "false")}>
  //       {row?.status.charAt(0).toUpperCase() + row?.isApproved.slice(1)}
  //     </label>
  // },
];

export default UserManagementColumns;
