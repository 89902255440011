import React, { useEffect, useMemo, useState } from "react";
import "./OperationalCostTable.scss";
import { useDispatch, useSelector } from "react-redux";
import { setFormValues } from "../../redux/reducers";

const OperationalCostTable = () => {
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.form?.values);
  const {
    projectBeneficiaries = [],
    operationalCost = [],
    amountinUSD,
    amountInPKR,
  } = formValues;

  const fundCurrencyLable = formValues?.fundsCurrencyId?.value?.label;

  const calculateTotalAmountInUSD = useMemo(() => {
    return operationalCost?.value?.reduce((acc, item) => {
      const amount = parseFloat(item?.amountinUSD);
      return (
        acc + (isNaN(amount) ? 0 : amount) + parseInt(amountinUSD?.value || 0)
      );
    }, 0);
  }, [operationalCost, amountinUSD]);

  const calculateTotalAmountInPKR = useMemo(() => {
    return operationalCost?.value?.reduce((acc, item) => {
      const amount = parseFloat(item?.amountInPKR);
      return (
        acc + (isNaN(amount) ? 0 : amount) + parseInt(amountInPKR?.value || 0)
      );
    }, 0);
  }, [operationalCost, amountInPKR]);

  const [localBeneficiaries, setLocalBeneficiaries] = useState(
    projectBeneficiaries?.value ?? []
  );

  useEffect(() => {
    const d = formateTableData();
    setLocalBeneficiaries(d);
  }, [projectBeneficiaries]);

  const onHandleChangeItem = (index, key, value) => {
    const updatedData = [...localBeneficiaries];

    updatedData[index] = {
      ...updatedData[index],
      [key]: value,
    };

    setLocalBeneficiaries(updatedData);

    dispatch({
      type: setFormValues.type,
      payload: {
        ...formValues,
        operationalCost: {
          name: "operationalCost",
          value: updatedData,
        },
      },
    });
  };

  const formateTableData = () => {
    const reqData = projectBeneficiaries?.value?.map((item) => {
      return {
        sector: item?.clusterId?.value,
        district: item?.districtId?.value,
      };
    });
    const operationalCostVal = operationalCost?.value ?? [];

    const updatedData = reqData?.map((item, i) => {
      const isExist = operationalCostVal?.find(
        (data) =>
          data.sector?.value === item?.sector?.value &&
          data.district?.value === item?.district?.value
      );

      if (isExist) {
        return isExist;
      } else {
        return {
          sector: item?.sector,
          district: item?.district,
          amountinUSD: operationalCostVal[i]?.amountinUSD || 0,
          amountInPKR: operationalCostVal[i]?.amountInPKR || null,
          totalPercentage: operationalCostVal[i]?.totalPercentage || 0,
          remarks: operationalCostVal[i]?.remarks || "",
        };
      }
    });

    return updatedData;
  };

  return (
    <div className="container-fluid mt-5 px-0">
      <p className="heading2">Operational Cost</p>
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr className="bg-light">
            <th>Sector</th>
            <th>District</th>
            <th>Amount in {fundCurrencyLable ?? "USD"}</th>
            {fundCurrencyLable !== "PKR" && <th>Equivalent PKR Amount</th>}
            <th>% Age To Total</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {!localBeneficiaries?.length > 0 && (
            <tr>
              <td colSpan="6" className="text-center">
                No data found
              </td>
            </tr>
          )}
          {localBeneficiaries?.length > 0 &&
            localBeneficiaries?.map((item, index) => (
              <tr key={index}>
                <td className="font-weight-bold text-success" name="clusterId">
                  {item?.sector?.label || item?.clusterName}
                </td>
                <td className="font-weight-bold text-success" name="districtId">
                  {item?.district?.label || item?.districtName}
                </td>
                <td>
                  <input
                    type="number"
                    value={item?.amountinUSD || ""}
                    onChange={(e) =>
                      onHandleChangeItem(
                        index,
                        "amountinUSD",
                        parseFloat(e.target.value)
                      )
                    }
                    name="amountinUSD"
                    className="form-control"
                    placeholder={`Enter Amount in ${fundCurrencyLable}`}
                  />
                </td>
                {fundCurrencyLable !== "PKR" && (
                  <td>
                    <input
                      type="number"
                      value={item?.amountInPKR || ""}
                      onChange={(e) =>
                        onHandleChangeItem(
                          index,
                          "amountInPKR",
                          parseFloat(e.target.value)
                        )
                      }
                      name="amountInPKR"
                      className="form-control"
                      placeholder="Enter in Millions PKR"
                    />
                  </td>
                )}
                <td>
                  <input
                    type="number"
                    value={item?.totalPercentage || ""}
                    onChange={(e) =>
                      onHandleChangeItem(
                        index,
                        "totalPercentage",
                        parseFloat(e.target.value)
                      )
                    }
                    name="totalPercentage"
                    className="form-control"
                    placeholder="Enter % Age"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item?.remarks || ""}
                    onChange={(e) =>
                      onHandleChangeItem(index, "remarks", e.target.value)
                    }
                    className="form-control"
                    name="remarks"
                    placeholder="Enter remarks"
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <p className="heading2 mt-3">Total Amount</p>
      <div className="col-md-12 row px-0">
        <div className="col-md-4 ">
          <p className="regular-text">
            Total Amount in {fundCurrencyLable ?? "USD"}
          </p>
          <p className="heading2 bg-light py-2 rounded text-center">
            {(fundCurrencyLable ?? "USD") + "." ?? "USD."}
            {calculateTotalAmountInUSD ?? parseInt(amountinUSD?.value || 0)}
          </p>
        </div>
        {fundCurrencyLable !== "PKR" && (
          <div className="col-md-4 ">
            <p className="regular-text">Total Amount in PKR</p>
            <p className="heading2 bg-light py-2 rounded text-center">
              RS.
              {calculateTotalAmountInPKR ?? parseInt(amountInPKR?.value || 0)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OperationalCostTable;
