export const logicalFrameworkFields = [
  {
    label: "Narrative Summary",
    placeholder: "Select Narrative Summary",
    name: "narrativeSummery",
    id: "narrativeSummery",
    inputType: "async-dropdown",
    url: "/Lookup/NarrativeSummary",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "name",
    onChangeMethod: "dropdown-in-table",
    className: "px-2",
    inForm: true,
    isEnum: true,
    isMulti: false,
  },
  {
    label: "Expected Results Type",
    placeholder: "Select Expected Results",
    name: "resultType",
    id: "resultType",
    inputType: "async-dropdown",
    url: "/Lookup/ExpectedResultType",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "name",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    isMulti: false,
    className: "px-2",
    isEnum: true,
  },
  {
    label: "Expected Results",
    placeholder: "Expected Results",
    name: "expectedResult",
    inForm: true,
    wrapperClass: "col-md-4",
    onChangeMethod: "text-in-table",
    isEnum: true,
  },
  {
    label: "Performance Measurment",
    placeholder: "Performance Measurment",
    name: "performanceMeasure",
    inForm: true,
    wrapperClass: "col-md-4",
    isEnum: true,
    onChangeMethod: "text-in-table",
  },
  {
    label: "Assumption & Risk",
    placeholder: "Enter Assumption & Risk",
    name: "assumptionsAndRisks",
    onChangeMethod: "text-in-table",
    wrapperClass: "col-md-4",
    isEnum: true,
    inForm: true,
  },
];
