import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import "./Header.scss";
import { saveUserState } from "../../utils/localStorage";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import Notifications from "../notification/Notifications";
import HeaderProfile from "../header-profile/HeaderProfile";
import { FaSearch } from "react-icons/fa";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  // const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const { id = "", name = "" } = useSelector((state) => state?.setting?.user);
  const { pageMeta } = useSelector((state) => state.setting);

  const items = useSelector(
    (state) => state.records?.current?.headerNotification?.data?.items
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({
        type: "records/setSearch",
        payload: {
          generalSearch: searchText,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  function handleChange({ value }) {
    if (value == "logout") {
      dispatch({ type: "setting/setUser", payload: {} });
      saveUserState({});
      navigate("/");
    } else {
      navigate(`user/${value}`);
    }
  }

  function handleChangeValue(value) {
    if (value == "logout") {
      dispatch({ type: "setting/setUser", payload: {} });
      saveUserState({});
      navigate("/");
    } else {
      navigate(`user/${value}`);
    }
  }

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/GetAll`,
        params: { OrderDir: ORDER_DIRECTION.DESC },
        appendCurrentReducerKey: "headerNotification",
      },
    });
  };

  const [isImageClicked, setIsImageClicked] = useState(false);

  return (
    <div className="header d-flex justify-content-between bg-white">
      <div className="col-md-8 col-12 d-flex align-items-center input-search">
        <FaSearch />
        <input
          placeholder="Search here..."
          value={searchText?.generalSearch ?? ""}
          onChange={(e) => setSearchText({ generalSearch: e.target.value })}
        />
      </div>

      {/* right side */}
      <div className=" right col-md-4 col-4 d-flex flex-row align-items-center justify-content-between">
        {/* <div className=" col-md-2 col-2 text-right align-items-center"> */}
        <div className="col d-flex flex-row justify-content-end">
          <HeaderProfile />
        </div>
        <div className="col-2 col-md-2">
          <Notifications />
        </div>
      </div>
    </div>
  );
};

export default Header;
