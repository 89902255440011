import * as React from "react"
const CheckFileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
   
    
    {...props}
  >
    <path
    className={`${props.className}`}     fill={`${props.fill ?? "#075F33"}`} 
      d="M24.63 7.822 17.9 1.13a2.166 2.166 0 0 0-.706-.466A2.216 2.216 0 0 0 16.358.5H5.794c-.573 0-1.123.221-1.528.615A2.07 2.07 0 0 0 3.632 2.6v.7h-.72c-.574 0-1.123.221-1.529.615A2.07 2.07 0 0 0 .75 5.4v3.5a.69.69 0 0 0 .211.495.731.731 0 0 0 1.02 0 .69.69 0 0 0 .21-.495V5.4a.69.69 0 0 1 .211-.495.731.731 0 0 1 .51-.205h.72v18.9c0 .557.228 1.091.634 1.485.405.394.955.615 1.528.615h15.132v.7a.69.69 0 0 1-.21.495.731.731 0 0 1-.51.205H2.912a.731.731 0 0 1-.51-.205.69.69 0 0 1-.21-.495V11.7a.69.69 0 0 0-.212-.495.731.731 0 0 0-1.019 0 .69.69 0 0 0-.211.495v14.7c0 .557.228 1.091.633 1.485.406.394.955.615 1.529.615h17.294c.573 0 1.123-.221 1.529-.615a2.07 2.07 0 0 0 .633-1.485v-.7h.72c.573 0 1.123-.221 1.529-.615a2.07 2.07 0 0 0 .633-1.485V9.285a2.002 2.002 0 0 0-.159-.792 2.05 2.05 0 0 0-.46-.671ZM18.044 3.3l4.958 4.9h-4.237a.732.732 0 0 1-.51-.205.69.69 0 0 1-.21-.495V3.3Zm5.765 20.3a.69.69 0 0 1-.211.495.732.732 0 0 1-.51.205H5.794a.732.732 0 0 1-.51-.205.69.69 0 0 1-.21-.495v-21a.69.69 0 0 1 .21-.495.731.731 0 0 1 .51-.205h10.564a.739.739 0 0 1 .245.049V7.5c0 .557.228 1.091.633 1.485.405.394.955.615 1.529.615h5.044v14Zm-4.59-11.746a.706.706 0 0 1 .265.472.681.681 0 0 1-.157.515l-5.765 7A.72.72 0 0 1 13 20.1a.734.734 0 0 1-.447-.154l-3.603-2.8a.686.686 0 0 1-.115-.98.717.717 0 0 1 .484-.261.74.74 0 0 1 .532.149l3.034 2.359 5.318-6.447a.717.717 0 0 1 .484-.261.74.74 0 0 1 .532.149Z"
    />
  </svg>
)
export default CheckFileIcon
