import { customStyles } from "./CustomStyle";

export const defaultListStyle = {
    ...customStyles,
    headCells: {
      style: {
        fontSize: "14px",
        color: "#000000",
        fontWeight: "500",
        paddingLeft: "16px",
        fontFamily: "Roboto Flex, sans-serif",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#E0E0E0",
        },
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#E0E0E0",
          borderTopRightRadius: "6px",
        },
        "&:first-of-type": {
          borderRight: "none",
          borderLeftStyle: "solid",
          borderLeftWidth: "1px",
          borderLeftColor: "#E0E0E0",
          borderTopLeftRadius: "6px",
        },
      },
    },
    cells: {
      style: {
        height: "48px",
        fontSize: "13px",
        paddingLeft: "16px",
        color: "#1E1F23",
        fontFamily: "Roboto Flex, sans-serif",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#E0E0E0",
        },
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#E0E0E0",
        },
        "&:first-of-type": {
          borderRightStyle: "none",
          borderLeftStyle: "solid",
          borderLeftWidth: "1px",
          borderLeftColor: "#E0E0E0",
        },
      },
    },
  };