import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: {},
  validations: {},
  lookups: {},
  uploads: {},
  reset: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormValues(state, action) {
      return { ...state, values: { ...state.values, ...action.payload }, uploads:{} };
    },
    setUploadedFile(state, action) {
      const { name, documentId, isObj, data } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [name]: {data, documentId, isObj},
        },
      };
    },
    setFormFile(state, action) {
      const { name, documentId, isObj, data } = action.payload;
      const filePath = data.filePath; // Assume the file path is provided in the data

      if (isObj) {
        const existingDocuments = state.values?.applicationDocuments?.value ?? [];
        const updatedDocuments = existingDocuments?.filter(
          (doc) => doc.documentId !== documentId
        );

        updatedDocuments.push({
          documentId: documentId,
          appDoc: filePath,
        });

        return {
          ...state,
          values: {
            ...state.values,
            [name]: {
              name: name,
              value: updatedDocuments,
            },
          },
        };
      } else {
        return {
          ...state,
          values: {
            ...state.values,
            [name]: filePath,
          },
        };
      }
    },
    setValidations(state, action) {
      return { ...state, validations: action.payload };
    },
    setLookup: (state, action) => {
      let data = action.payload?.data;
      let key = action.payload.key;
      let lookup = { [key]: data };
      return {
        ...state,
        lookups: { ...state.lookups, ...lookup },
      };
    },
    clearForm: (state, action) => {
      return {
        ...state,
        reset: true,
        values: {},
      };
    },
  },
});

export const { setValidations, clearForm, setLookup, setFormValues } =
  formSlice.actions;
// Action creators are generated for each case reducer function

export default formSlice.reducer;
