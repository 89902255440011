import USER_ORGANIZATION_STATUS from "../enums/USER_ORGANIZATION_STATUS";

const userOrganizationStatus = {
    [USER_ORGANIZATION_STATUS.All]: 'All',
    [USER_ORGANIZATION_STATUS.Approved]: 'Approved',
    [USER_ORGANIZATION_STATUS.Blocked]: 'Blocked',
    [USER_ORGANIZATION_STATUS.Pending]: 'Pending',
    [USER_ORGANIZATION_STATUS.UNDER_PROCESS]: 'Under Process',

}

export default userOrganizationStatus;