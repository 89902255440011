import React, { useEffect, useState } from "react";
import OrganizationInformationDetail from "../../components/applications/applicationDetail/OrganizationInformationDetail";
import HumanResourceDetail from "../../components/applications/applicationDetail/HumanResourceDetail";
import ProjectSummary from "../../components/applications/applicationDetail/ProjectSummary";
import ProjectDetail from "../../components/applications/applicationDetail/ProjectDetail";
import SupportingDocuments from "../../components/applications/applicationDetail/SupportingDocuments";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  createRecord,
  getRecords,
  resetData,
} from "../../redux/reducers";
import DetailPagesStepper from "../../components/form-stepper/DetailPagesStepper";
import * as Icon from "react-bootstrap-icons";
import ReactSelect from "react-select";
import Modal from "react-modal";
import "./Applications.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommentSectionContainer from "../../components/comment-section/CommentSectionContainer";
import Loader from "../../components/loader/loader";
import { canView, formatDate, getRandomColor } from "../../utils/util";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import toast from "react-hot-toast";

const ApplicationDetail = () => {
  const { id, organizationId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const isExtension = queryParams.get("isExtension") === "true";

  const [modalShow, setModalShow] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [errors, setErrors] = useState({});
  const [rejectModal, setRejectModal] = useState(false);
  const [sendFormFields, setSendFormFields] = useState({});
  const [isCommentRequired, setIsCommentRequired] = useState(false);
  const { role } = useSelector((state) => state.setting?.user);
  const [filePath, setFilePath] = useState(null);
  console.log("🚀 ~ ApplicationDetail ~ filePath:", filePath)
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(true);
  const {
    commentsData = [],
    nocApplicationDetails = [],
    organizationDetails,
    districtsData,
    users,
    transferLogs = [],
  } = useSelector((state) => state.records?.current);

  const { loading, creating, created, searchText, error, response } =
    useSelector((state) => state?.records);

  const steps = [
    {
      name: "Organization Information",
      component: <OrganizationInformationDetail data={organizationDetails} />,
    },
    {
      name: "Human Resource",
      component: <HumanResourceDetail data={organizationDetails} />,
    },
    {
      name: "Project Summary",
      component: (
        <ProjectSummary
          data={nocApplicationDetails}
          districtData={districtsData}
        />
      ),
    },
    {
      name: "Project Detail",
      component: <ProjectDetail data={nocApplicationDetails} />,
    },
    {
      name: "Supporting Documents",
      component: <SupportingDocuments data={nocApplicationDetails} />,
    },
  ];

  useEffect(() => {
    if (created && !creating && !response?.filePath) {
      setModalShow(false);
      setApproveModal(false);
      setDate({ startDate: "", endDate: "" });
      setErrors({});
      setRejectModal(false);
      setSendFormFields({});
      // fetchNocApplicationComments();
      closeCommentSection();
      // getNocApplicationsDetails();

      navigate("/applications");
    }
  }, [created, creating]);

  useEffect(() => {
    if (error?.title) {
      setApproveModal(false);
      setRejectModal(false);
      setModalShow(false);
    }
  }, [error?.title]);

  useEffect(() => {
    if (!searchText?.commentSearch) return;
    fetchNocApplicationComments();
  }, [searchText?.commentSearch]);

  useEffect(() => {
    getNocApplicationsDetails();
  }, []);

  function fetchNocApplicationComments() {
    const params = searchText?.commentSearch
      ? { searchText: searchText?.commentSearch }
      : {};
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocComment/nocApplicationId?nocApplicationId=${id}`,
        params,
        appendCurrentReducerKey: "commentsData",
      },
    });
  }

  const closeCommentSection = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/TransferLog`,
        appendCurrentReducerKey: "transferLogs",
      },
    });
  };

  const getNocApplicationsDetails = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Organization/${organizationId}/GetById`,
        // params: { isLocal: true },
        appendCurrentReducerKey: "organizationDetails",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/GetNocApplicationDetails`,
        // params: { isLocal: true },
        appendCurrentReducerKey: "nocApplicationDetails",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/District/${id}`,
        appendCurrentReducerKey: "districtsData",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/User?isPdmaUser=true`,
        appendCurrentReducerKey: "users",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/TransferLog`,
        appendCurrentReducerKey: "transferLogs",
      },
    });

    closeCommentSection();
  };

  const toggleCommentSection = () => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
    // Perform any additional actions here when toggling
  };

  const onFormValueChange = (value, key) => {
    setSendFormFields({ ...sendFormFields, [key]: value });
  };

  const getUserRoles = () => {
    if (!users?.data || !users?.data.length) return [];
    return users?.data
      .filter(
        (user) =>
          user.role !== "NGO User" &&
          user.role !== "ADC Relief" &&
          user.role !== null &&
          user.status === "Approved"
      )
      .map(({ userId, role, name }) => ({
        value: userId,
        label: `${role} (${name})`,
      }));
  };

  const onSubmitSendForm = () => {
    const { toUserId, comment } = sendFormFields;
    if (!comment) {
      setIsCommentRequired(true);
      return;
    }

    if (toUserId?.label === "Back to Applicant") {
      dispatch({
        type: createRecord.type,
        payload: {
          url: `/NocApplication/${id}/forward?toUserId=${
            toUserId?.value
          }&comment=${comment ?? ""}`,
          isFormatted: true,
          supressMessages: true,
        },
      });
      dispatch({
        type: createRecord.type,
        payload: {
          url: `/NocComment/nocApplicationId?nocApplicationId=${id}`,
          isInternalComment: false,
          comment: comment ?? "",
          isFormatted: true,
          message: "Commented Successfully!",
        },
      });
      setModalShow(false);
      getNocApplicationsDetails();
    } else {
      dispatch({
        type: createRecord.type,
        payload: {
          url: `/NocApplication/${id}/forward?toUserId=${
            toUserId?.value
          }&comment=${comment ?? ""}&file=${filePath?.filePath}`,
          isFormatted: true,
          message: "Forwarded Successfully!",
        },
      });
      getNocApplicationsDetails();
    }
  };

  const onSendMessageAction = (action) => {
    setModalShow(true);
    setSendFormFields({ ...sendFormFields, action });
  };

  function validateForm() {
    let formErrors = {};

    if (!date?.startDate) {
      formErrors.startDate = "Start Date is required";
    }
    if (!date?.endDate) {
      formErrors.endDate = "End Date is required";
    }

    return formErrors;
  }

  const handleApprove = (id, isExtension) => {
    const formErrors = validateForm();
    if (Object.keys(formErrors)?.length > 0) {
      setErrors(formErrors);
      return;
    }

    const url = isExtension
      ? `/NocApplication/${id}/Extension/approve?startDate=${date?.startDate}&endDate=${date?.endDate}`
      : `/NocApplication/${id}/approve?startDate=${date?.startDate}&endDate=${date?.endDate}`;
    dispatch({
      type: createRecord.type,
      payload: {
        url: url,
      },
    });
  };

  const handleReject = (id, isExtension) => {
    const url = isExtension
      ? `/NocApplication/${id}/Extension/reject`
      : `/NocApplication/${id}/reject`;
    dispatch({
      type: createRecord.type,
      payload: {
        url: url,
      },
    });
  };

  function handleUploadFile(e) {
    const selectedFile = e.target.files[0];

    dispatch({
      type: createRecord.type,
      payload: {
        file: { name: "file", value: selectedFile },
        url: `/FileUpload/nocApplication/forward_file`,
        headers: { "Content-Type": "multipart/form-data" },
        message: "Uploading ....",
      },
      onSuccess: (response) => {
        setFilePath(response?.data);
      },
    });
  }

  return (
    <div className="col-md-12 d-flex">
      <Loader loading={loading} />
      <div
        className={`mt-5 ${isCommentSectionOpen ? "col-md-9" : "col-md-11"}`}
      >
        <div className="col-md-12">
          <DetailPagesStepper steps={steps.map((step) => step.name)} />
        </div>
        <div className="px-4 py-2">
          <div className="col-md-12 bg-white shadow-sm mb-2 p-4 mt-3 rounded">
            <div className="content">
              {steps.map((step, index) => (
                <section
                  key={index}
                  id={`section${index + 1}`}
                  className="section"
                >
                  {step?.component}
                </section>
              ))}
            </div>
          </div>
          <div className="bg-white shadow-sm p-4 mt-3 rounded logscomments">
            <p className="heading1">Comments</p>
            <div className="comment-parent-body">
              {transferLogs
                ?.filter((i) => i?.remarks !== null)
                ?.map((log) => {
                  return (
                    <div className="rounded text-black comment-child-body">
                      <div className="d-flex flex-row ">
                        <span className="header-text">Form:</span>
                        <span className="w-100"> {log?.fromUser}</span>
                      </div>

                      <div className="d-flex flex-row ">
                        <span className="header-text ">To:</span>
                        <span className="w-100"> {log?.toUser}</span>
                      </div>

                      <div className="d-flex flex-row ">
                        <span className="header-text">Remarks:</span>
                        <span className="w-100"> {log?.remarks}</span>
                      </div>

                      <div
                        className="d-flex flex-row justify-content-end"
                        style={{ fontSize: "14px" }}
                      >
                        {formatDate(log?.date)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {nocApplicationDetails?.canComment && (
            <div className="d-flex gap-2 justify-content-end">
              <button
                onClick={() => onSendMessageAction("Backward")}
                className="btn-backward d-flex gap-1"
              >
                <ArrowLeft />
                SEND BACKWARD
              </button>

              {role == "Focal Person" && (
                <button
                  onClick={() => onSendMessageAction("Forward")}
                  className="btn-forward d-flex gap-1"
                >
                  SEND FORWARD
                  <ArrowRight />
                </button>
              )}

              {role !== "Secretary" && role !== "Focal Person" ? (
                <button
                  onClick={() => onSendMessageAction("Forward")}
                  className="btn-forward d-flex gap-1"
                >
                  SEND FORWARD
                  <ArrowRight />
                </button>
              ) : (
                <>
                  <button
                    onClick={() => setApproveModal(true)}
                    className="btn-forward d-flex px-5 gap-1"
                  >
                    APPROVE
                  </button>
                  <button
                    onClick={() => setRejectModal(true)}
                    className="btn-forward bg-danger px-5 d-flex"
                  >
                    REJECT
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* <div className={`col-md-${isCommentSectionOpen ? 3 : 0}`} style={{ transition: 'all 0.3s' }}>
        {isCommentSectionOpen && (
          <CommentSection commentOptions commentsData={commentsData} />
        )}
      </div> */}
      <CommentSectionContainer
        commentsData={commentsData}
        url="/NocComment/nocApplicationId"
        param="nocApplicationId"
        id={id}
        isCommentSectionOpen={isCommentSectionOpen}
        toggleCommentSection={toggleCommentSection}
        canComment={nocApplicationDetails?.canComment}
      />

      <Modal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        contentLabel={`Send ${sendFormFields?.action} Application`}
        overlayClassName="modal-xs"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">{`Send ${sendFormFields?.action} Application`}</p>
          <Icon.X
            size={32}
            color="black"
            onClick={() => {
              setModalShow(!modalShow);
              setIsCommentRequired(false);
            }}
          />
        </div>

        <div className="seprator"></div>

        <div className="mt-4">
          <p className="m-1">Comments</p>
          <textarea
            onChange={(e) => onFormValueChange(e.target.value, "comment")}
            className={`form-control h-25 ${
              isCommentRequired ? "border-danger" : ""
            }`}
            rows="5"
            placeholder="Enter Your Comments"
          ></textarea>
          {isCommentRequired && (
            <p className="text-danger">Comments is required</p>
          )}
        </div>
        <div className="mt-2">
          <p className="m-1">Minutes of Meeting (Attach File)</p>
          <div className="bg-light px-3 py-2 rounded d-flex align-items-center justify-content-between">
            <input
              id="meeting-minutes"
              className="visually-hidden"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv"
              onChange={(e) => handleUploadFile(e)}
            />
            <p className="m-0">{filePath?.fileName ?? "No file selected"}</p>
            <label for={"meeting-minutes"} className="upload-button">
              <Icon.BoxArrowUp size={20} color="black" /> Upload
            </label>
          </div>
        </div>
        <div className="mt-3">
          <ReactSelect
            className="dropdown-input"
            options={[
              ...getUserRoles(),
              {
                label: "Back to Applicant",
                value: nocApplicationDetails?.createdBy,
              },
            ]}
            placeholder="Select Recipient"
            onChange={(e) =>
              onFormValueChange({ label: e.label, value: e.value }, "toUserId")
            }
          />
        </div>
        <div className="d-flex justify-content-end">
          <button
            onClick={() => {
              onSubmitSendForm();
            }}
            className="btn-forward"
            disabled={creating}
          >
            {creating || loading ? (
              <div class="spinner-border text-white" role="status"></div>
            ) : (
              "FORWARD"
            )}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={approveModal}
        onRequestClose={() => setApproveModal(false)}
        contentLabel={`Approve Application`}
        overlayClassName="modal-xs"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">{`Approve Application`}</p>
          <Icon.X
            size={32}
            color="black"
            onClick={() => {
              setApproveModal(!approveModal);
              setErrors({});
              setDate({ startDate: "", endDate: "" });
            }}
          />
        </div>

        <div className="seprator"></div>

        <div className="mt-4">
          <p className="m-1">Start Date</p>
          <input
            type="date"
            name="startDate"
            className="form-control"
            value={date?.startDate}
            onChange={(e) => setDate({ ...date, startDate: e.target.value })}
          />
          {errors && <p className="text-danger">{errors?.startDate}</p>}
        </div>
        <div className="mt-4">
          <p className="m-1">End Date</p>
          <input
            name="endDate"
            value={date?.endDate}
            className="form-control"
            type="date"
            onChange={(e) => setDate({ ...date, endDate: e.target.value })}
          />
          {errors && <p className="text-danger">{errors?.endDate}</p>}
        </div>

        <div className="d-flex justify-content-end mt-5">
          <button
            onClick={() => handleApprove(id, isExtension)}
            className="btn-forward"
            disabled={creating}
          >
            {creating || loading ? (
              <div class="spinner-border text-white" role="status"></div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={rejectModal}
        onRequestClose={() => setRejectModal(false)}
        contentLabel={`Reject Application`}
        overlayClassName="modal-xs"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">{`Reject Application`}</p>
          <Icon.X
            size={32}
            color="black"
            onClick={() => {
              setRejectModal(!rejectModal);
            }}
          />
        </div>

        <div className="seprator"></div>
        <p className="mt-5 mb-1 heading1">Are You Sure Want to Reject?</p>
        <div className="d-flex justify-content-end mt-5 gap-3">
          <button
            onClick={() => handleReject(id, isExtension)}
            className="btn-forward  px-5"
            disabled={creating}
          >
            {creating || loading ? (
              <div class="spinner-border text-white" role="status"></div>
            ) : (
              "Reject"
            )}
          </button>
          <button
            onClick={() => setRejectModal(!rejectModal)}
            className="btn-forward bg-danger px-5"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ApplicationDetail;
