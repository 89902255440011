import React, { useEffect, useState } from "react";
import "./MonthlyReportDetail.scss";
import WithPageHeader from "../../components/ui/withPageHeader";
import SubmitIcon from "../../assets/icons/SubmitIcon";
import FilterIcon from "../../assets/images/Filter.svg";
import { useNavigate, useParams } from "react-router-dom";
import CommentSection from "../../components/comment-section/CommentSection";
import { Button } from "react-bootstrap";
import MonthlyTableDetail from "../../components/monthly-report/MonthlyTableDetail";
import CommentSectionContainer from "../../components/comment-section/CommentSectionContainer";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, getRecords } from "../../redux/reducers";
import { canView, formatDate } from "../../utils/util";
import { getStatusBadge, handleDownloadFile } from "../../Utility";
import Loader from "../../components/loader/loader";
import { Download } from "react-bootstrap-icons";

const MonthlyReportDetail = () => {
  const navigate = useNavigate();
  const { id, nocId } = useParams();
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(true);
  const {creating,loading,created } = useSelector((state) => state.records);
  const { role } = useSelector((state) => state.setting?.user);
  const dispatch = useDispatch();
  const {
    progressReportComments = [],
    progressReport = {},
    nocApplicationDetails = {},
    monthlyReportWorkPlan = [],
  } = useSelector((state) => state.records.current);

  useEffect(() => {
    initialFetchData();
  }, []);

  function initialFetchData() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ProgressReportComment/progressReportId?progressReportId=${id}`,
        appendCurrentReducerKey: "progressReportComments",
      },
    });

    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/ProgressReport/${id}/GetProgressReportDetailById?id=${id}`,
          appendCurrentReducerKey: "progressReport",
        },
      });
    }, 100);

    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/NocApplication/${nocId}/GetNocApplicationDetails`,
          appendCurrentReducerKey: "nocApplicationDetails",
        },
      });
    }, 200);

    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/NocApplication/${nocId}/GetNocWorkPlan`,
          appendCurrentReducerKey: "monthlyReportWorkPlan",
        },
      });
    }, 300);
  }

  const toggleCommentSection = () => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
    // Perform any additional actions here when toggling
  };

  const handleApprove = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/ProgressReport/${id}/approve`,
      },
    });
  };

  const handleReject = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/ProgressReport/${id}/reject`,
      },
    });

  };

  if(created){
    navigate("/monthly-report");
  }

  return (
    <div className="d-flex flex-row justify-content-between">
    <Loader loading={loading} />
      <div
        className={`mt-3 ${isCommentSectionOpen ? "col-md-9" : "col-md-11"}`}
      >
        <div className="col-md-12 px-4 py-5 rounded">
          <div className="top-header-detail-container">
            <WithPageHeader
              heading="Progress Report Details"
              text="Review the detailed monthly progress update"
              buttons={[]}
            />
          </div>
          <div className=" bg-white shadow-sm  p-4 ">
            <div className="monthly-report-detail">
              <div className="body">
                <div className="project-detail ">
                  <div className="project-detail-heading mb-2">
                    Progress Information
                  </div>
                  <div className="project-detail-data ">
                    <div className="d-flex flex-row gap-3  align-items-center p-2">
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                          Organization Name:
                        </span>
                        <span className="text-data col-md-7">
                          {progressReport?.organizationName}
                        </span>
                      </div>
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                          Donor Agency:
                        </span>
                        <span className="text-data col-md-7">
                          {progressReport?.donorAgency}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-row gap-3  align-items-center p-2">
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                          Target Area:
                        </span>
                        <span className="text-data col-md-7">
                          {progressReport?.districtName}
                        </span>
                      </div>
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                          Implementing Parthner's:
                        </span>
                        <span className="text-data col-md-7">
                          {progressReport?.implementingPartner}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-3  align-items-center p-2">
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                          Start Date:
                        </span>
                        <span className="text-data col-md-7">
                          {formatDate(nocApplicationDetails?.projectStartDate)}
                        </span>
                      </div>
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">End Date:</span>
                        <span className="text-data col-md-7">
                          {formatDate(nocApplicationDetails?.projectEndDate)}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-row gap-3  align-items-center p-2">
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                          Progress Status:
                        </span>
                        <span className="text-data col-md-7">
                          {getStatusBadge(progressReport?.progressReportStatus)}
                        </span>
                      </div>
                      <div className="d-flex flex-row gap-2 col-md-6 ">
                        <span className="bold-header col-md-5">
                        Verified ReportDoc:
                        </span>
                        <span className="text-data col-md-7">
                        
                        {/* TODO The Path of file provided is not correct */}
                          <button className="download-btn" onClick={(e) => handleDownloadFile(e,progressReport?.verifiedReportDoc)} > <Download size={20} color="white" /> {progressReport?.verifiedReportDoc?.replace(/^.*[\\\/]/, "")}</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="monthly-report-detail mt-4 ">
              <div className="body">
                <div className="project-detail ">
                  <div className="project-detail-heading">
                    Progress Information
                  </div>
                  <MonthlyTableDetail
                    data={progressReport?.detailedResponse}
                    fetchData={monthlyReportWorkPlan}
                  />
                </div>
              </div>
            </div>
          </div>
          <div></div>

          {canView(["ADC Relief"], role) && (
            <div className="d-flex flex-row justify-content-end gap-4 fw-bolder">
              {/* TODO Implement below given APIS  methods*/}

              {/* <button className="btn-backward px-5 py-3">Send Back</button> */}
              <button
                className="btn-backward px-5"
                onClick={() => handleReject(id)}
              >
                Reject
              </button>
              <button
                className="btn-forward px-5"
                onClick={() => handleApprove(id)}
              >
                Approve
              </button>
            </div>
          )}
        </div>
      </div>

      {/* <CommentSection /> */}
      <CommentSectionContainer
        commentsData={progressReportComments}
        commentOptions={false}
        url="/ProgressReportComment/progressReportId"
        param="progressReportId"
        id={id}
        isCommentSectionOpen={isCommentSectionOpen}
        toggleCommentSection={toggleCommentSection}
      />
    </div>
  );
};

export default MonthlyReportDetail;
