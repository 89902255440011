import React, { useEffect, useState } from "react";

import Table from "../../components/table/Table";
import { NocColumns } from "../../assets/columns/NOCColumns";
import "./Noc.scss";
import WithPageHeader from "../../components/ui/withPageHeader";
import { customStyles } from "../../assets/styles/CustomStyle";
import { getRecords } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";

const Noc = () => {
  const dispatch = useDispatch();
  const { loading, current, searchText } = useSelector(
    (state) => state?.records
  );
  const nocs = current?.nocs;
  const { lookups = [] } = useSelector((state) => state?.form);
  const [filterData, setFilterData] = useState(nocs?.data);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [perPage, setPerPage] = useState(nocs?.pageSize ?? 10);
  const [page, setPage] = useState(nocs?.pageNumber ?? 1);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchText?.generalSearch === "") {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/NocApplication/GetAllNOCProject`,
          appendCurrentReducerKey: "nocs",
        },
      });
      fetchDataFromSearch();
    }
  }, [searchText?.generalSearch]);

  useEffect(() => {
    setTimeout(() => {
      getNocData();
    }, 200);
  }, []);

  useEffect(() => {
    setFilterData(nocs?.data);
    setSelectedStatus("All");
  }, [nocs?.data]);

  useEffect(() => {
    handlePaginationPerpage();
  }, [perPage, page]);

  const fetchDataFromSearch = () => {
    const params = {
      pageNumber: page,
      pageSize: perPage,
    };
    if (searchText?.generalSearch) {
      params.search = searchText.generalSearch;
    }

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/GetAllNOCProject`,
        params,
        appendCurrentReducerKey: "nocs",
      },
    });
  };

  const getNocData = () => {
    dispatch({
      type: "form/fetchLookup",
      payload: { key: "nocStatus", url: "/Lookup/ProjectStatus" },
    });
  };

  const filterByStatus = (type) => {
    setSelectedStatus(type);
    if (type === "All") {
      setFilterData(nocs?.data);
    } else {
      const filtered = nocs?.data?.filter(
        (record) => record?.projectStatus === type
      );
      setFilterData(filtered);
    }
  };

  const getStatusClass = (type) => {
    return selectedStatus === type ? "active" : "";
  };

  const statusList = (lookups?.nocStatus ?? []).map((item, key) => {
    return {
      text: item?.description,
      className: `${getStatusClass(item?.description)}`,
      onClick: () => filterByStatus(item?.description),
    };
  });

  const All = {
    text: "All",
    className: `${getStatusClass("All")}`,
    onClick: () => filterByStatus("All"),
  };

  const columns = [...NocColumns];

  const handlePaginationPerpage = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/GetAllNOCProject?pageNumber=${page}&pageSize=${perPage}`,
        appendCurrentReducerKey: "nocs",
      },
    });
  };
  return (
    <div className="px-4 py-2 mt-5">
      <Loader loading={loading} />
      <WithPageHeader
        heading="NOC Management"
        text="Review and manage Non-Objection Certificate (NOC) applications"
        buttons={[]}
      />

      <div className="bg-white shadow-sm rounded p-3 table-container-anim">
        <WithPageHeader
          heading="Issued NOC List"
          buttons={[All, ...statusList]}
        />
        <div className="mt-3">
          <Table
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            pagination
            paginationServer
            paginationTotalRows={nocs?.totalRecords}
            onChangeRowsPerPage={(pP) => {
              setPerPage(pP);
            }}
            onChangePage={(p) => {
              setPage(p);
            }}
            dense
          />
        </div>
      </div>
    </div>
  );
};

export default Noc;
