import React, { Children } from "react";
import Modal from "react-modal";
import * as Icon from "react-bootstrap-icons";
import "./ApplicationConfirmModal.scss";

const ApplicationConfirmModal = ({
  modalShow,
  setModalShow,
  title,
  children,
}) => {
  return (
    <div className="conf-mod">
      <Modal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        contentLabel="Forward Application"
        overlayClassName="modal-md"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">{title}</p>
          <Icon.X
            size={32}
            color="black"
            onClick={() => setModalShow(!modalShow)}
          />
        </div>

        <div className="seprator"></div>

        <>{children}</>
      </Modal>
    </div>
  );
};

export default ApplicationConfirmModal;
