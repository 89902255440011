import React from "react";
import Form from "../../form/Form";
import { applicationFields } from "../../../assets/fields/applicationFields";
import { getFieldsByCategory } from "../../../utils/util";
import { useSelector } from "react-redux";

const ProjectDetailForm = () => {
  const {validations}= useSelector(state => state.form);
  return (
    <div className="col-md-12 bg-white shadow-sm mb-2 p-4 rounded">
      <Form
        formData={getFieldsByCategory(applicationFields, "projectDetail")}
        withValidation={true}
        formValidation={validations}
      />
    </div>
  );
};

export default ProjectDetailForm;
