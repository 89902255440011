import { Link } from "react-router-dom";
import { getFormatedDate, getStatusText } from "../../Utility";
import Countdown from "../../components/applications/CountDownTimer";
import { formatDate } from "../../utils/util";

export const applicationsColumns = [
  // {
  //   name: "ID",
  //   selector: (row, index) => index + 1,
  //   maxWidth: "80px",
  // },
  {
    name: "Project Title",
    selector: (row) => (
      <Link
        to={`/applications/detail/${row?.id}/${row?.organizationId}?isExtension=${row?.isExtension}`}
      >
        {row?.projectTitle}
      </Link>
    ),
  },
  {
    name: "Organization",
    selector: (row) => row?.organizationName,
  },
  {
    name: "Assigned To",
    selector: (row) => row?.assignedToUser?.name,
  },
  {
    name: "Status",
    selector: (row) => getStatusText(row?.applicationStatus),
  },
  // {
  //   name: "Count Down",
  //   selector: (row) => (
  //     <>
  //       {row?.applicationStatus == "Initial Submission" ? (
  //         <Countdown
  //           COUNTDOWN_TARGET={new Date(row?.lastActionDate?.split(".")[0])}
  //         />
  //       ) : (
  //         <>{formatDate(row?.lastActionDate)}</>
  //       )}
  //     </>
  //   ),
  //   maxWidth: "280px",
  // },
  {
    name: "Type",
    selector: (row) => {
      if (row?.extensionCount > 0) {
        return "Extension : " + row?.extensionCount;
      }

      return "Fresh";
    },
    maxWidth: "160px",
  },
  {
    name: "Last Action Date",
    // selector: (row) => row.lastActionDate,
    selector: (row) => getFormatedDate(row.lastActionDate),
    width: "160px",
  },
];
