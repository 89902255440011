import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "./Sidebar.scss";
import MobileLogo from "../../assets/mobileLogo.png";
import { NavLink, Link, useLocation } from "react-router-dom";
import { canView, getRoleAsArray } from "../../utils/util";
import { useSelector } from "react-redux";
import {
  FaList,
  FaUsers,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { TbLayoutDashboard } from "react-icons/tb";
import { MdOutlineFeedback, MdOutlineLibraryAdd } from "react-icons/md";
import { LOGO } from "../../assets/GlobalMediaAssets";
import packageJson from "../../../package.json";
import Feedback from "react-bootstrap/esm/Feedback";

const Sidebar = () => {
  const location = useLocation();
  const { version } = packageJson;

  const { role } = useSelector((state) => state.setting?.user);
  const userRoles = role && getRoleAsArray(role);
  const typeOfChild = {
    heading: "heading",
    line: "line",
    tab: "tab",
  };

  const [selectedTab, setSelectedTab] = useState(1);
  const [sidebarLinks, setSidebarLinks] = useState([]);
  const [toggle, setToggler] = useState(true);

  const toggleSidebar = () => {
    setToggler(!toggle);
  };

  useEffect(() => {
    getSidebarLinks();
  }, [role]);

  useEffect(() => {
    const currentPath = location.pathname;

    const selectedLink = sidebarLinks.find((link) =>
      currentPath.includes(link.route)
    );

    if (selectedLink) {
      setSelectedTab(selectedLink.id);
    }
  }, [location.pathname, sidebarLinks]);

  const sidebarData = [
    {
      id: 0,
      label: "Analytics",
      type: typeOfChild.heading,
    },
    {
      id: 1,
      name: "dashboard",
      route: "dashboard",
      icon: <TbLayoutDashboard fill="none" />,
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <TbLayoutDashboard className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Dashboard</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <TbLayoutDashboard className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 2,
      name: "reports",
      route: "reports",
      icon: <TbLayoutDashboard fill="none" />,
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <Icon.Clipboard2Data className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Reports</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <TbLayoutDashboard className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 3,
      name: "line",
      type: typeOfChild.line,
    },
    {
      id: 4,
      label: "Data & Monitoring",
      type: typeOfChild.heading,
    },
    {
      id: 5,
      name: "applications",
      route: "applications",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <MdOutlineLibraryAdd className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Applications</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <MdOutlineLibraryAdd className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 6,
      name: "nocs",
      route: "nocs",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <Icon.Files className="sidebar-icon" />
          </div>
          <div className="sidebar-text">NOCs</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <Icon.Files className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 7,
      name: "extension",
      route: "extension",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <Icon.FileEarmarkPlus className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Extension</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <Icon.FileEarmarkPlus className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 9,
      name: "monthly-report",
      route: "monthly-report",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <Icon.Clipboard2Data className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Monthly Reports</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <Icon.Clipboard2Data className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 10,
      name: "notifications",
      route: "notifications",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <Icon.Bell className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Notifications</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <Icon.Bell className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 15,
      name: "grievence",
      route: "grievence",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <MdOutlineFeedback className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Grievence Desk</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <Icon.Bell className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 11,
      name: "line",
      type: typeOfChild.line,
    },
    {
      id: 12,
      label: "ADMIN",
      type: typeOfChild.heading,
    },
    {
      id: 13,
      name: "user",
      title: "User Management",
      route: "/user-management",
      icon: <FaUsers />,
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <FaUsers className="sidebar-icon" />
          </div>
          <div className="sidebar-text">
            <>Users &amp; Organization</>
          </div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaUsers className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 14,
      name: "definition",
      route: "/definitions/cluster",
      type: typeOfChild.tab,
      path: (
        <>
          <div className="icon-bg">
            <FaList className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Definitions</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaList className="sidebar-icon" />
          </div>
        </>
      ),
    },
  ];

  const getSidebarLinks = () => {
    if (
      !canView(
        ["Secretary", "SuperAdmin", "NOC Section", "Focal Person"],
        userRoles
      )
    ) {
      const data = sidebarData.filter(
        ({ name, label }) =>
          name == "line" ||
          name == "applications" ||
          name == "nocs" ||
          name == "monthly-report" ||
          name == "extension" ||
          name == "notifications" ||
          name == "grievence"
      );
      setSidebarLinks(data);
    } else if (canView(["Secretary", "Focal Person"], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) =>
          name == "dashboard" ||
          name == "applications" ||
          name == "nocs" ||
          name == "notifications"
      );
      setSidebarLinks(data);
    } else if (canView(["NOC Section"], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) =>
          name == "applications" ||
          name == "notifications" ||
          name == "nocs" ||
          name == "user"
      );
      setSidebarLinks(data);
    } else {
      setSidebarLinks(sidebarData);
    }
  };

  return (
    <>
      <div
        className="wrap h-100"
        style={{ width: toggle ? "" : "5rem", borderWidth: 1 }}
      >
        {toggle && (
          <a
            href="/"
            className="logo"
            style={{ justifyContent: "start", marginLeft: "20px" }}
          >
            <img src={LOGO} alt="logo" />
            <p>NOC</p>
          </a>
        )}
        {!toggle && (
          <a href="/" className="logo">
            <img src={LOGO} alt="logo" height={50} />
          </a>
        )}

        <div className="toggle-btn-new" onClick={() => toggleSidebar()}>
          <div className="inside-btn-toggle">
            {toggle ? (
              <FaAngleDoubleLeft color="#075f33" />
            ) : (
              <FaAngleDoubleRight color="#075f33" />
            )}
          </div>
        </div>

        <div className="sidebar">
          {sidebarLinks.length > 0 &&
            sidebarLinks.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  {i.type === typeOfChild.heading && toggle && (
                    <div className="sidebar-heading">{i.label}</div>
                  )}
                  {i.type === typeOfChild.tab && (
                    <NavLink to={i.route}>
                      <div
                        className={`sidebar-nav ${
                          i.id === selectedTab ? "active-sidebar-link" : ""
                        }`}
                        key={i.id}
                        onClick={() => {
                          setSelectedTab(i.id);
                        }}
                      >
                        {toggle ? i.path : i.passivePath}
                      </div>
                    </NavLink>
                  )}
                  {i.type === typeOfChild.line && toggle && (
                    <hr className="line" />
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <div className="mt-2">
        {toggle && (
          <>
            <hr />
            <small className="text-dark p-2 d-block text-center copyright text-regular text-muted">
              Powered by Centangle Interactive
              <br />
              Version: {version}
            </small>
            <hr />
          </>
        )}
      </div>
    </>
  );
};

export default Sidebar;
