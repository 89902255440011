import React from "react";
import * as Icon from "react-bootstrap-icons";

const Tabs = ({ TABS, tabs, setTabs }) => {
  return (
    <div className="mt-3 d-flex gap-5 mx-2">
      <div className="cursor-pointer" onClick={() => setTabs(TABS.USERS)}>
        <p
          className={`heading2 ${
            tabs === TABS.USERS ? "text-success" : "text-muted"
          }`}
        >
          <span className="mx-2">
            <Icon.People />
          </span>
          User Management
        </p>
        {tabs === TABS.USERS && (
          <div style={{ height: "3px" }} className="rounded bg-success"></div>
        )}
      </div>
      <div
        className="cursor-pointer"
        onClick={() => setTabs(TABS.ORGANIZATIONS)}
      >
        <p
          className={`heading2 ${
            tabs === TABS.ORGANIZATIONS ? "text-success" : "text-muted"
          }`}
        >
          <span className="mx-2">
            <Icon.Building />
          </span>
          Organization Management
        </p>
        {tabs === TABS.ORGANIZATIONS && (
          <div style={{ height: "3px" }} className="rounded bg-success"></div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
