import { put } from "redux-saga/effects";

import { saveUserState } from "../../utils/localStorage";
import {
  onRecordSuccess,
  resetData,
  setUser,
  setUserMessages,
  setValidations,
} from "../reducers";
import { getError, displayFormat } from "../../utils/util";
import { resetFlags, setErrors } from "../reducers/recordSlice";
import toast from "react-hot-toast";

export function* branchedActionAsync({ payload = {} }) {
  const notify = ({
    type = "success",
    message = "Successfull!",
    position = "top-center",
  } = {}) => {
    toast[type](message, {
      position: position,
    });
  };

  const {
    response = {},
    updated = false,
    created = false,
    code = "",
    message = "",
  } = payload;
  const isLoginApi = payload.payload?.url === "/Auth/SignIn";
  if ((updated || created) && !isLoginApi) {
    yield put({ type: onRecordSuccess.type });
    notify({ message });
  }
  if (isLoginApi) {
    let apiResponse = { ...response };
    saveUserState(apiResponse);
    yield put({ type: setUser.type, payload: apiResponse });
    yield put({ type: "records/setRecords", payload: { created: true } });
  }

  if (payload?.url === "/Role") {
    let fetchUserInfo = localStorage.getItem("state");
    let userInfo = JSON.parse(fetchUserInfo);

    let combine = {
      ...userInfo,
      roles: payload?.data,
    };

    saveUserState(combine);
  }

  if (payload?.url === "/Auth") {
    let fetchUserInfo = localStorage.getItem("state");
    let userInfo = JSON.parse(fetchUserInfo);

    let combine = {
      ...userInfo,
      ...payload,
    };

    saveUserState(combine);
  }

  if (code === "ERR_BAD_REQUEST" || code === "TypeError") {
    let errorMessage = message
      ? message
      : "Something went wrong, please try again";
    if (response?.data?.status > 204 && response?.data?.errors) {
      errorMessage = getError(response?.data?.errors);
    }
    if (response?.status === 401) {
      errorMessage = "Session was expired, please login again";
      saveUserState({});
      window.location = "/login";
    }

    if (!response?.data?.errors) {
      errorMessage =
        response?.data?.title ??
        `⁠ Something went wrong with login, please try again! ⁠`;
    }
    notify({
      message: errorMessage,
      type: "error",
    });
    // toast.error(errorMessage, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  }
}

export function* formatRecordsAsync({ payload = {} }) {
  let newData = {};
  Object.entries(payload).forEach(([key, item]) => {
    //for dropdowns
    if (Array.isArray(item)) {
      newData = {
        ...newData,
        [key]: {
          name: key,
          value: item.map((innerItem) => {
            if (innerItem?.id && innerItem?.name)
              return { value: innerItem?.id, label: innerItem?.name };
            else return { ...innerItem };
          }),
        },
      };
    } else newData = { ...newData, [key]: { name: key, value: item } };
  });
  yield put({ type: "form/setFormValues", payload: newData });
}

export function* setErrorsAsync(action) {
  console.log("🚀 ~ function*setErrorsAsync ~ action:", action);
  yield put({
    type: resetFlags.type,
    payload: {},
  });

  const status = action?.payload?.status;
  if (status >= 400 && status <= 403) {
    const validation = action.payload.response?.errors;
    yield put({
      type: setValidations.type,
      payload: validation,
    });
    const details = yield displayFormat(validation);
    yield put({
      type: setUserMessages.type,
      payload: { className: "orange", message: "Validation Error!", details },
    });
  } else if (status != 422 && status >= 400) {
    yield put({
      type: setErrors.type,
      payload: action?.payload?.response,
    });
    yield put({
      type: setUserMessages.type,
      payload: {
        className: "red",
        message: action.payload.response
          ? action.payload.response?.title
          : action.payload.message
          ? action.payload.message
          : `⁠General Error! status: ${status}`,
      },
    });
  } else if (status === undefined) {
    const details = action.payload.response;
    yield put({
      type: setUserMessages.type,
      payload: {
        className: "red",
        message: action.payload.message
          ? action.payload.message
          : `⁠ General Error! ⁠`,
        details,
      },
    });
  }
}
