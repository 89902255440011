import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import add_btn from "../../assets/images/plus.svg";
import "./UserManagement.scss";
import WithPageHeader from "../../components/ui/withPageHeader";
import UserTabForManagement from "./UserTabForManagement";
import OrganizationTabForManagment from "./OrganizationTabForManagment";
import Tabs from "./Tabs";

const TABS = {
  USERS: "Users",
  ORGANIZATIONS: "Organizations",
};

const UserManagements = () => {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(TABS.USERS);

  return (
    <div className="px-4 py-2 mt-5">
      <WithPageHeader
        heading="Users & Organizations"
        text=""
        buttons={[
          {
            icon: <img src={add_btn} alt="add_user_button" />,
            text: "Add User",
            onClick: () => navigate("/user-management/add"),
            className: "regularButton",
            style: { gap: "8px" },
          },
        ]}
      />

      <Tabs TABS={TABS} tabs={tabs} setTabs={setTabs} />

      <div className="mt-3">
        {tabs === TABS.USERS && <UserTabForManagement />}
        {tabs === TABS.ORGANIZATIONS && <OrganizationTabForManagment />}
      </div>
    </div>
  );
};

export default UserManagements;
