import { Link } from "react-router-dom";
import { FaCircle } from "react-icons/fa";

import "../../assets/styles/global.scss";
import { ThreeDots } from "react-bootstrap-icons";
import { getStatusBadge } from "../../Utility";

export const MonthlyReportColumns = [
  {
    name: "Project Title",
    selector: (row) => (
      <div className="">
        <Link to={`detail/${row?.progressReportId}/${row?.nocApplicationId}`}>
          {row?.nocApplicationName}
        </Link>
     

      </div>
    ),
  },
  {
    name: "Organization",
    selector: (row) => row?.organizationName,
  },
  {
    name: "Report Month",
    selector: (row) => row?.reportMonth,
  },
  {
    name: "Status",
    selector: (row) => getStatusBadge(row?.progressReportStatus),
    
  },
];
