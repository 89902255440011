import React, { useEffect } from "react";
import WithPageHeader from "../../components/ui/withPageHeader";
import ProgressInformationForm from "../../components/monthly-report/ProgressInformationForm";
import ProgressDetailTableInput from "../../components/monthly-report/ProgressDetailTableInput";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, createRecord, resetData } from "../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import { validateForm } from "../../components/form/form.helper";
import { montlyReportFields } from "../../assets/fields/monthlyReportFields";
import Loader from "../../components/loader/loader";

const AddUpdateMonthlyReport = () => {
  const { id } = useParams();
  let canUpdate = false;
  const navigate = useNavigate();
  if (id) canUpdate = true;
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.form.values);
  const { creating, loading, created } = useSelector((state) => state.records);
  useEffect(() => {
    if (created) {
      navigate("/monthly-report");
    }
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [created]);
  const handleSubmit = () => {
    const isValid = validateForm(montlyReportFields, formValues, dispatch);
    if (!isValid) return;
    const formatePayload = {
      nocApplicationId: formValues.nocApplicationId?.value?.value,
      donorAgency: formValues.donorAgency?.value,
      targetDistrictId: formValues.targetDistrictId?.value?.value,
      implementingPartner: formValues.implementingPartner?.value,
      reportMonth: formValues.reportMonth?.value?.label,
      verifiedReportDoc: formValues.verifiedReportDoc,
      vettedBy: formValues.vettedBy?.value,
      progressReportStatus: "Under Process",
      reportDetails: formValues.reportDetails?.value?.map((item) => {
        return {
          ...item,
          workTypeId: item.workTypeId?.value?.value,
          measurementUnit: item.measurementUnit?.value,
          plannedTarget: parseInt(item.plannedTarget?.value),
          achievedAmount: parseInt(item.achievedAmount?.value),
          percentageAchieved: parseInt(item.percentageAchieved?.value),
          progressStatus: item.progressStatus?.value?.label,
          reasonForDelay: item.reasonForDelay?.value,
          isWorkSubActivity: true,
          // nocWorkPlanList.find(
          //   (item) => item.id === item?.workTypeId?.value?.value
          // )?.isSubActivity,
        };
      }),
    };
    dispatch({
      type: createRecord.type,
      payload: {
        ...formatePayload,
        url: "/ProgressReport",
        isFormatted: true,
      },
    });
  };
  return (
    <div className="px-4 py-2 mt-5">
      <Loader loading={loading} />
      <WithPageHeader heading="Progress Report" buttons={[]} />
      <div className=" mt-3">
        <div className="col-md-12 shadow-sm bg-white mb-2 py-4 px-4 rounded">
          <ProgressInformationForm projectId={id} />
        </div>
        <ProgressDetailTableInput projectId={id} />
      </div>
      <div style={{ gap: "32px" }} className="d-flex justify-content-end">
        <button className="btn-backward">Go Back</button>
        <button
          onClick={handleSubmit}
          disabled={creating}
          className="btn-forward"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddUpdateMonthlyReport;
