import React, { useEffect } from "react";
import {
  clearForm,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
  setPageMeta,
} from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Form from "./../../components/form/Form";
import { userAccountFields } from "../../assets/fields/userAccountFields";
import Loader from "../../components/loader/loader";

const UserUpdatePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.records);

  const handleSubmit = (values) => {
    dispatch({
      type: putRecord.type,
      payload: {
        url: "/UserManagement",
        id,
        ...values,
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  };

  useEffect(() => {
    dispatch({ type: setPageMeta.type, payload: { title: "Account Update" } });
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/UserManagement/${id}` },
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  return (
    <>
      <div className="mt-5 px-5" style={{ minHeight: "100vh" }}>
        <Loader loading={loading} />
        <Form
          formData={userAccountFields}
          onSubmit={(params) => handleSubmit(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </div>
    </>
  );
};

export default UserUpdatePage;
