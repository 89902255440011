import * as Icon from "react-bootstrap-icons";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getRecords } from "../../redux/reducers";

const SearchList = ({ url, params, searchFieldName = "Name", placeholder = "Search" }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleSearch = (value) => {
    if (value === null) return
    dispatch({
      type: getRecords.type,
      payload: {
        url,
        params: { [searchFieldName]: value, ...params }
      }
    });
  };

  return (
    <div className="left d-flex align-items-center mr-2 w-md-50 py-1 px-2 border  rounded bg-white">
      <span className="mr-2 ml-2 text-primary">
        <Icon.Search color="#000" />
      </span>
      <input
        type="text"
        className="t-input w-100 text-primary"
        placeholder={placeholder}
        onChange={(event) => setQuery(event?.target?.value)}
      />
    </div>
  );
};

export default SearchList;
