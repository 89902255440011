import { createSlice } from "@reduxjs/toolkit";
import { leftMenu } from "../../assets/default-menu";
const initialState = { leftMenu, selectedField: "Cluster", searchFields: [] };
const fieldsSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    setFormFields(state, action) {
      return {
        ...state,
        searchFields: action.payload.filter((i) => i.inSearch),
        formFields: action.payload.filter((i) => i.inForm),
        approveFields: action.payload.filter((i) => i.inApprove),
        resultFields: action.payload.filter((i) => i.inResult),
      };
    },
    setErrors(state, action) {
      return { ...state, error: action.payload };
    },
  },
});

export const { setFormFields, setErrors } = fieldsSlice.actions;
export default fieldsSlice.reducer;
