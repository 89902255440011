import React from "react";
import "./NocProjectDetails.scss";

const NocProjectDetails = ({projectDetails={}}) => {
  return (
    <div>
      <div className="header-project-details">
        <p className="header-text">Project Details</p>
      </div>
      <div className="col-md-12 bg-white shadow-sm mb-2 p-4 rounded">
        <p className="label-heading">Project Details</p>
      

        <div className="detail-container">
          <p className="body-heading mb-2">Project Background:</p>
          <p className="body-text">
           {projectDetails?.projectBackground}
          </p>

          <p className="body-heading mb-2">Project Rational:</p>
          <p className="body-text">
         {projectDetails?.projectRational}
          </p>

          <p className="body-heading mb-2">Implementation Strategy:</p>
          <p className="body-text">
          {projectDetails?.implementationStratergy}
          </p>

          <p className="body-heading mb-2">Project Goal:</p>
          <p className="body-text">
            {projectDetails?.projectGoal}
          </p>

          <p className="body-heading mb-2">Outcomes & Output:</p>
          <p className="body-text">
            {projectDetails?.outcome}
          </p>

          <p className="body-heading mb-2">Coordination Mechanism:</p>
          <p className="body-text">
           {projectDetails?.cordinationMechanism}
          </p>

          <p className="body-heading mb-2">Monitoring Strategy:</p>
          <p className="body-text">
           {projectDetails?.monitoringStratergy}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NocProjectDetails;
