import Beneficiaries from "./assets/icons/Beneficiaries";
import CertificateIcon from "./assets/icons/CertificateIcon";
import CheckFileIcon from "./assets/icons/CheckFileIcon";
import FundDetailIcon from "./assets/icons/FundDetailIcon";
import SupportingIcons from "./assets/icons/SupportingIcons";
import WorkPlanIcon from "./assets/icons/WorkPlanIcon";

export const COMPLAINT_STATUS = {
  PENDING: "pending",
  CLOSED: "closed",
  APPROVE: "approve",
  TRUE: "true",
  FALSE: "false",
};

export const INTERVENTION_LOGIC_TYPE = {
  SPECIFIC_OBJECTIVE: "SpecificObjective",
  EXPECTED_RESULT: "ExpectedResult",
};

export const ACTIVITY_STATUS_TYPE = {
  IN_Progress: "InProgress",
  PENDING: "Pending",
  COMPLETED: "Completed",
};

export const ORDER_DIRECTION = {
  ASC: "Asc",
  DESC: "Desc",
};

export const COMPLAINT_STATUS_TYPE = {
  PENDING: "Pending",
  CLOSED: "Closed",
};

export const COMPLAINT_LEVEL_SATISFACTION = {
  SATISFIED: "Satisfied",
  NEUTRAL: "Neutral",
  DISSATISFIED: "Dissatisfied",
};

export const MODE_OF_RESPONSE = {
  TELEPHONICALLY: "Telephonically",
  MEETING: "Meeting",
  OTHERS: "Others",
};

export const USER_ROLE = {
  ADMIN: "SuperAdmin",
  USER: "USER",
};

export const PROJECT_STATUS = {
  UNAPPROVED: "UnApproved",
  APPROVED: "Approved",
  ARCHIVED: "Archived",
};

export const APPLICATIONS_STATUS = {
  ONGOING: "Ongoing",
  ACTIVE: "Active",
  APPROVED: "Approved",
  ADC_APPROVED: "ADC Approved",
  BLOCKED: "Blocked",
  PENDING: "Pending",
  REJECTED: "Rejected",
  UNDER_REVIEW: "Under Review",
  UNDER_PROCESS: "Under Process",
  REVISION_REQUIRED: "Revision Required",
};

export const NOC_Tabs = [
  {
    icon: CheckFileIcon,
    pathName: "project-summary",
    btnText: "Project Summary",
  },
  {
    pathName: "project-details",
    btnText: "Project Details",
  },
  {
    icon: Beneficiaries,
    pathName: "beneficiaries-list",
    btnText: "Beneficiaries List",
  },
  {
    icon: FundDetailIcon,
    pathName: "funds-details",
    btnText: "Funds Details",
  },
  {
    icon: WorkPlanIcon,
    pathName: "work-plan",
    btnText: "Work Plan",
  },
  {
    pathName: "logical-framework",
    btnText: "Logical Framework",
  },
  {
    icon: SupportingIcons,
    pathName: "supporting-documents",
    btnText: "Supporting Documents",
  },
  {
    icon: CertificateIcon,
    pathName: "certificate",
    btnText: "Certificate",
  },
];

// <Route path="project-summary" element={<NocProjectSummary />} />
// <Route path="*" element={<NocProjectSummary />} />
// <Route path="project-details" element={<NocProjectDetails />} />
// <Route path="beneficiaries-list" element={<BeneficiariesList />} />
// <Route path="funds-details" element={<FundsDetails/>}/>
// <Route path="work-plan" element={<NocWorkPlan />} />
// <Route path="logical-framework" element={<NocLogicalFramework/>}/>
// <Route path="supporting-documents" element={<NocSupportingDocuments />} />
