/*export const formatPostFields = (obj) => {
    const r = {};
    Object.keys(obj).map((i) => {
        if (obj[i]) {
            let calculatedValue = obj[i];
            if (obj[i]?.value) {
                const { isEnum = false } = obj[i];
                const { value } = typeof (obj[i]) == 'object' ? obj[i] : { value: obj[i] };
                calculatedValue = value;
                if (Array.isArray(value)) {
                    calculatedValue = [];
                    value.map(j => {
                        let calculatedObject = {};
                        const keys = Object.keys(j);
                        keys.map(key => {
                            const val = typeof (j[key]?.value) == 'object' ? j[key]?.value?.value : j[key];
                            calculatedObject[key] = val;
                        })
                        if (Object.entries(calculatedObject).length) {
                            calculatedValue.push({ id: calculatedObject?.value, name: calculatedObject?.label });
                        }
                    })
                }
                if (!Array.isArray(value) && typeof (value) == 'object') {
                    calculatedValue = { id: calculatedValue.value, name: calculatedValue.label }
                    if (isEnum) {
                        calculatedValue = calculatedValue.id
                    }
                }
                //for media file upload
                if(obj[i]?.fileObject){
                    calculatedValue = obj[i]?.fileObject;  
                }
            }
            if (calculatedValue) {
                r[i] = calculatedValue;
            }
        }
    })
    return r;
}
*/

const keyExist = (key) => {
  const bannedKeys = ["_links", "_meta", "items"];
  return bannedKeys.includes(key);
};

export const formatPostFields = (obj) => {
  const r = {};
  Object.keys(obj).forEach((i) => {
    if (obj[i]) {
      let calculatedValue = obj[i];
      calculatedValue = getFieldValue(calculatedValue);
      if (calculatedValue && !keyExist(i)) {
        r[i] = calculatedValue;
      }
    }
  });

  return r;
};

const getFieldValue = (field) => {
  if (field?.value) {
    const { isEnum = false } = field;
    const { value } = typeof field == "object" ? field : { value: field };
    let calculatedValue = value;
    if (Array.isArray(value)) {
      let innerCalculatedArray = [];
      value.forEach((childObj) => {
        if (childObj instanceof File) {
          innerCalculatedArray.push({ file: childObj, id: 0 });
        } else if (
          typeof childObj?.value !== "object" &&
          childObj &&
          childObj.value
        ) {
          innerCalculatedArray.push({
            id: childObj.value,
            name: childObj.label,
          });
        } else if (typeof childObj !== "object") {
          innerCalculatedArray.push(childObj);
        } else if (childObj?.id && childObj?.url) {
          innerCalculatedArray.push({ ...childObj });
        } else {
          let calculatedObject = {};
          Object.keys(childObj).forEach((coValue) => {
            if (childObj[coValue]) {
              let innerCalculatedValue = childObj[coValue]; // if just simple array (multi dropdown select)
              if (!Array.isArray(childObj.value)) {
                if (
                  typeof innerCalculatedValue == "string" ||
                  typeof innerCalculatedValue == "number"
                ) {
                  innerCalculatedValue = innerCalculatedValue;
                }
                // for table input specificly
                else innerCalculatedValue = getFieldValue(innerCalculatedValue);
              }
              if (innerCalculatedValue) {
                calculatedObject[coValue] = innerCalculatedValue;
              }
            }
          });
          innerCalculatedArray.push(calculatedObject);
        }
      });
      calculatedValue = innerCalculatedArray;
    }
    if (!Array.isArray(value) && typeof value == "object") {
      if (calculatedValue?.value && calculatedValue.label)
        calculatedValue = {
          id: calculatedValue.value,
          name: calculatedValue.label,
        };
      if (isEnum) {
        calculatedValue = calculatedValue.id;
      }
    }
    //for media file upload
    if (field?.fileObject) {
      calculatedValue = field?.fileObject;
    }
    return calculatedValue;
  }
  if (field?.id) {
    return { ...field };
  }
  return null;
};

export const formatByHeader = (formatedData = {}, headers) => {
  if (headers["Content-Type"] == "multipart/form-data") {
    Object.entries(formatedData).map(([key, item]) => {
      if (item?.value && Array.isArray(item?.value)) {
        item?.value.forEach((arrayItem, index) => {
          let formatedName = `${key}[${index}]`;
          formatedData = { ...formatedData, [formatedName]: arrayItem };
        });
      } else if (Array.isArray(item)) {
        item.forEach((arrayItem, index) => {
          let formatedName = `${key}[${index}]`;
          // let formatedName = `${key}`;
          formatedData = { ...formatedData, [formatedName]: arrayItem };
        });
      } else {
        formatedData = { ...formatedData, [key]: item };
      }
    });
    return formatedData;
  }
  return formatedData;
};
