import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createRecord,
  getRecords,
  putRecord,
  setPageMeta,
  setRecords,
} from "../../redux/reducers";
import { useDispatch } from "react-redux";
import "./UserDetail.scss";
import { baseUrl } from "../../redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const UserDetailPage = () => {
  const { user } = useSelector((state) => state?.setting);
  const { response, created } = useSelector((state) => state?.records);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState(null);
  const { email, name, phone, id, profilePicture } = user;

  useEffect(() => {
    dispatch({ type: setPageMeta.type, payload: { title: "Account Details" } });
  }, []);

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setProfileImg(file);

    dispatch({
      type: createRecord.type,
      payload: {
        file: { name: "file", value: file },
        url: `/FileUpload/user/profile_picture`,
        headers: { "Content-Type": "multipart/form-data" },
        message: "Profile Picture Selected!",
      },
    });
  };

  const handleSubmit = () => {
    if (response?.filePath) {
      const params = {
        profilePicture: response?.filePath,
        name,
        phone,
      };
      dispatch({
        type: putRecord.type,
        payload: {
          url: `/User/${id}`,
          ...params,
          isFormatted: true,
          message: "Profile Picture Updated Successfully!",
        },
        onSuccess: [
          {
            type: getRecords.type,
            payload: {
              url: "/User/loggedUserDetail",
              typeOverride: "setting/setUser",
            },
          },
          {
            type: setRecords.type,
            payload: {
              response: {},
            },
          },
        ],
      });
    }
  };

  return (
    <div className="px-5 py-5 mt-5">
      <div className=" d-flex flex-row justify-content-end">
        <Icon.X
          size={32}
          color="red"
          onClick={() => {
            navigate(-1);
          }}
          className="cursor-pointer"
        />
      </div>
      <div
        className="bg-white shadow-sm rounded p-5"
        style={{ marginLeft: "10px" }}
      >
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col col-lg-10 mb-4 mb-lg-0">
            <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
              <div className="row g-0">
                <div className="col-md-4 gradient-custom  d-flex flex-column justify-content-center">
                  <div className="d-flex align-items-center justify-content-center ">
                    <img
                      src={
                        profilePicture
                          ? `${baseUrl}/${profilePicture}`
                          : require("../../assets/profile.png")
                      }
                      alt="Avatar"
                      className="img-fluid"
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "50%",
                        width: "200px",
                        height: "200px",
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2 row-position">
                    <div className="profile-edit">
                      <Icon.PencilSquare
                        size={30}
                        color="green"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="fileInput"
                        className="d-none"
                      />
                    </div>
                    {response?.filePath && (
                      <div className="profile-edit">
                        <Icon.Check
                          size={30}
                          color="green"
                          onClick={handleSubmit}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="card-body p-4 col-md-10 justify-content-center">
                    <span
                      className="font-weight-bold "
                      style={{ fontSize: "18px" }}
                    >
                      User Information
                    </span>

                    <hr className="mt-0 mb-4" />
                    <div className="pt-1">
                      <div className="col-md-12 mb-3">
                        <h6>Full Name</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {name}
                        </p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Email</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {email}
                        </p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Phone</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
