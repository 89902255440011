import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../components/form/Form";
import { createRecord, getRecords } from "../../redux/reducers";

const ChangePassword = () => {


  const ChangePasswordFields = [
    {
      children: "Change Password",
      inputType: "h3",
      inForm: true,
      wrapperClass: "col-md-12",
      className: "heading1",
    },
    {
      name: "oldPassword",
      inputType: "password",
      label: "Current Password",
      placeholder: "Current Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      name: "newPassword",
      inputType: "password",
      label: "New Password",
      placeholder: "New Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      name: "confirmNewPassword",
      inputType: "password",
      placeholder: "Confirm Password",
      label: "Confirm Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      name: "changePassword",
      inputType: "button",
      buttonType: "submit",
      children: "Change Password",
      className: "col-12",
      wrapperClass: "mr-3 btn-wrapper mt-3",
    },
  ];
  const {currentUser= []} = useSelector((state) => state.records?.current);
  const {id =""} = currentUser;

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200)
  }, []);

  const getData = () => {

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/User/loggedUserDetail`,
        appendCurrentReducerKey: "currentUser",
      },
    }
    )
  }

  const handleSubmit = (values) => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...values,
        url: `/User/${id}/ChangePassword`,
      },
    });
  };

  return (
    <div className="d-flex col-md-12 justify-content-center mt-5">
      <div className="bg-white shadow-sm py-5 col-md-6 rounded px-md-5">
        <Form
          formData={ChangePasswordFields}
          onSubmit={(params) => handleSubmit(params)}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
