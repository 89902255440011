import React from "react";
import "./charts.scss";
import ReactApexChart from "react-apexcharts";

const BudgetAllocationBarChart = ({ data }) => {
  const series = [
    {
      name: "Fund Allocated (PKR)",
      data: data?.map((i) => i?.pkrFundsAllocated),
    },
    {
      name: "Fund Utilized (PKR)",
      data: data?.map((i) => i?.pkrFundsUtilized),
    },
  ];
  const barChartOption = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    chart: {
      stacked: true,
      toolbar: {
        show: true,
        offsetX: 10,
        offsetY: -30,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FAC22D", "#45916B", "#C7493E"],
    xaxis: {
      categories: data?.map((i) => i?.clusterName),
      title: {
        text: "Sectors"
      }
    },
    yaxis: {
      title: {
        text: "Funds in PKR"
      },
      labels: {
        formatter: function (value) {
          if (value >= 1000) {
            return (value / 1000).toFixed(0) + "k";
          }
          return value;
        }
      }
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      markers: {
        width: 10,
        height: 10,
        radius: 10,
      },
    },
  };

  return (
    <div className="chart container-fluid my-4">
      <span className="chart-title fw-bold text-dark fs-6">Budget Allocation</span>
      <ReactApexChart
        options={barChartOption}
        series={series}
        type="bar"
        width={"100%"}
        height={450}
      />
    </div>
  );
};

export default BudgetAllocationBarChart;
