import { Link } from "react-router-dom";
import "../../assets/styles/global.scss";
import { formatDate } from "../../utils/util";
import { ThreeDots } from "react-bootstrap-icons";

export const ClosureColumns = [
  {
    name: "Project Title",
    selector: (row) => (
      <div className="">
        <Link >
          {row?.projectTitle}
        </Link>
      </div>
    ),
  },
  // {
  //   name: "Location",
  //   selector: (row) => row?.location,
  // },
  {
    name: "Status",
    selector: (row) => row?.status,
  },
  {
    name: "Creation Date",
    selector: (row) => formatDate(row?.createdOn ?? "0"),
  },
  // {
  //   name: "Action",
  //   selector: (row) => <ThreeDots width={30} height={30} />,
  //   width : "80px"
  // },
];
