import React from "react";
import "./Button.scss";
const Button = ({ className='',children, ...props }) => {
  const buttonClass = `btn ${className}`;
  return (
    <button className={buttonClass} {...props}>
      {children}
    </button>
  );
};

export default Button;
