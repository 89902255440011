import { put } from "redux-saga/effects";
import axios from "./api-client";
import { setRecords, setErrors, setUserMessages, clearForm } from "../reducers";
import { baseUrl } from "..";
import { formatPostFields } from "./sagas.helper";

export function* deleteRecordAsync(action) {
  const { url, headers = {}, ...payload } = action.payload;
  const {id}=payload;
  const fullUrl = baseUrl + '/api' + url + "/" + id;
  if (url) {
    try {
      const data = yield axios
        .delete(fullUrl, formatPostFields(payload), {headers})
        .then(({ data }) => data);
      yield put({
        type: setRecords.type,
        payload: { response: data, created: true },
      });
      yield put({
        type: setUserMessages.type,
        payload: {
          ...action,
          response: data,
          message: "Record Deleted Successfully!",
          created: true,
        },
      });

      yield put({
        type: clearForm.type,
      });
    } catch (e) {
      yield put({
        type: setUserMessages.type,
        payload: { ...action.payload, ...e },
      });
      yield put({
        type: setErrors.type,
        payload: { errorIn: action.type, message: e.message },
      });
      yield put({
        type: "records/setErrors",
        payload: { errorIn: action.type, message: e.message },
      });
    }
  } else {
    yield put({
      type: setErrors.type,
      payload: { errorIn: action.type, message: "No url specified!" },
    });
    yield put({
      type: "records/setErrors",
      payload: {
        errorIn: action.type,
        message: "No url specified for record!",
      },
    });
  }
}
