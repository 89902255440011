import React from "react";
import noData from "../../assets/images/no-data.png";
import "./style.scss";
import { fixFloatWidth } from "../../Utility";

const MonthlyTableDetail = ({ data,fetchData }) => {
  return (
    <div className="container table-style px-0 mt-2 w-100 overflow-x-scroll">
      <div className="table-wrap">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr className="bg-light">
              {/* <th className="text-center align-middle src">Sr. No.</th> */}
              <th className="text-center align-middle ri">Result Indicator</th>
              <th className="text-center align-middle">Unit of Measurement</th>
              <th className="text-center align-middle">Planned Target</th>
              <th className="text-center align-middle">
                Achieved (Reporting Period)
              </th>
              <th className="text-center align-middle">
                Achieved Cumulative *
              </th>
              <th className="text-center align-middle">
                % achieved Against Cumm.
              </th>
              <th className="text-center align-middle">Progress To Date</th>
              <th className="text-center align-middle">
                Reason For Delay (If any)
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length === 0 ? (
              <td colSpan={8} className="text-center">
                <img src={noData} />
                <h4 className="heading2"> No Data Available</h4>
              </td>
            ) : (
              data?.map((item, index) => {
                let achievedCommulative =
                  parseFloat(item?.achievedAmount) +
                  parseFloat(fetchData?.find(i => i?.id === item?.workPlanId)?.achievedAmount);
                let percentageAchieved =
                  (parseFloat(achievedCommulative) /
                  parseFloat(item?.plannedTarget)) * 100;
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.progressWorkPlan?.description}
                    </td>
                    <td className="text-center align-middle">
                      {item?.measurementUnit}
                    </td>
                    <td className="text-center align-middle">
                      {item?.plannedTarget}
                    </td>
                    <td className="text-center align-middle">
                      {item?.achievedAmount}
                    </td>
                    <td className="text-center align-middle">
                      {achievedCommulative}
                    </td>
                    <td className="text-center align-middle">
                      {fixFloatWidth(percentageAchieved)}
                    </td>
                    <td className="text-center align-middle">
                      {item?.progressStatus}
                    </td>
                    <td className="text-center align-middle">
                      {item?.reasonForDelay}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonthlyTableDetail;
