import React, { useEffect, useState } from "react";
import "./ExtensionDetail.scss";
import ReadableTable from "../../components/readableTable/ReadableTable";
import ExtensionDonorAgreement from "../../components/extension/ExtensionDonorAgreement";
import ExtensionRevisedWorkPlan from "../../components/extension/ExtensionRevisedWorkPlan";
import ExtensionSupportingDocs from "../../components/extension/ExtensionSupportingDocs";
import CommentSection from "../../components/comment-section/CommentSection";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, getRecords } from "../../redux/reducers";
import Loader from "../../components/loader/loader";
import { useNavigate, useParams } from "react-router-dom";
import DetailPagesStepper from "../../components/form-stepper/DetailPagesStepper";
import * as Icon from "react-bootstrap-icons";
import ReactSelect from "react-select";
import Modal from "react-modal";
import CommentSectionContainer from "../../components/comment-section/CommentSectionContainer";
import { canView, getRoleAsArray } from "../../utils/util";

const ExtensionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(true);
  const { created, loading } = useSelector((state) => state.records);
  const { extensionDetail = [] } = useSelector(
    (state) => state.records?.current
  );
  const { role } = useSelector((state) => state.setting?.user);
  const userRoles = role && getRoleAsArray(role);

  useEffect(() => {
    if (created) {
      navigate("/extension");
    }
  }, [created]);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/NocExtensionDetails`,
        appendCurrentReducerKey: "extensionDetail",
      },
    });
  };

  const toggleCommentSection = () => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
  };

  const handleApprove = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/NocApplication/${id}/Extension/approve`,
      },
    });
  };

  const handleReject = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/NocApplication/${id}/Extension/reject`,
      },
    });
  };

  const steps = [
    {
      name: "Donor Agreement",
      component: (
        <ExtensionDonorAgreement donorAgreement={extensionDetail ?? {}} />
      ),
    },
    {
      name: "Revised Work Plan",
      component: (
        <ExtensionRevisedWorkPlan workPlan={extensionDetail?.workPlans} />
      ),
    },
    {
      name: "Supporting Documents",
      component: <ExtensionSupportingDocs data={extensionDetail} />,
    },
  ];
  return (
    <div className="col-md-12 d-flex flex-row extension-detail">
      <Loader loading={loading} />

      {/* <div
        className={` mt-5 ${isCommentSectionOpen ? "col-md-9" : "col-md-12"}`}
      > */}
      <div className="col-md-12 mt-5">
        <div className="col-md-12">
          <DetailPagesStepper steps={steps.map((step) => step?.name)} />
        </div>
        <div className="px-4 py-2">
          <div className="col-md-12">
            <div className="content">
              {steps.map((step, index) => (
                <section
                  key={index}
                  id={`section${index + 1}`}
                  className="section"
                >
                  {step?.component}
                </section>
              ))}
            </div>
          </div>
        </div>
        {/* { canView(["Secretary"], userRoles) && <div style={{ gap: "10px" }} className="d-flex justify-content-end me-5">
        <button className="btn-backward bg-danger text-light  px-5" onClick={() => navigate(-1)}>
         Cancel
          </button>
          <button className="btn-backward px-5" onClick={() => handleReject(id)}>
          Reject
          </button>
          <button onClick={() => handleApprove(id)} className="btn-forward px-5">
            Approve
          </button>
        </div>
       } */}
      </div>
      {/* <CommentSectionContainer
        commentsData={[]}
        isCommentSectionOpen={isCommentSectionOpen}
        toggleCommentSection={toggleCommentSection}
      /> */}

      {/* <Modal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        contentLabel="Forward Application"
        overlayClassName="modal-xs"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">Forward Extension Request</p>
          <Icon.X
            size={32}
            color="black"
            onClick={() => setModalShow(!modalShow)}
          />
        </div>

        <div className="seprator"></div>

        <div className="mt-4">
          <p className="m-1">Forwarding Comments</p>
          <textarea
            className="form-control h-25"
            rows="5"
            placeholder="Enter Your Comments"
          ></textarea>
        </div>
        <div className="mt-3">
          <ReactSelect
            className="dropdown-input"
            options={[
              { value: "1", label: "M Irfan" },
              { value: "2", label: "Hadi M" },
              { value: "3", label: "Mehran" },
              { value: "4", label: "Umar" },
            ]}
            placeholder="Select Recipient"
          />
        </div>
        <div className="d-flex justify-content-end">
          <button onClick={() => {}} className="btn-forward">
            FORWARD
          </button>
        </div>
      </Modal> */}
    </div>
  );
};

export default ExtensionDetail;
