import React from "react";
import "./SearchFilterContainer.scss";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import Form from "../form/Form";
const SearchFilterContainer = ({handleSearch}) => {
  const formFields = useSelector((state) => state.fields.searchFields);
  return (
    <div>
      <div className="search-filter-container">
        <div className="card-body p-0">
            <Form
              formData={formFields ? formFields : []}
              onSubmit={(params) => handleSearch(params)}
              withValidation={false}
              formValidation={[]}
              extraInputClass=""
            />
          </div>
      </div>
    </div>
  );
};

export default SearchFilterContainer;
