import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { createRecord, getRecords } from "../../redux/reducers";
import { truncateText } from "../../utils/util";
import ToolTip from "../tooltip/Tooltip";
import "./Notifications.scss";

const Notifications = () => {
  const dispatch = useDispatch();
  const { created } = useSelector((state) => state.records);
  const { user } = useSelector((state) => state?.setting);

  const { notifications = [] } = useSelector(
    (state) => state?.records?.current
  );

  let items = [];
  if (notifications) {
    items = [...notifications]?.sort((a, b) =>
      a.isRead === b.isRead ? 0 : a.isRead ? 1 : -1
    );
  }

  useEffect(() => {
    if (created) getNotifications();
  }, [created]);

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/user`,
        appendCurrentReducerKey: "notifications",
      },
    });
  };

  const markAsRead = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/Notification/${id}/read`,
        message: "Notification marked as read",
      },
    });
  };

  const notificationTitle = (id, type, message) => (
    <>{truncateText(message, 32)}</>
  );

  return (
    <div className="notif-wrapper">
      <div className="icon cursor-pointer">
        <OverlayTrigger
          trigger="click"
          onEnter={() => getNotifications()}
          rootClose
          placement="bottom"
          overlay={
            <Popover
              id={`popover-contained popover-positioned-bottom`}
              style={{ minWidth: 350 }}
            >
              <Popover.Body className="h-25">
                <p className="mb-1 border-bottom text-center p-3">
                  Recent Notifications
                </p>

                {items &&
                  items.map(
                    ({ id, isRead, type, entityId, message }, index) =>
                      index < 5 && (
                        <div
                          style={{
                            backgroundColor: !isRead
                              ? "rgba(5, 210, 66, 0.08)"
                              : "",
                          }}
                          className="custom-notification-bar"
                          key={id}
                          onClick={isRead ? "" : () => markAsRead(id)}
                        >
                          <div className="notification-status">
                            {isRead ? (
                              <Icon.EnvelopeOpen color="#075f33" />
                            ) : (
                              <Icon.EnvelopeFill color="#075f33" />
                            )}
                          </div>
                          <div
                            className={`notification-text  ${
                              !isRead && "font-weight-bold"
                            }`}
                            style={{ "user-select": "none" }}
                          >
                            {notificationTitle(id, type, message)}
                          </div>
                        </div>
                      )
                  )}

                <div className="text-center">
                  <Link to="/notifications" style={{ fontSize: 12 }}>
                    Show All
                  </Link>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div className="d-flex ">
            <Icon.BellFill
              fill="none"
              stroke="#1E1F23"
              strokeWidth={"1px"}
              size={18}
            />

            <div className="notification-icon">
              {notifications.length > 0 && (
                <p>
                  {notifications.length > 99 ? "99+" : notifications.length}
                </p>
              )}
            </div>
          </div>
        </OverlayTrigger>
        {/* <span className="badge badge-pill bg-danger text-light">New</span> */}
      </div>
    </div>
  );
};

export default Notifications;
