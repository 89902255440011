import React from "react";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStepper } from "../../redux/reducers";
import { validateForm } from "../form/form.helper";
import FaIcon from "../../components/icon/FaIcon";

import "./FormStepper.scss";
import { CheckCircleFill, Circle } from "react-bootstrap-icons";

export const FormStepper = ({
  children = [],
  onNext,
  initialStep = 0,
  onSubmit,
  fields = [],
  data = [],
}) => {
  const { stepper } = useSelector((state) => state.setting);
  const { submitted = false, currentStep } = stepper;
  const dispatch = useDispatch();

  useEffect(() => {
    onCountUpdate(initialStep);
  }, []);

  useEffect(() => {
    if (submitted) onSubmit();
    return () => {
      dispatch({ type: setStepper.type, payload: { submitted: false } });
    };
  }, [submitted]);

  function onCountUpdate(value) {
    // setCount(value)
    // let isValid = true;
    // if (data && fields && Object.keys(data).length > 0)
    //   isValid = validateForm(fields, data, dispatch);
    // if (isValid) {
    dispatch({
      type: setStepper.type,
      payload: { currentStep: value, totalSteps: children.length },
    });
    dispatch({ type: "form/setValidations", payload: {} });
    // }
  }

  return (
    <div className="form-stepper">
      <StepperNav children={children} count={currentStep} />
      <Step children={children} count={currentStep} />
      <StepperAction
        count={currentStep}
        children={children}
        setCount={(value) => onCountUpdate(value)}
        onNext={onNext}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export const Step = ({ children, count }) => {
  return (
    <div className="stepper-content">
      {children.length && count <= children.length
        ? children[count]?.props.children
        : ""}
    </div>
  );
};

export const StepperNav = ({ children, count, ...otherProps }) => {
  return (
    <section className="step-wizard">
      <ul className="step-wizard-list">
        {children.map(({ props }, j) => (
          <div
            key={j}
            className={`step-wizard-item-d ${
              count === j ? "current-item" : ""
            } ${count > j ? "completed-item" : ""}`}
          >
            <span>
              {count > j ? (
                <CheckCircleFill color="#fff" size={24} />
              ) : count == j ? (
                <Circle color="#fff" size={24} />
              ) : (
                <Circle color="#d3d3d3" size={24} />
              )}
            </span>
            <span
              className={`
              progress-label ${
                count === j ? "current-label" : count > j ? "current-label" : ""
              }
            `}
            >
              {props?.title}
            </span>
          </div>
        ))}
      </ul>
    </section>
  );
};

export const StepperAction = ({
  count,
  children,
  setCount,
  onNext,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const onCancel = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{ minWidth: "30vw" }}
            className="d-flex flex-column shadow rounded rounded-6"
          >
            <div className=" bg-success px-3 py-4 text-center rounded-top">
              <FaIcon
                iconName="FaExclamationTriangle"
                color="#fff"
                size="4em"
              />
            </div>
            <div className="text-center px-3 py-4 bg-white rounded-bottom">
              <h3 style={{ color: "#162E4A" }}>Are you sure?</h3>
              <p style={{ color: "#162E4A" }}>
                You want to go back to previous screen?
              </p>
              <div className="d-flex gap-4 justify-content-center">
                <button
                  className="btn btn-primary mr-3 w-25"
                  onClick={() => {
                    navigate(-1);
                    onClose();
                  }}
                >
                  Yes
                </button>
                <button className="btn btn-primary w-25" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    });
    // confirmAlert({
    //   message: "Are you sure to Go Back",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => navigate(-1),
    //     },
    //     {
    //       label: "No",
    //       onClick: () => { },
    //     },
    //   ],
    //   closeOnEscape: true,
    //   closeOnClickOutside: true,
    //   keyCodeForClose: [8, 32],
    //   willUnmount: () => { },
    //   afterClose: () => { },
    //   onClickOutside: () => { },
    //   onKeypressEscape: () => { },
    //   overlayClassName: "overlay-custom-class-name",
    // });
  };

  return (
    <div className="stepper-action">
      {count == 0 ? (
        <button className="btn-primary btn-outline" onClick={() => onCancel()}>
          Cancel
        </button>
      ) : (
        <button
          className="btn-primary btn-outline"
          onClick={() => setCount(count - 1)}
        >
          Back
        </button>
      )}

      <button
        disabled={count >= children.length - 1}
        onClick={() => {
          onNext ? onNext() : setCount(count + 1);
        }}
      >
        Save & Next
      </button>

      {count >= children.length - 1 && (
        <button onClick={onSubmit}>Submit</button>
      )}
    </div>
  );
};
