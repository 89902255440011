import React from "react";
import "./reportcard.scss";
import { formateGeneralStatus } from "../../utils/util";
import option_icon from "../../assets/images/options_icon.svg";

const ReportCard = ({ title, icon, data = [], ...props }) => {

  return (
    <div className="monthly-report container-fluid p-0">
      <div className="d-flex flex-row  p-1 col-md-12">
        <span className="title d-flex flex-row align-items-center fw-bold text-dark fs-5 col-md-10">
          {title}
        </span>
        <span className="title-icon"></span>
        {/* <span className="title d-flex flex-row justify-content-end col-md-2">
          <img src={option_icon} alt="options_icon" />
        </span> */}
      </div>

      <div className="main  px-0 g-3 row-container">
        {data?.length > 0 ? (
          data?.map((item, key) => {
            return (
              <div className="main-child col-md-11 px-3" key={key}>
                <div className="shadow-sm bg-white rounded-2  col-md-2 icon d-flex justify-content-center ">
                  <img src={icon} alt="icon" width={30} />
                </div>
                <div className="d-flex flex-column col-md-7 gap-1">
                  <span className="heading col-md-12">
                    {item?.projectTitle ?? "Loading"}
                  </span>
                  <span className="date col-md-12">{item?.submissionDate}</span>
                </div>
                <div className="d-flex justify-content-center align-items-center col-md-4">
                  <div className="status ">
                    {/* {formateGeneralStatus(item?.status)
                      ? formateGeneralStatus(item?.status)
                      : ""} */}
                    {item?.missingReportMonth ?? "Loading"}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="main-child col-md-11 px-3">
            <div className="d-flex flex-column col-md-7 gap-1">
              <span className="heading col-md-12">No Data Found</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportCard;
