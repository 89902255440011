import React from "react";
import "./progressreportcard.scss";
import { formatDate, formateGeneralStatus } from "../../utils/util";
import option_icon from "../../assets/images/options_icon.svg";

const ProgressReportCard = ({ title, icon, data, ...props }) => {

  return (
    <div className="monthly-progress-report container-fluid p-0">
      {/* <div className='d-flex flex-row gap-5'><span className='title'>{title}</span> <span className='title-icon'><img src={option_icon} alt='options_icon'/></span></div> */}
      <div className="d-flex flex-row  p-1 col-md-12">
        <span className="title d-flex flex-row align-items-center fw-bold text-dark col-md-10 fs-5">
          {title}
        </span>{" "}
        <span className="title-icon"></span>
        {/* <span className='title d-flex flex-row justify-content-end col-md-2'>
            <img src={option_icon} alt='options_icon'/>
            </span> */}
      </div>

      <div className="main row-container">
        {data?.length > 0 ? (
          data?.map((item, key) => {
            return (
              <div className="main-child col-md-11" key={key}>
                <div className=" icon col-md-2 ">
                  <img src={icon} alt="icon" width={30} />
                </div>
                <div className="d-flex flex-column gap-2 col-md-10">
                  <div className="d-flex flex-row col-md-12">
                    <span className="heading col-md-7">
                      {item?.projectName ?? "Loading..."}
                    </span>
                    <span className="date col-md-5">
                      {formatDate(item?.projectEndDate ?? "0")}
                    </span>
                  </div>
                  {/* <div className="d-flex align-items-center">
                                        <div className="progress status" role="progressbar" aria-label="Example with label" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar" style={{ width:`${item.progress}`, backgroundColor:"green"}}></div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            );
          })
        ) : (
          <div className="main-child col-md-11">
            <div className="d-flex flex-column col-md-7 gap-1">
              <span className="heading col-md-12">No Data Found</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressReportCard;
