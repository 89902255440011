import React from "react";
import "./SupportingDocuments.scss";
import { Download } from "react-bootstrap-icons";
import { formatDate, formateDateTime } from "../../../utils/util";
import { getFormatedDate, handleDownloadFile } from "../../../Utility";
import { useDispatch } from "react-redux";

const SupportingDocuments = ({data=[]}) => {
  const dispatch = useDispatch();

  return (
    <div>
      <p className="label-heading">SupportingDocuments</p>

      <div className="row g-4">
        {data?.applicationDocuments?.map((item) => (
          <div className={`col-md-6`} key={item?.id}>
          <p className="mb-2 text-black label-text">{item?.documentName}</p>
            <div className="border px-3 rounded d-flex align-items-center justify-content-between p-2">
            <p className="px-0 my-0 general-text">{item?.appDoc.split('/').pop() ?? "Download File"}</p>
              <label for="files" className="upload-btn">
              {/* <a href={item?.appDoc} download className="download-btn">
                <Download size={20} color="white" /> Download
              </a> */}
              <button className="download-btn" onClick={(e) => handleDownloadFile(e,item?.appDoc)} > <Download size={20} color="white" /> Download</button>
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex  align-items-center flex-row justify-content-end my-1 gap-3 col-md-12">
        <div className="d-flex flex-row gap-2 justify-content-center align-items-center col-md-3">
          <p>Start Date:</p><p>{getFormatedDate(data?.agreementStartDate)}</p>
        </div>
        <div className="d-flex flex-row gap-2 justify-content-center align-items-center col-md-3">
          <p>End Date:</p><p>{getFormatedDate(data?.agreementEndDate)}</p>
        </div>
      </div>
    </div>
  );
};

export default SupportingDocuments;
