import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../hooks/useMediaQuery";
import { getRecords } from "../../redux/reducers";
import DashboardCard from "../dashboard/DashboardCard";
import BudgetAllocationBarChart from "../dashboard/BudgetAllocationBarChart";
import ReportCard from "../dashboard/ReportCard";
import ProgressReportCard from "../dashboard/ProgressReportCard";
import ReactApexChart from "react-apexcharts";
import option_icon from "../../assets/images/options_icon.svg";
import under_review_icon from "../../assets/images/under_review_icon.svg";
import vector_icon from "../../assets/images/vector.svg";
import noc_icon from "../../assets/images/noc_icon.svg";
import p_cost_icon from "../../assets/images/proejct_cost_icon.svg";
import g_icon from "../../assets/images/genral_icon.svg";
import { baseUrl, baseClientUrl } from "../../redux/index";
import WithPageHeader from "../ui/withPageHeader";
import FilterIcon from "../../assets/icons/FilterIcon";
import DashboardFilterDropDown from "../dashboard/dashboardFilterDropDown";

const DashboardMainPage = () => {
  const dispatch = useDispatch();
  const {
    statisticsData = [],
    nocStatusCount = [],
    sectorWiseStats = [],
    nocExpiryDates = [],
    missingProgressreport = [],
    districtWiseStats = [],
    nocSectorWiseFundsSummary = [],
    districts = [],
    sectors = [],
    projectStatus = [],
  } = useSelector((state) => state?.records?.current);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const isMobileView = useMediaQuery("(max-width: 768px)");

  const donutNocStatusChartOptions = {
    chart: {
      type: "donut",
    },
    labels: [...nocStatusCount.map((item) => item?.status)],
    colors: ["#FAC22D", "#45916B", "#C7493E"],
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              color: "#333",
              fontFamily: "Roboto Flex, sans-serif",
              fontWeight: 600,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: "#666",
              fontFamily: "Roboto Flex, sans-serif",
              fontWeight: 600,
              formatter: (val) => `${val}`,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const sectorWisePieChartoptions = {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      }
    },
    labels: [
      ...sectorWiseStats
        .filter((item) => item?.count > 0)
        ?.map((item) => item?.clusterName),
    ],
    colors: ["#FAC22D", "#45916B", "#C7493E",
      "#FFD700", "#FFB14E", "#FF8C00",
      "#66CDAA", "#2E8B57", "#20B2AA",
      "#FF6347", "#DC143C", "#8B0000"],
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              color: "#333",
              fontFamily: "Roboto Flex, sans-serif",
              fontWeight: 600,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: "#666",
              fontFamily: "Roboto Flex, sans-serif",
              fontWeight: 600,
              formatter: (val) => `${val}`,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const districtWisepieChartoptions = {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      }
    },
    colors: ["#FAC22D", "#45916B", "#C7493E",
      "#FFD700", "#FFB14E", "#FF8C00",
      "#66CDAA", "#2E8B57", "#20B2AA",
      "#FF6347", "#DC143C", "#8B0000"],
    labels: [
      ...districtWiseStats
        .filter((item) => item?.count !== 0)
        .map((item) => item?.districtName),
    ],

    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              color: "#333",
              fontFamily: "Roboto Flex, sans-serif",
              fontWeight: 600,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: "#666",
              fontFamily: "Roboto Flex, sans-serif",
              fontWeight: 600,
              formatter: (val) => `${val}`,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    fetchFilteredData();
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/District`,
        appendCurrentReducerKey: "districts",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Cluster`,
        appendCurrentReducerKey: "sectors",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Lookup/ProjectStatus`,
        appendCurrentReducerKey: "projectStatus",
      },
    });
  }, []);

  function fetchFilteredData(filters = {}) {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/Statistics`,
        params: filters,
        appendCurrentReducerKey: "statisticsData",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/NocStatusCount`,
        params: filters,
        appendCurrentReducerKey: "nocStatusCount",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/SectorWiseStats`,
        params: filters,
        appendCurrentReducerKey: "sectorWiseStats",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/SectorWiseStats`,
        params: filters,
        appendCurrentReducerKey: "sectorWiseStats",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/NocExpiryDates`,
        params: filters,
        appendCurrentReducerKey: "nocExpiryDates",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/MissingProgressreport`,
        params: filters,
        appendCurrentReducerKey: "missingProgressreport",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/DistrictWiseStats`,
        params: { ...filters },
        appendCurrentReducerKey: "districtWiseStats",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/NOCSectorWiseFundsSummary`,
        params: filters,
        appendCurrentReducerKey: "nocSectorWiseFundsSummary",
      },
    });
  }

  const color = ["#FAF3F2", "#FFF4DE", "#F3E8FF", "#DCFCE7"];
  const icons = [vector_icon, under_review_icon, noc_icon, p_cost_icon];
  const cardTitle = [
    "Organizations",
    "Under Review Applications",
    "Total NOCs Issued",
    "Total Cost of Projects (PKR)",
  ];

  function toggleDropdown() {
    setDropdownVisible(!dropdownVisible);
  }

  return (
    <>
      <WithPageHeader
        heading="Dashboard"
        buttons={[
          {
            isStyled: true,
            icon: (
              <DashboardFilterDropDown
                dropdownVisible={dropdownVisible}
                toggleDropdown={toggleDropdown}
                districts={districts}
                sectors={sectors}
                projectStatus={projectStatus}
                applyFilter={fetchFilteredData}
              />
            ),
          },
        ]}
      />
      <div className="dashboard shadow-sm bg-white rounded-3 px-0">
        <div className="row row-container px-3 g-3 m-0 table-container-anim">
          {statisticsData?.map((items, index) => {
            return (
              <div className="col-md-3" key={index}>
                <DashboardCard
                  title={cardTitle[index] ?? "Loading..."}
                  icon={icons[index]}
                  value={items?.value ?? 0}
                  bgColor={color[index]}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{ animationDelay: "0.3s" }}
          className="row row-container px-3 g-3 m-0 table-container-anim"
        >
          <div className="col-md-12 mb-3 shadow-sm">
            <BudgetAllocationBarChart data={nocSectorWiseFundsSummary} />
          </div>

          <div className="col-md-3">
            <ReportCard
              title={"Missing Progress Report"}
              icon={g_icon}
              data={missingProgressreport}
            />
          </div>
          <div className="col-md-3">
            <ProgressReportCard
              title={"Expired NOCs"}
              icon={g_icon}
              data={nocExpiryDates.map((item) => ({
                ...item,
                projectName: item?.projectTitle,
              }))}
            />
          </div>

          <div className="col-md-6">
            <div className="monthly-report-card col-md-12">
              <div className="monthly-report-title">
                <div className="d-flex flex-row justify-content-between fw-bold text-dark fs-5">
                  <span>NOC's Status</span>
                </div>
              </div>
              {nocStatusCount?.filter((item) => item?.count !== 0).length ===
                0 ? (
                <div className="d-flex justify-content-center align-items-center h-100 text-regular mt-3">
                  No Data Found
                </div>
              ) : (
                <ReactApexChart
                  options={donutNocStatusChartOptions}
                  series={nocStatusCount?.map((d) => d?.count)}
                  type="donut"
                  height={300}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{ animationDelay: "0.6s" }}
          className="row row-container px-3 g-3 m-0 table-container-anim"
        >
          <div className="col-md-6">
            <div className="monthly-report-card col-md-12">
              <span className="monthly-report-title fw-bold text-dark fs-5">District Wise Stats</span>
              <div className="d-flex flex-column gap-3">
                {districtWiseStats?.filter((item) => item?.count !== 0)
                  .length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center h-100 text-regular mt-3">
                    No Data Found
                  </div>
                ) : (
                  <ReactApexChart
                    options={districtWisepieChartoptions}
                    series={districtWiseStats?.map((item) => item?.count)}
                    type="donut"
                    height={450}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="monthly-report-card col-md-12">
              <div className="monthly-report-title fw-bold text-dark fs-5">
                <div className="d-flex flex-row justify-content-between">
                  <span>Sector Wise NOC</span>
                </div>
              </div>
              {sectorWiseStats?.filter((item) => item?.count !== 0).length ===
                0 ? (
                <div className="d-flex justify-content-center align-items-center h-100 text-regular mt-3">
                  No Data Found
                </div>
              ) : (
                <ReactApexChart
                  options={sectorWisePieChartoptions}
                  series={sectorWiseStats?.map((item) => item?.count)}
                  type="donut"
                  height={450}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardMainPage;
