import React, { useMemo } from "react";
import "./ProjectSummary.scss";
import ReadableCheckboxTable from "../../readableTable/ReadableCheckboxTable";
import ReadableTable from "../../readableTable/ReadableTable";
import WithPageHeader from "../../ui/withPageHeader";
import Seperator from "../../seperator/Seperator";
import WorkPlan from "./WorkPlan";
import LogicalFramework from "./LogicalFramework";
import { formatDate } from "../../../utils/util";

const ProjectSummary = ({ data = {}, districtData = {} }) => {
  const calculateTotalAmountInUSD = useMemo(() => {
    return (data?.projectCost?.operationalCost ?? []).reduce((acc, item) => {
      const amount = parseFloat(item?.amountinUSD) || 0;
      return acc + amount;
    }, 0);
  }, [data?.projectCost?.operationalCost]);

  const calculateTotalAmountInPKR = useMemo(() => {
    return (data?.projectCost?.operationalCost ?? []).reduce((acc, item) => {
      const amount = parseFloat(item?.amountInPKR) || 0;
      return acc + amount;
    }, 0);
  }, [data?.projectCost?.operationalCost]);

  const getTotalMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const totalMonths =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    return totalMonths;
  };

  const selectedOption = "lessThan10Miles";
  return (
    <div className="project-des">
      <div className="my-3">
        <WithPageHeader
          heading=" Plan under which project falls(if applicable)"
          buttons={[]}
        />

        <div className="header-name my-3">
          {data?.responsePlans?.map((item) => {
            return <div key={item?.id}>{<p>{item?.plan}</p>}</div>;
          })}
        </div>
      </div>
      <div className="my-3">
        <p className="label-heading">Cluster under which project falls</p>
        <div className="row">
          {data?.applicationClusters?.map((item) => {
            return (
              <div className="col-md-6  my-1 " key={item?.id}>
                <div className="header-name rounded-2">
                  <p className="mr-2">
                    {item.clusterName === "Other"
                      ? item?.clusterName + ` (${item?.clusterValue})`
                      : item.clusterName}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <ReadableTable
        headers={["District", "Tehsil", "Union Council", "Village"]}
        label="Geographic Location & Beneficiaries"
        data={
          data?.geographicLocations?.map(
            ({ id, districtId, ...rest }) => rest
          ) ?? []
        }
      />
      <div className="row">
        <div className="col-md-6">
          <div className="form-check form-switch px-2 switch d-flex flex-row">
            <p className="col-md-10">
              Is/Are the district, sharing the International Border
            </p>

            <input
              className="form-check-input"
              style={{ width: "50px" }}
              type="checkbox"
              id="flexSwitchCheckDefault"
              checked={districtData?.isBorderWithInternationalBorder ?? false}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-check form-switch switch px-2 d-flex flex-row">
            <p className="col-md-10">Sharing border with Tribal Areas</p>
            <input
              className="form-check-input"
              type="checkbox"
              style={{ width: "50px" }}
              id="flexSwitchCheckDefault"
              checked={districtData?.isBorderWithTribalAreas ?? false}
            />
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="d-flex flex-column radio-op">
          <p className="p-0 m-0 mb-3 text-black">
            Distance of the Area of intervention from International Border
          </p>
          <div className="d-flex align-items-center radio-row-container flex-wrap">
            {[
              {
                label: "Less than 10 Miles",
                value: "lessThan10Miles",
              },
              {
                label: "10 Miles to 30 Miles",
                value: "10MilesTo30Miles",
              },
              {
                label: "Above 30 Miles",
                value: "above30Miles",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center radio-container"
              >
                <input
                  type="radio"
                  value={item?.value}
                  checked={selectedOption === item?.value}
                />
                <p className="m-0">{item?.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="my-3">
        <p className="label-heading">
          Thematic Areas Under which the project falls
        </p>
        <div className="row">
          {data?.thematicAreas?.map((projectArea) => (
            <React.Fragment key={projectArea?.id}>
              {
                <div className="col-md-6  my-1 ">
                  <div className="header-name rounded-2 h-100">
                    <p className="mr-2">{projectArea?.areaName}</p>
                  </div>
                </div>
              }
            </React.Fragment>
          ))}
        </div>
      </div>

      <ReadableTable
        headers={[
          "District",
          "Sector Name",
          "Households",
          "No. of Males",
          "No. of Females",
          "Total Beneficiaries",
        ]}
        label="Estimated  number of  direct  beneficiaries"
        data={
          data?.projectBeneficiaries?.map(({ id, ...rest }) => ({
            districtName: rest?.districtName ?? "",
            clusterName: rest?.clusterName ?? "",
            houseHolds: rest?.houseHolds ?? 0,
            noOfMales: rest?.noOfMales ?? 0,
            noOfFemales: rest?.noOfFemales ?? 0,
            totalBeneficiaries: rest?.noOfMales + rest?.noOfFemales,
          })) ?? []
        }
      />

      <div className="row ps-3">
        <p className="label-heading px-0">Proposed timeframe for project</p>
        <div className="header-name col-md-3 me-4 rounded-2">
          <p>Total Months:</p>
          <p>{getTotalMonths(data?.projectStartDate, data?.projectEndDate)}</p>
        </div>
        <div className="header-name col-md-3 me-4 rounded-2">
          <p>Start Date:</p>
          <p>{formatDate(data?.projectStartDate)}</p>
        </div>
        <div className="header-name col-md-3 rounded-2">
          <p>End Date:</p>
          <p>{formatDate(data?.projectEndDate)}</p>
        </div>
      </div>

      <div className=" my-4">
        <WithPageHeader heading="Project Cost" buttons={[]} />
        <div>
          <p className="label-heading px-0 py-2">Fund Currency</p>
          <div className="header-name  rounded-2">
            <p>{data?.projectCost?.fundCurrencyName}</p>
          </div>
        </div>

        <p className="label-heading ">Program Cost</p>
      </div>
      <div className="d-flex flex-row gap-2">
        <div className="col-md-4">
          <p className="label">
            Amount in {data?.projectCost?.fundCurrencyName}
          </p>
          <div className="header-name rounded-2 ">
            <p className="mr-2">{data?.projectCost?.amountinUSD}</p>
          </div>
        </div>

        {data?.projectCost?.fundCurrencyName !== "PKR" && (
          <div className="col-md-4">
            <p className="label">Eqaualient PKR Amount</p>
            <div className="header-name rounded-2 ">
              <p className="mr-2">{data?.projectCost?.amountInPKR}</p>
            </div>
          </div>
        )}

        <div className="col-md-4">
          <p className="label">%age To Total </p>
          <div className="header-name rounded-2 ">
            <p className="mr-2">{data?.projectCost?.totalPercentage}</p>
          </div>
        </div>
      </div>

      <div className="col-md-6 my-3">
        <div className="d-flex flex-column ">
          <p className="label">Remark</p>
          <div className="header-name rounded-2 ">
            <p className="mr-2">{data?.projectCost?.remarks}</p>
          </div>
        </div>
      </div>

      <ReadableTable
        headers={[
          "Sector",
          "District",
          `Amount in ${data?.projectCost?.fundCurrencyName}`,
          "Equivalent PKR Amount",
          "% Age to total",
          "Remarks",
        ]?.filter((i) =>
          data?.projectCost?.fundCurrencyName === "PKR"
            ? i !== "Equivalent PKR Amount"
            : i
        )}
        label="Operational Cost"
        data={
          data?.projectCost?.operationalCost?.map(
            ({ id, districtId, clusterId, amountInPKR, ...rest }) => ({
              ...(!data?.projectCost?.fundCurrencyName == "PKR" && {
                amountInPKR,
              }),
              ...rest,
            })
          ) ?? []
        }
      />

      <div style={{ paddingLeft: "12px" }} className="row">
        <p className="label-heading px-0">Amount of funds confirmed</p>
        <div className="header-name col-md-4 me-4">
          <p>{data?.projectCost?.fundCurrencyName}:</p>
          <p>
            {calculateTotalAmountInUSD +
              parseFloat(data?.projectCost?.amountinUSD || 0)}
          </p>
        </div>
        {data?.projectCost?.fundCurrencyName !== "PKR" && (
          <div className="header-name col-md-4 me-4">
            <p className="col-md-6">Amount In PKR:</p>
            <p>
              {" "}
              {calculateTotalAmountInPKR +
                parseFloat(data?.projectCost?.amountInPKR || 0)}
            </p>
          </div>
        )}
      </div>

      <div className="row my-4">
        <Seperator width="100%" />
      </div>

      <WorkPlan data={data?.workPlans ?? []} />
      <LogicalFramework data={data?.logicalFrameworks ?? []} />
    </div>
  );
};

export default ProjectSummary;
