import React from "react";

const Beneficiaries = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={36}
      height={36}
      {...props}
    >
      <path d="M10.691 10.213c0-1.78-1.447-3.228-3.227-3.228s-3.226 1.449-3.226 3.228 1.447 3.226 3.226 3.226 3.227-1.447 3.227-3.226zM7.464 8.985a1.228 1.228 0 1 1-.001 2.453 1.228 1.228 0 0 1 .001-2.453zM3 21.433a1 1 0 0 0 1-1c0-.24.027-.476.075-.706a3.436 3.436 0 0 1 .94-1.736 3.446 3.446 0 0 1 3.146-.945c.229.048.448.118.652.21.5.224 1.094.009 1.325-.494a1 1 0 0 0-.495-1.325 5.27 5.27 0 0 0-2.175-.461c-1.46 0-2.834.568-3.867 1.601A5.417 5.417 0 0 0 2 20.433a1 1 0 0 0 1 1zm18.309-11.22c0 1.78 1.447 3.226 3.227 3.226s3.226-1.447 3.226-3.226-1.447-3.228-3.226-3.228-3.227 1.449-3.227 3.228zm3.227 1.226a1.228 1.228 0 0 1 0-2.454 1.228 1.228 0 0 1 0 2.454zm-.004 3.537a5.27 5.27 0 0 0-2.175.46 1 1 0 0 0 .83 1.82c.204-.092.423-.162.652-.21a3.446 3.446 0 0 1 3.146.945c.484.484.804 1.085.94 1.736.048.23.075.466.075.706a1 1 0 1 0 2 0 5.417 5.417 0 0 0-1.601-3.856 5.431 5.431 0 0 0-3.867-1.601zm-4.733-3.511c0-2.095-1.704-3.799-3.799-3.799s-3.799 1.704-3.799 3.799 1.704 3.799 3.799 3.799 3.799-1.704 3.799-3.8zM16 9.666c.992 0 1.799.807 1.799 1.799s-.807 1.799-1.799 1.799-1.799-.807-1.799-1.8.807-1.798 1.799-1.798zm-5.61 15.349a1 1 0 0 0 1-1v-.001c0-2.542 2.068-4.61 4.61-4.61s4.61 2.068 4.61 4.61a1 1 0 1 0 2 0c0-3.645-2.965-6.61-6.61-6.61s-6.61 2.965-6.61 6.61a1 1 0 0 0 1 1z" />
    </svg>
  );
};

export default Beneficiaries;
