import React from "react";
import { Route, Routes, useLocation } from "react-router";

import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import "./AddContainer.scss";
import DashboardPage from "./../../pages/dashboard/DashboardPage";
import DefinitionManagement from "./../definition/DefinitionManagement";
import SearchFilterContainer from "./../search-filter/SearchFilterContainer";
import UserDetailPage from "../../pages/userAccount.js/UserDetailPage";
import UserUpdatePage from "../../pages/userAccount.js/UserUpdatePage";
import UserManagements from "../../pages/user-management/UserManagements";
import UserManagement from "../../pages/user-management/UserManagement";
import AddUpdateUserManagement from "../../pages/user-management/AddUpdateUserManagement";
import NotificationPage from "../../pages/notification/NotificationPage";
import ChangePassword from "../../pages/userAccount.js/ChangePassword";
import { useSelector } from "react-redux";
import { canUserAccess, canView, getRoleAsArray } from "../../utils/util";
import ChartDetail from "../../pages/chart-detail/ChartDetail";
import AddUpdateApplications from "../../pages/applications/AddUpdateApplication";
import Applications from "../../pages/applications/Applications";
import Noc from "../../pages/noc/Noc";
import NocDetail from "../../pages/noc/NocDetail";
import AddUpdateNoc from "../../pages/noc/AddUpdateNoc";
import MonthlyReport from "../../pages/monthly-report/MonthlyReport";
import AddUpdateMonthlyReport from "../../pages/monthly-report/AddUpdateMonthlyReport";
import ApplicationDetail from "../../pages/applications/ApplicationDetail";
import MonthlyReportDetail from "../../pages/monthly-report/MonthlyReportDetail";
import UserOrganizationDetails from "../../pages/user-management/UserOrganizationDetails";
import ExtensionNoc from "../../pages/extension/ExtensionNoc";
import ExtensionDetail from "../../pages/extension/ExtensionDetail";
import AddUpdateExtension from "../../pages/extension/AddUpdateExtension";
import Closures from "../../pages/closure/closures";
import Report from "../../pages/report/Report";
import NgoUserDetails from "../organization/NgoUserDetails";
import GrievencePage from "../../pages/Grievence/GrievencePage";

const AppContainer = () => {
  // const { setMeta } = usePageMeta();
  const location = useLocation();
  const { role } = useSelector((state) => state.setting?.user);
  const userRoles = role && getRoleAsArray(role);

  React.useEffect(() => {
    // setMeta();
  }, [location]);

  return (
    <div className="d-flex">
      <div className="sidebar" style={{ backgroundColor: "#e9ebed" }}>
        <Sidebar />
      </div>
      <div className="main-content w-100">
        <Header />
        <Routes>
          <Route path="/applications/*">
            <Route path="" element={<Applications />} />
            <Route path="add" element={<AddUpdateApplications />} />
            <Route path="update/:id" element={<AddUpdateApplications />} />
            <Route
              path="detail/:id/:organizationId"
              element={<ApplicationDetail />}
            />
          </Route>
          <Route path="/nocs/*">
            <Route path="" element={<Noc />} />
            <Route path="add" element={<AddUpdateNoc />} />
            <Route path="update/:id" element={<NocDetail />} />
            <Route path=":id/:orgId/*" element={<NocDetail />} />
          </Route>
          {!canView(["NOC Section"], userRoles) && (
            <>
              <Route path="/extension/*">
                <Route path="" element={<ExtensionNoc />} />
                <Route path="add" element={<AddUpdateExtension />} />
                <Route path="update/:id" element={<AddUpdateExtension />} />
                <Route path=":id/*" element={<ExtensionDetail />} />
              </Route>
              <Route path="/closure/*">
                <Route path="" element={<Closures />} />
              </Route>
              <Route path="/monthly-report/*">
                <Route path="" element={<MonthlyReport />} />
                <Route
                  path="detail/:id/:nocId"
                  element={<MonthlyReportDetail />}
                />
                <Route path="add" element={<AddUpdateMonthlyReport />} />
                <Route path="add/:id" element={<AddUpdateMonthlyReport />} />
              </Route>
            </>
          )}

          <Route path="/user/*">
            <Route path="Detail" element={<UserDetailPage />} />
            <Route path=":id" element={<UserUpdatePage />} />
            <Route path="changePassword" element={<ChangePassword />} />
            <Route path="ngo-user-details/:id" element={<NgoUserDetails />} />
          </Route>

          <Route
            path="/*"
            element={
              canView(["SuperAdmin", "Secretary", "Focal Person"], userRoles) ? (
                <DashboardPage />
              ) : (
                <Applications />
              )
            }
          />
          {canView(["SuperAdmin"], userRoles) && (
            <>
              <Route path="/chart-detail/" element={<ChartDetail />} />
              <Route path="/definitions/*" element={<DefinitionManagement />} />
            </>
          )}
          <Route path="/search-filter/*" element={<SearchFilterContainer />} />
          <Route path="/reports" element={<Report />} />

          {canView(["SuperAdmin","NOC Section"], userRoles) && (
            <Route path="/user-management/*">
              <Route path="" element={<UserManagements />} />
              <Route path="project/:id" element={<UserManagements />} />
              <Route path=":id" element={<UserManagement />} />
              <Route path="add" element={<AddUpdateUserManagement />} />
              <Route path="update/:id" element={<AddUpdateUserManagement />} />
              <Route
                path="organization-details/:id"
                element={<UserOrganizationDetails />}
              />
            </Route>
          )}
          <Route path="/notifications/*">
            <Route path="" element={<NotificationPage />} />
          </Route>
          <Route path="/grievence/*">
            <Route path="" element={<GrievencePage />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default AppContainer;
