import React from "react";

const FundDetailIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" {...props}>
      <path
        fillRule="evenodd"
        d="M10 40c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm8 0h-5c-.55 0-1 .45-1 1s.45 1 1 1h5c.55 0 1-.45 1-1s-.45-1-1-1Zm1.61-26.97c-1.45.69-2.8 1.7-4 2.93-.89.91-1.7 1.94-2.4 3.05H8c-.55 0-1 .45-1 1s.45 1 1 1h4.08c-1.12 2.25-1.83 4.69-2.03 7H8c-.55 0-1 .45-1 1s.45 1 1 1h2.02c.39 7.23 6.44 10 13.98 10 2.15 0 4.19-.23 5.99-.72A7.97 7.97 0 0 0 36 42.01c4.42 0 8-3.58 8-8 0-3.83-2.7-7.04-6.31-7.82-.79-4.08-3.06-8.3-6.16-11.04-.98-.86-2.03-1.58-3.15-2.11l1.55-4.18c.14-.38.04-.82-.27-1.09 0 0-1.88-1.76-5.67-1.76s-5.67 1.76-5.67 1.76c-.31.27-.41.71-.27 1.09l1.55 4.18ZM36 28c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6Zm-.39-1.99c-.8-3.49-2.76-7.03-5.4-9.37C28.4 15.04 26.28 14 24 14c-2.61 0-5 1.35-6.96 3.35-3.05 3.12-5.04 7.83-5.04 11.9C12 35.81 17.44 38 24 38c1.71 0 3.33-.15 4.81-.48A7.953 7.953 0 0 1 28 34a8 8 0 0 1 7.61-7.99ZM9 35H5c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1Zm26-4v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1h-2v-2c0-.55-.45-1-1-1s-1 .45-1 1Zm-12-7.95a2.5 2.5 0 0 0 .5 4.95h1c.13 0 .26.05.35.15.09.09.15.22.15.35s-.05.26-.15.35a.51.51 0 0 1-.35.15H22c-.55 0-1 .45-1 1s.45 1 1 1h1v1c0 .55.45 1 1 1s1-.45 1-1v-1.05a2.488 2.488 0 0 0 2-2.45c0-.67-.26-1.3-.73-1.77S25.17 26 24.5 26h-1a.47.47 0 0 1-.35-.15c-.09-.09-.15-.22-.15-.35s.05-.26.15-.35a.51.51 0 0 1 .35-.15H26c.55 0 1-.45 1-1s-.45-1-1-1h-1v-1c0-.55-.45-1-1-1s-1 .45-1 1v1.05ZM5 28c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm21.51-15.67c.34-.91 1.28-3.45 1.28-3.45-.66-.37-1.93-.89-3.79-.89s-3.13.52-3.79.89l1.28 3.45c.82-.22 1.66-.33 2.51-.33s1.7.12 2.51.33ZM8 9c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm8 0h-5c-.55 0-1 .45-1 1s.45 1 1 1h5c.55 0 1-.45 1-1s-.45-1-1-1Z"
      />
    </svg>
  );
};

export default FundDetailIcon;
