import React, { useEffect } from "react";
import WithPageHeader from "../../components/ui/withPageHeader";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";
import { extensionFields } from "../../assets/fields/extensionFields";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectSummaryForm from "../../components/applications/form/ProjectSummaryForm";
import WorkAndPlanForm from "../../components/applications/form/WorkAndPlanForm";
import SupportingDocumentForm from "../../components/applications/form/SupportingDocumentForm";
import ApplicationConfirmModal from "../../components/applications/ApplicationConfirmModal";
import "./ExtensionNoc.scss";
import WorkPlan from "../../components/applications/applicationDetail/WorkPlan";
import Loader from "../../components/loader/loader";
import { directBenefFields } from "../../assets/fields/directBenefFields";
import { geographicLocationFields } from "../../assets/fields/geographicLocationFields";
import { formateRecordsDropDown, getFieldMeta } from "../../utils/util";
import toast from "react-hot-toast";

const AddUpdateExtension = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = React.useState(false);
  const selectedSteps = {
    0: "Revised Project Details",
    1: "Revised work Plan",
    2: "Supporting Documents",
  };

  const location = useLocation();
  const dispatch = useDispatch();

  let { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const edit = queryParams.get("Edit"); // "true"
  let canUpdate = false;
  if (id) canUpdate = true;

  const { currentStep } = useSelector((state) => state?.setting?.stepper);
  const params = useSelector((state) => state?.form?.values);

  const { values, validations, lookups } = useSelector((state) => state.form);

  const nocAppId = lookups?.nocAppId ?? [];
  const projectId = values?.nocAppId?.value?.value ?? undefined;

  const { data, created, loading, creating } = useSelector(
    (state) => state?.records
  );
  const { projectData = [], extensionData = {} } = useSelector(
    (state) => state?.records?.current
  );

  const { nocLastExtensionDetail, nocProjectDetail } = projectData ?? {};
  const initialWorkPlans = nocLastExtensionDetail?.workPlans ?? [];

  useEffect(() => {
    if (created) {
      navigate("/extension");
      setModalShow(false);
    }

    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [created]);

  useEffect(() => {
    let nocAppData = {};
    if (nocAppId && Array.isArray(nocAppId) && id && !projectId) {
      nocAppData = nocAppId.find((item) => item?.id === id);

      if (nocAppData !== undefined) {
        dispatch({
          type: formatRecords.type,
          payload: {
            nocAppId: {
              value: nocAppData?.id,
              label: nocAppData?.projectTitle,
            },
          },
        });
      }
    } else if (projectId !== undefined && !edit) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/NocApplication/${projectId}/GetNocProjectAndExtensionDetails`,
          appendCurrentReducerKey: "projectData",
        },
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/NocApplication/${id}/NocExtensionDetails`,
          appendCurrentReducerKey: "extensionData",
        },
      });
    }
  }, [id]);

  useEffect(() => {
    // if (nocLastExtensionDetail) {
    //   const formatedExtensionData = formateExtensionDataDetails(
    //     nocLastExtensionDetail
    //   );
    //   dispatch({
    //     type: formatRecords.type,
    //     payload: { ...formatedExtensionData },
    //   });
    // } else if (!nocLastExtensionDetail && nocProjectDetail) {
    //   const formatedExtensionData =
    //     formateExtensionDataDetails(nocProjectDetail);
    //   dispatch({
    //     type: formatRecords.type,
    //     payload: { ...formatedExtensionData },
    //   });
    // } else
     if (extensionData && Object.keys(extensionData)?.length > 0) {
      let modifiedData = {
        ...extensionData,
        ...extensionData?.fundDetail,
        ...extensionData?.projectCost,
      };
      let nocAppId = formateRecordsDropDown(
        modifiedData,
        "nocAppId",
        "projectTitle",
        "nocApplicationId",
        false,
        true
      );
      let fundTypeId = formateRecordsDropDown(
        modifiedData,
        "fundTypeId",
        "fundType",
        "fundTypeId"
      );

      let fundsCurrencyId = formateRecordsDropDown(
        modifiedData,
        "fundsCurrencyId",
        "fundCurrencyName",
        "fundsCurrencyId"
      );
      //geogaphic Location
      const districtMeta = getFieldMeta(geographicLocationFields, "districtId");
      let geographicLocation = modifiedData?.geographicLocations?.map((i) => {
        const districtFieldData = formateRecordsDropDown(
          i,
          "districtId",
          "districtName",
          "districtId",
          false,
          true,
          districtMeta
        );

        return {
          districtId: {
            ...districtFieldData,
          },
          tehsil: {
            ...getFieldMeta(geographicLocationFields, "tehsil"),
            value: i.tehsil,
            label: i.tehsil,
          },
          unionCouncil: {
            ...getFieldMeta(geographicLocationFields, "unionCouncil"),
            value: i.unionCouncil,
            label: i.unionCouncil,
          },
          village: {
            ...getFieldMeta(geographicLocationFields, "village"),
            value: i.village,
            label: i.village,
          },
          edit: false,
        };
      });

      //beneficiaries
      // const applicationClusters = modifiedData?.applicationClusters?.map(
      //   (i) => {
      //     return {
      //       id: i?.id,
      //       clusterId: i?.clusterId,
      //       name: i?.clusterName,
      //       clusterValue: i?.clusterValue,
      //     };
      //   }
      // );
      const clusterIdMeta = getFieldMeta(directBenefFields, "clusterId");
      const benefiDistrictMeta = getFieldMeta(directBenefFields, "districtId");
      const projectbeneficiaries = modifiedData?.projectBeneficiaries?.map(
        (i) => {
          const sectorFieldData = formateRecordsDropDown(
            i,
            "clusterId",
            "clusterName",
            "clusterId",
            false,
            true,
            clusterIdMeta
          );

          const districtFieldData = formateRecordsDropDown(
            i,
            "districtId",
            "districtName",
            "districtId",
            false,
            true,
            benefiDistrictMeta
          );

          return {
            clusterId: {
              ...sectorFieldData,
            },
            districtId: {
              ...districtFieldData,
            },

            houseHolds: {
              ...getFieldMeta(directBenefFields, "houseHolds"),
              value: i.houseHolds,
              label: i.houseHolds,
            },

            noOfMales: {
              ...getFieldMeta(directBenefFields, "noOfMales"),
              value: i.noOfMales,
              label: i.noOfMales,
            },
            noOfFemales: {
              ...getFieldMeta(directBenefFields, "noOfFemales"),
              value: i.noOfFemales,
              label: i.noOfFemales,
            },
            edit: false,
          };
        }
      );
      //Work Plan

      //logical framework
      let logicalFramework = modifiedData?.logicalFrameworks?.map((i) => {
        return {
          narrativeSummery: formateRecordsDropDown(
            i,
            "narrativeSummery",
            "narrativeSummery",
            "narrativeSummery",
            false,
            true
          ),
          resultType: formateRecordsDropDown(
            i,
            "resultType",
            "resultType",
            "resultType",
            false,
            true
          ),
          expectedResult: {
            name: "expectedResult",
            value: i.expectedResult,
            label: i.expectedResult,
          },
          performanceMeasure: {
            name: "performanceMeasure",
            value: i.performanceMeasure,
            label: i.performanceMeasure,
          },
          assumptionsAndRisks: {
            name: "assumptions , risks",
            value: i.assumptionsAndRisks,
            label: i.assumptionsAndRisks,
          },
          edit: false,
        };
      });

      let operationalCost = modifiedData?.projectCost?.operationalCost?.map(
        (i) => {
          return {
            ...i,
            sector: { label: i.clusterName, value: i.clusterId },
            district: { label: i.districtName, value: i.districtId },
          };
        }
      );

      let updatedModifiedData = {
        ...modifiedData,
        ...fundTypeId,
        ...fundsCurrencyId,
        nocAppId: nocAppId,
        // applicationClusters : applicationClusters,
        logicalFrameworks: logicalFramework,
        geographicLocations: geographicLocation,
        projectBeneficiaries: projectbeneficiaries,
        projectCost: {
          ...modifiedData.projectCost,
          operationalCost,
        },
      };
      console.log("🚀 ~ useEffect ~ updatedModifiedData:", updatedModifiedData);
      dispatch({
        type: formatRecords.type,
        payload: { ...updatedModifiedData },
      });
    }
    //nocLastExtensionDetail
  }, [extensionData]);

  // const formateExtensionDataDetails = (nocLastExtensionDetail) => {
  //   let modifiedData = {
  //     ...nocLastExtensionDetail,
  //     ...nocLastExtensionDetail?.fundDetail,
  //     ...nocLastExtensionDetail?.projectCost,
  //   };
  //   let fundTypeId = formateRecordsDropDown(
  //     modifiedData,
  //     "fundTypeId",
  //     "fundType",
  //     "fundTypeId"
  //   );

  //   let fundsCurrencyId = formateRecordsDropDown(
  //     modifiedData,
  //     "fundsCurrencyId",
  //     "fundCurrencyName",
  //     "fundsCurrencyId"
  //   );
  //   //geogaphic Location
  //   const districtMeta = getFieldMeta(geographicLocationFields, "districtId");
  //   let geographicLocation = modifiedData?.geographicLocations?.map((i) => {
  //     const districtFieldData = formateRecordsDropDown(
  //       i,
  //       "districtId",
  //       "districtName",
  //       "districtId",
  //       false,
  //       true,
  //       districtMeta
  //     );

  //     return {
  //       districtId: {
  //         ...districtFieldData,
  //       },
  //       tehsil: {
  //         ...getFieldMeta(geographicLocationFields, "tehsil"),
  //         value: i.tehsil,
  //         label: i.tehsil,
  //       },
  //       unionCouncil: {
  //         ...getFieldMeta(geographicLocationFields, "unionCouncil"),
  //         value: i.unionCouncil,
  //         label: i.unionCouncil,
  //       },
  //       village: {
  //         ...getFieldMeta(geographicLocationFields, "village"),
  //         value: i.village,
  //         label: i.village,
  //       },
  //       edit: false,
  //     };
  //   });

  //   //beneficiaries

  //   const clusterIdMeta = getFieldMeta(directBenefFields, "clusterId");
  //   const benefiDistrictMeta = getFieldMeta(directBenefFields, "districtId");
  //   const projectbeneficiaries = modifiedData?.projectBeneficiaries?.map(
  //     (i) => {
  //       const sectorFieldData = formateRecordsDropDown(
  //         i,
  //         "clusterId",
  //         "clusterName",
  //         "clusterId",
  //         false,
  //         true,
  //         clusterIdMeta
  //       );

  //       const districtFieldData = formateRecordsDropDown(
  //         i,
  //         "districtId",
  //         "districtName",
  //         "districtId",
  //         false,
  //         true,
  //         benefiDistrictMeta
  //       );

  //       return {
  //         clusterId: {
  //           ...sectorFieldData,
  //         },
  //         districtId: {
  //           ...districtFieldData,
  //         },

  //         houseHolds: {
  //           ...getFieldMeta(directBenefFields, "houseHolds"),
  //           value: i.houseHolds,
  //           label: i.houseHolds,
  //         },

  //         noOfMales: {
  //           ...getFieldMeta(directBenefFields, "noOfMales"),
  //           value: i.noOfMales,
  //           label: i.noOfMales,
  //         },
  //         noOfFemales: {
  //           ...getFieldMeta(directBenefFields, "noOfFemales"),
  //           value: i.noOfFemales,
  //           label: i.noOfFemales,
  //         },
  //         edit: false,
  //       };
  //     }
  //   );
  //   //Work Plan

  //   //logical framework
  //   let logicalFramework = modifiedData?.logicalFrameworks?.map((i) => {
  //     return {
  //       narrativeSummery: formateRecordsDropDown(
  //         i,
  //         "narrativeSummery",
  //         "narrativeSummery",
  //         "narrativeSummery",
  //         false,
  //         true
  //       ),
  //       resultType: formateRecordsDropDown(
  //         i,
  //         "resultType",
  //         "resultType",
  //         "resultType",
  //         false,
  //         true
  //       ),
  //       expectedResult: {
  //         name: "expectedResult",
  //         value: i.expectedResult,
  //         label: i.expectedResult,
  //       },
  //       performanceMeasure: {
  //         name: "performanceMeasure",
  //         value: i.performanceMeasure,
  //         label: i.performanceMeasure,
  //       },
  //       assumptionsAndRisks: {
  //         name: "assumptions , risks",
  //         value: i.assumptionsAndRisks,
  //         label: i.assumptionsAndRisks,
  //       },
  //       edit: false,
  //     };
  //   });

  //   let operationalCost =
  //     modifiedData?.projectCost?.operationalCost &&
  //     modifiedData?.projectCost?.operationalCost.length > 0
  //       ? modifiedData?.projectCost?.operationalCost?.map((i) => {
  //           return {
  //             ...i,
  //             sector: { label: i.clusterName, value: i.clusterId },
  //             district: { label: i.districtName, value: i.districtId },
  //           };
  //         })
  //       : [];
  //   let updatedModifiedData = {
  //     ...modifiedData,
  //     ...fundTypeId,
  //     ...fundsCurrencyId,
  //     fundDetail: {},
  //     logicalFrameworks: logicalFramework,
  //     geographicLocations: geographicLocation,
  //     projectBeneficiaries: projectbeneficiaries,
  //     projectCost: {
  //       ...modifiedData.projectCost,
  //       operationalCost,
  //     },
  //   };
  //   return updatedModifiedData;
  // };

  const submitExtensionApplication = () => {
    const ifilteredDoc = params?.applicationDocuments?.value?.filter(
      (i) => !i.id
    );

    const parameters = {
      projectTitle: params?.projectTitle?.value,
      responsePlans: params?.responsePlans?.value,
      applicationClusters: params?.applicationClusters?.value,
      projectStartDate: params?.projectStartDate?.value,
      projectEndDate: params?.projectEndDate?.value,
      geographicLocations: params?.geographicLocations?.value?.map((i) => {
        return {
          districtId: i?.districtId?.value?.value,
          tehsil: i?.tehsil?.value,
          unionCouncil: i?.unionCouncil?.value,
          village: i?.village?.value,
        };
      }),
      thematicAreas: params?.thematicAreas?.value,
      projectBeneficiaries: params?.projectBeneficiaries?.value?.map((i) => {
        return {
          clusterId: i?.clusterId?.value?.value,
          districtId: i?.districtId?.value?.value,
          houseHolds: i?.houseHolds?.value,
          noOfFemales: parseInt(i?.noOfFemales?.value),
          noOfMales: parseInt(i?.noOfMales?.value),
        };
      }),
      projectCost: {
        fundsCurrencyId: params?.fundsCurrencyId?.value.value,
        amountinUSD: parseInt(params?.amountinUSD?.value),
        amountInPKR: parseInt(params?.amountInPKR?.value),
        totalPercentage: parseFloat(params?.totalPercentage?.value),
        remarks: params?.remarks?.value,
        operationalCost: params?.projectCost?.value?.operationalCost?.map(
          (item) => ({
            clusterId: item?.sector?.value,
            districtId: item?.district?.value,
            amountinUSD: parseInt(item?.amountinUSD),
            amountInPKR: parseInt(item?.amountInPKR),
            totalPercentage: parseFloat(item?.totalPercentage),
            remarks: item?.remarks,
          })
        ),
      },
      projectStartDate: params?.projectStartDate?.value,
      projectEndDate: params?.projectEndDate?.value,
      //step2
      workPlans: params?.workPlans?.value,
      //step3
      applicationDocuments: ifilteredDoc,

      agreementStartDate: params?.agreementStartDate?.value,
      agreementEndDate: params?.agreementEndDate?.value,

      isFormatted: true,
    };
    if (!edit) {
      dispatch({
        type: createRecord.type,
        payload: {
          ...parameters,
          // isDraft: true,
          url: `/NocApplication/NocExtension?nocAppId=${
            id ? id : values?.nocAppId?.value?.value
          }`,
          isFormatted: true,
        },
      });
    } else {
      dispatch({
        type: putRecord.type,
        payload: {
          ...parameters,
          isSubmit: true,
          url: `/NocApplication/${id}`,
          isFormatted: true,
        },
      });
    }
  };

  return (
    <div className="px-4 py-2 mt-5">
      <Loader loading={loading || creating} />
      <WithPageHeader
        heading={`NOC Extension`}
        text={`${selectedSteps[currentStep]}`}
        buttons={[]}
      />
      <div className=" mt-3">
        <FormStepper
          fields={extensionFields}
          data={values}
          initialStep={0}
          onSubmit={() => setModalShow(true)}
        >
          <Step key="revisedProjectDetail" title="Revised Project Details">
            <ProjectSummaryForm extension />
          </Step>
          <Step key="revisedWorkPlan" title="Revised work Plan">
            <WorkAndPlanForm extension initialData={initialWorkPlans ?? []} />
          </Step>
          <Step key="revisedSupportingDocuments" title="Supporting documents">
            <SupportingDocumentForm extension />
          </Step>
        </FormStepper>
      </div>

      <ApplicationConfirmModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Submit Your NOC Application"}
      >
        <p className="m-0 mt-4">
          Please print and submit a physical copy of your NOC application along
          with the following items to the Temporarily Displaced Person (TDP)
          Department:
        </p>
        <ol type="1" className="mt-3">
          <li className="numbered-list">Original Cover Letter</li>
          <li className="numbered-list">
            Legal Status/Registration Certificate
          </li>
          <li className="numbered-list">
            Proof of Registration (Social Welfare Department/SECP)
          </li>
          <li className="numbered-list">MOU with EAD/Ministry of Interior</li>
          <li className="numbered-list">One Page Summary (Project Details)</li>
          <li className="numbered-list">
            Project-specific MOU/Acknowledgment with EAD
          </li>
          <li className="numbered-list">Annexes (A, B, C)</li>
          <li className="numbered-list">CNIC/Passport Copies</li>
          <li className="numbered-list">
            Donor Agreement with Implementing Partner (IP) or Letter of Intent
          </li>
          <li className="numbered-list">Detailed Budget Breakdown</li>
          <li className="numbered-list">Audit Report</li>
          <li className="numbered-list">Tax Return Certificate</li>
          <li className="numbered-list">Proposed Staff List</li>
          <li className="numbered-list">Work Plan</li>
          <li className="numbered-list">
            Progress Report (counter-signed by District Coordinator CEW-PDMA for
            extensions)
          </li>
          <li className="numbered-list">Affidavit on Stamp Paper</li>
        </ol>

        <div className="d-flex align-items-center gap-3">
          <input
            type="checkbox"
            checked={isAgreed}
            onChange={(e) => setIsAgreed(e.target.checked)}
            style={{ width: "16px", height: "16px" }}
          />
          <p className="m-0">I have understood the requirements</p>
        </div>
        <div className="d-flex justify-content-end gap-3">
          <button
            onClick={() => {
              setModalShow();
              setIsAgreed(false);
            }}
            className="btn-backward w-25"
          >
            CANCEL
          </button>
          <button
            onClick={() => {
              setModalShow(false);
              submitExtensionApplication();
            }}
            className={`${
              isAgreed ? "btn-forward" : "btn-backward disabled"
            } w-25`}
            disabled={!isAgreed}
          >
            OK
          </button>
        </div>
      </ApplicationConfirmModal>
    </div>
  );
};

export default AddUpdateExtension;
