import React from "react";
import ReactApexChart from "react-apexcharts";
import { PieChartAgeData } from "./ApexSampleData";
import noData from "../../assets/no-data.png";

const PieChartApex = ({ data, innerHeight }) => {
  let series = data.series.some(i => i > 0)

  if (data.series.length === 0 || !series) {
    return (
      <div className="text-center p-5 mt-5">
        <img src={noData} alt="logo" height={60} />
        <p>No Data Found!</p>
      </div>
    );
  } else {
    return (
      <ReactApexChart
        options={data?.options}
        series={data?.series}
        type="donut"
        height={innerHeight ? 500 : 300}
      />
    );
  }
};

export default PieChartApex;
