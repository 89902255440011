import React, { useEffect } from 'react'
import ReadableTable from '../readableTable/ReadableTable';
import "./ngouserdetails.scss";
import WithPageHeader from '../ui/withPageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createRecord, getRecords } from '../../redux/reducers';
import Loader from '../loader/loader';
import { getFormatedDate } from '../../Utility';

const NgoUserDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { ngoUserDetails = [] } = useSelector((state) => state.records?.current);
    const { created, loading } = useSelector((state) => state.records);
    const navigate = useNavigate();


    useEffect(() => {
        setTimeout(() => {
            getData()
        }, 200)
    }, [])

    const getData = () => {
        dispatch({
            type: getRecords.type,
            payload: {
                url: `/User/${id}/OrganizationUser`,
                appendCurrentReducerKey: "ngoUserDetails",
            },
        });
    }
    const header = ["Office", "Email Address", "Phone No.", "Fax No."];
    const keyStafHeader = [
        "Key staff type ",
        "Staff Level",
        "Name",
        "Email",
        "Phone No.",
        "Cell No.",
        "Fax No.",
    ];
    const foriegnEmpHeader = [
        "Name",
        "Copy of Passport",
        "Address in Pakistan",
        "Address in Home Country",
    ];
    const existingLocalsHeader = [
        "Name",
        "Father Name",
        "CNIC",
        "Address (Permanent/Temporary)",
        "Contact No.",
    ];
    const projectAreaHeader = [
        "Project Title",
        "Project Area",
        "Cluster/line department",
        "Donor Organization",
    ];

    const handleApprove = () => {
        dispatch({
            type: createRecord.type,
            payload: {
              url: `/User/${id}/approve`,
              message: "User Approved Successfully!",
            },
          });
    }

    const handleBlock = () => {
        dispatch({
            type: createRecord.type,
            payload: {
              url: `/User/${id}/block`,
              message: "User Blocked Successfully!",
            },
          });
    }

    return (
        <div className='col-md-12 shadow-sm mb-2 p-5 mt-3 rounded'>
            <Loader loading={loading} />

            <div className="organization-details d-flex flex-column gap-5 p-2 mt-2 bg-wh">

                <WithPageHeader heading="Ngo User Details" buttons={[]} />
                <div className="bg-white px-4 py-4 rounded-top-2">

                    <p className="label">User Details</p>
                    <div className="d-flex flex-column justify-content-between gap-3 mb-2">
                        <div className="header-name">
                            <p className="" style={{ width: "150px" }}>Name:</p>
                            <p>{ngoUserDetails?.name}</p>
                        </div>
                        <div className="header-name">
                            <p style={{ width: "150px" }}>Email</p>
                            <p>{ngoUserDetails?.email}</p>
                        </div>
                        <div className="header-name">
                            <p style={{ width: "150px" }}>Phone</p>
                            <p>{ngoUserDetails?.phone}</p>
                        </div><div className="header-name">
                            <p style={{ width: "150px" }}>Role</p>
                            <p>{ngoUserDetails?.role}</p>
                        </div>
                    </div>
                </div>




                <div className="bg-white px-4 py-4 rounded-top-2">
                    <p className="label">Oragnization Details</p>
                    <div className="d-flex flex-column justify-content-between gap-2 mb-2">
                        <div className="header-name">
                            <p style={{ width: "150px" }}>Organization Name:</p>
                            <p>{ngoUserDetails?.userOrganizationResponse?.organizationName}</p>
                        </div>
                        <div className="header-name">
                            <p style={{ width: "150px" }}>NGO Type:</p>
                            <p> {ngoUserDetails?.userOrganizationResponse?.organizationType} </p>
                        </div>

                    </div>
                </div>

                <div className="bg-white px-4 py-4 rounded-top-2">
                    {/* <p className="header-child mt-3"></p> */}
                    <p className="label">Registration Details</p>
                    <div className="d-flex flex-column justify-content-between gap-4 ">
                        <div className=" col-md-12 ">
                            <div className="header-name col-md-12  mt-3">
                                <p style={{ width: "150px" }}> Where Registered:</p>
                                <p>
                                    {ngoUserDetails?.userOrganizationResponse?.registeredLocation}
                                </p>
                            </div>
                        </div>

                        <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                            <div className="header-name col-md-6 ">
                                <p style={{ width: "150px" }}> Registration Certificate:</p>
                                <p className="underlined_text">
                                    {ngoUserDetails?.userOrganizationResponse?.registrationCertificateDoc?.replace(/^.*[\\\/]/, "")}
                                </p>
                            </div>
                            <div className="header-name col-md-6">
                                <p style={{ width: "150px" }}>NTN Number:</p>
                                <p>
                                    {ngoUserDetails?.userOrganizationResponse?.ntnNumber}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                            <div className="header-name col-md-6 ">
                                <p style={{ width: "150px" }}>Start date:</p>
                                <p >
                                    {getFormatedDate(ngoUserDetails?.userOrganizationResponse?.regStartDate)}
                                </p>
                            </div>
                            <div className="header-name col-md-6">
                                <p style={{ width: "150px" }}>End date:</p>
                                <p>
                                    {getFormatedDate(ngoUserDetails?.userOrganizationResponse?.regEndDate)}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                            <div className="header-name col-md-6 ">
                                <p style={{ width: "200px" }}>Previous Year Tax Return:</p>
                                <p className="underlined_text">
                                    {ngoUserDetails?.userOrganizationResponse?.yearOfTaxReturn}
                                </p>
                            </div>
                            <div className="header-name col-md-6 ">
                                <p style={{ width: "180px" }}>Previous Year Tax Return:</p>
                                <p className="underlined_text">
                                    {ngoUserDetails?.userOrganizationResponse?.previousYearReturnsDoc?.replace(/^.*[\\\/]/, "")}
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">
                            <div className="header-name col-md-6">
                                <p style={{ width: "150px" }}>Star Date of Mou</p>
                                <p>
                                    {getFormatedDate(ngoUserDetails?.userOrganizationResponse?.mouStartDate)}
                                </p>
                            </div>
                            <div className="header-name col-md-6 ">
                                <p style={{ width: "150px" }}>End Date of Mou</p>
                                <p>
                                    {
                                        getFormatedDate(ngoUserDetails?.userOrganizationResponse?.mouEndDate)
                                    }
                                </p>
                            </div>

                        </div>


                        <div className="d-flex flex-row justify-content-between gap-3 pe-3 col-md-12 ">

                            <div className="header-name col-md-6">
                                <p style={{ width: "150px" }}>Mou Doc</p>
                                <p className="underlined_text">
                                    {ngoUserDetails?.userOrganizationResponse?.mouMoiDoc?.replace(/^.*[\\\/]/)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white px-4 py-4 rounded-top-2 d-flex flex-column gap-3">
                    <p className="label">Esential Contacts</p>
                    <div>
                        <p className="header-child">Head Of NGO</p>
                        <div className="d-flex flex-column justify-content-between gap-3 ">
                            <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                                <div className="header-name col-md-4 ">
                                    <p style={{ width: "150px" }}> Name:</p>
                                    <p>{ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[1]?.name}</p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Email:</p>
                                    <p>{ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[1]?.email}</p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Designation:</p>
                                    <p>
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[1]?.designation}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                                <div className="header-name col-md-4 ">
                                    <p style={{ width: "150px" }}> Phone No:</p>
                                    <p>{ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[1]?.phone}</p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Mobile No:</p>
                                    <p>
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[1]?.mobile}
                                    </p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Cnic Copy:</p>
                                    <p className="underlined_text">
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[1]?.cnicDoc?.replace(/^.*[\\\/]/, "")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="header-child mt-2">Focal Person Information</p>
                        <div className="d-flex flex-column justify-content-between gap-3 ">
                            <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                                <div className="header-name col-md-4 ">
                                    <p style={{ width: "150px" }}> Name:</p>
                                    <p>{ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[0]?.name}</p>

                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Email:</p>
                                    <p>{ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[0]?.email}</p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Designation:</p>
                                    <p>
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[0]?.designation}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row justify-content-between gap-2 col-md-12 ">
                                <div className="header-name col-md-4 ">
                                    <p style={{ width: "150px" }}> Phone No:</p>
                                    <p>
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[0]?.phone}
                                    </p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Mobile No:</p>
                                    <p>
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[0]?.mobile}
                                    </p>
                                </div>
                                <div className="header-name col-md-4">
                                    <p style={{ width: "150px" }}>Cnic Copy:</p>
                                    <p className="underlined_text">
                                        {ngoUserDetails?.userOrganizationResponse?.userOrganizationContacts[0]?.cnicDoc?.replace(/^.*[\\\/]/, "")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white px-4 py-3  rounded-top-2">
                    <ReadableTable
                        label="office Information"
                        headers={header}
                        data={ngoUserDetails?.userOrganizationResponse?.userOrganizationOffices?.map((items, index) => {
                            return {
                                officeType: items?.officeType,
                                mailAddress: items?.mailAddress,
                                phone: items?.phone,
                                fax: items?.fax,
                            }
                        }) ?? []}
                    />
                    <ReadableTable
                        label="Key Staff Information"
                        headers={keyStafHeader}
                        data={ngoUserDetails?.userOrganizationResponse?.userDonorOrganziationStaff ?? []}
                    />
                    <ReadableTable
                        label="Details of Foreigners working in the NGO/INGO"
                        headers={foriegnEmpHeader}
                        data={ngoUserDetails?.userOrganizationResponse?.userOrganizationForeigners?.map((items, index) => {
                            return {
                                name: items?.name,
                                passportDoc: items?.passportDoc?.replace(/^.*[\\\/]/, ""),
                                addressInPakistan: items?.addressInPakistan,
                                addressInHomeCountry: items?.addressInHomeCountry
                            }
                        }) ?? []}
                    // topHeader="Details of Foreigners working in the NGO/INGO"
                    />
                    <ReadableTable
                        label=" Details of Existing locals working in the NGO/INGO"
                        headers={existingLocalsHeader}
                        data={ngoUserDetails?.userOrganizationResponse?.userOrganizationLocals?.map((items, index) => {
                            return {
                                name: items?.name,
                                fatherName: items?.fatherName,
                                cnicNo: items?.cnicNo,
                                address: items?.address,
                                contactNo: items?.contactNo,

                            }
                        }) ?? []}
                    // topHeader=" Details of Existing locals working in the NGO/INGO"
                    />
                    <ReadableTable
                        label="Details of Project Undertaken by the NGO/iNGO in the Project Area"
                        headers={projectAreaHeader}
                        data={ngoUserDetails?.userOrganizationResponse?.userOrganizationProjects ?? []}
                    // topHeader="Details of Project undertaken by the NGO/INGO in the project area"
                    />
                </div>
                <div  className="d-flex justify-content-end align-items-center px-3">
                    <button className="btn btn-success "onClick={() => {handleApprove()}}>
                        APPROVE
                    </button>
                    <button className="btn btn-danger" onClick={() => {handleBlock()}}>
                      BLOCK
                    </button>
                    <button className="btn btn-secondary"   onClick={() => { navigate("/user-management")}}>
                        CANCEL
                    </button>
                </div>

            </div>
        </div>
    )
}

export default NgoUserDetails