import React, { useEffect, useState } from "react";
import OrganizationDetails from "../../components/organization/OrganizationDetails";
import WithPageHeader from "../../components/ui/withPageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";

const UserOrganizationDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { userDetails = [] } = useSelector((state) => state.records?.current);
  // const [selectedOrganization, setSelectedOrganization] = useState(
  //   userDetails.find((item) => item?.organizationId === parseInt(id))
  // );

  const { loading, creator, created } = useSelector((state) => state?.records);

  useEffect(() => {
    dispatch({  
      type: getRecords.type,
      payload: {
        url: "/User/GetOrganizationUserDetail",
        params: {
          orgUserId: id,
        },
        appendCurrentReducerKey: "userDetails",
      },
    });
  }, []);


  return (
    <div className="px-5 py-3 mt-5">
      <Loader loading={loading} />
      <WithPageHeader
        heading="Organization Information"
        buttons={[
          {
            text: "Edit Organization Info",
            className: "regularButton",
          },
        ]}
      />
      <OrganizationDetails organizationData={userDetails} />
    </div>
  );
};

export default UserOrganizationDetails;
