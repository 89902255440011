import React from "react";
import "./OrganizationInformationDetail.scss";
import ReadableTable from "../../readableTable/ReadableTable";

const OrganizationInformationDetail = ({ data }) => {
  const header = ["Office", "Email Address", "Phone No.", "Fax No."];
  const keyStafHeader = [
    "Key staff type ",
    "Staff Level",
    "Name",
    "Email",
    "Phone No.",
    "Cell No.",
    "Fax No.",
  ];

  const orderedData = data?.organizationOffices?.map((office) => ({
    "Office": office.officeType,
    "Email Address": office.mailAddress,
    "Phone No.": office.phone,
    "Fax No.": office.fax
  })) ?? [];

  
  // mailAddress: 'lorem ipsumds',
  // phone: '000011993',
  // fax: '0987654356',
  // officeType: null

  return (
    <div className="organization-des">
      <p className="label">Organization Contact Information</p>
      <div className="header-name">
        <p>Organization Name:</p>
        <p>{data?.organizationName}</p>
      </div>

      {/* <ReadableTable
        label="office Information"
        headers={header}
        data={data?.organizationOffices ?? []}
      /> */}
      <ReadableTable
  label="Office Information"
  headers={header}
  data={orderedData}
/>
      <ReadableTable
        label="Key Staff Information"
        headers={keyStafHeader}
        data={data?.donorOrganizationStaff ?? []}
      />
    </div>
  );
};

export default OrganizationInformationDetail;
