import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, getRecords } from "../../../redux/reducers";
import { BoxArrowUp, Calendar } from "react-bootstrap-icons";
import { getFieldsByCategory } from "../../../utils/util";
import { applicationFields } from "../../../assets/fields/applicationFields";
import Form from "../../form/Form";
const SupportingDocumentForm = ({ extension }) => {
  const dispatch = useDispatch();
  const [isMergedArea, setIsMergedArea] = useState(false);
  const { validations } = useSelector((state) => state.form);

  const { applicationDocument } = useSelector(
    (state) => state?.records?.current
  );
  const [filteredData, setFilteredData] = useState(applicationDocument ?? []);
  const formValues = useSelector((state) => state.form?.values);
  const selectedDistricts = formValues?.geographicLocations?.value;
  const districtsData = useSelector((state) => state.form?.lookups?.districtId);
  const mergedDistricts = districtsData?.filter(
    (item) => item?.isPartOfMergedDistrict
  );

  useEffect(() => {
    const getIsMergedDistrict = () => {
      for (let j = 0; j < mergedDistricts?.length; j++) {
        for (let i = 0; i < selectedDistricts?.length; i++) {
          const item = selectedDistricts[i];
          if (item?.districtId?.value?.value === mergedDistricts[j]?.id) {
            setIsMergedArea(true);
            return;
          }
        }
      }
      setIsMergedArea(false);
    };

    getIsMergedDistrict();
  }, [selectedDistricts, mergedDistricts]);

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: extension
          ? "/ApplicationDocument?isNocExtensionDoc=true"
          : "/ApplicationDocument",
        appendCurrentReducerKey: "applicationDocument",
      },
    });
  }, [extension, dispatch]);

  useEffect(() => {
    if (applicationDocument) {
      let filteredData = applicationDocument;

      if (!isMergedArea) {
        if (formValues?.fundTypeId?.value?.label === "Self Funded") {
          filteredData = applicationDocument.filter(
            (document) =>
              document.documentName !== "Affidavit on Stamp Paper" &&
              document.documentName !== "Annex A, B, C" &&
              document.documentName !==
                "Agreement Between Donor and Implementing Organization" &&
              document.documentName !== "One-Page Summary"
          );
        } else {
          filteredData = applicationDocument.filter(
            (document) =>
              document.documentName !== "Affidavit on Stamp Paper" &&
              document.documentName !== "Annex A, B, C" &&
              document.documentName !== "One-Page Summary"
          );
        }
      } else {
        if (formValues?.fundTypeId?.value?.label === "Self Funded") {
          filteredData = applicationDocument.filter(
            (document) =>
              document.documentName !==
              "Agreement Between Donor and Implementing Organization"
          );
        } else {
          filteredData = applicationDocument;
        }
      }

      setFilteredData(filteredData);
    }
  }, [applicationDocument, isMergedArea, formValues]);

  const handleFileChange = (id, e) => {
    const selectedFile = e.target.files[0];
    dispatch({
      type: createRecord.type,
      payload: {
        file: { name: "file", value: selectedFile },
        url: "/FileUpload/nocApplication/file",
        headers: { "Content-Type": "multipart/form-data" },
        supressMessages: true,
      },
      onSuccess: [
        {
          type: "form/setFormFile",
          payload: {
            name: "applicationDocuments",
            documentId: id,
            isObj: true,
          },
        },
      ],
    });
  };

  return (
    <div className="col-md-12 bg-white shadow-sm mb-2 p-4 rounded">
      <div className="container-fluid px-0">
        <div className="row g-3">
          {(filteredData ?? applicationDocument)?.map((document, index) => {
            return (
              <div key={document?.id} className="col-6">
                <label className="text-regular text-dark fw-bold mb-1">
                  {document?.documentName}
                </label>
                <div className="bg-light px-3 py-2 rounded d-flex align-items-center justify-content-between">
                  <input
                    className="visually-hidden"
                    id={index}
                    type="file"
                    accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv"
                    onChange={(e) => handleFileChange(document?.id, e)}
                  />
                  <p className="px-0 my-0">
                    {formValues?.applicationDocuments?.value?.find(
                      (item) => item?.documentId === document?.id
                    )
                      ? formValues?.applicationDocuments?.value
                          ?.find((item) => item.documentId === document?.id)
                          ?.appDoc?.split("/")
                          .pop()
                      : "Upload"}
                  </p>

                  <label for={index} className="upload-button">
                    <BoxArrowUp size={20} color="black" /> Upload
                  </label>
                </div>
              </div>
            );
          })}
        </div>

        {!extension && (
          <div className="col-md-12 mb-2">
            <Form
              formData={getFieldsByCategory(
                applicationFields,
                "agreementDates"
              )}
              withValidation={true}
              formValidation={validations}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportingDocumentForm;
