import React, { useEffect, useState } from "react";
import "./CommentSection.scss";
import SearchIcon from "../../assets/icons/SearchIcon";
import NoMessageIcon from "../../assets/icons/NoMessageIcon";

import { Button } from "react-bootstrap";
import Seperator from "../seperator/Seperator";
import { ChatDots, Clock, PersonCircle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { createRecord } from "../../redux/reducers";
import { canView, getRoleAsArray } from "../../utils/util";
import ToggleCommentSection from "./ToggleCommentSection";

const CommentSection = ({ commentOptions, commentsData = [], ...props }) => {
  const [selectedTab, setSelectedTab] = React.useState("Internal");
  const [filterData, setFilteredData] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const dispatch = useDispatch();

  const { created, searchText } = useSelector((state) => state.records);
  const { role } = useSelector((state) => state.setting?.user);

  const userRoles = role && getRoleAsArray(role);

  useEffect(() => {
    if (role === "NGO User") {
      setSelectedTab("External");
    }
    filterCommentsData();
  }, [commentsData, selectedTab]);

  useEffect(() => {
    if (created) {
      setInputValue("");
    }
  }, [created]);

  function filterCommentsData() {
    let data = commentOptions
      ? commentsData?.length > 0 &&
        [...commentsData]
          .filter((item) => item.type == selectedTab)
          .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
      : commentsData?.length > 0 &&
        [...commentsData].sort(
          (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        );
    setFilteredData(data);
  }

  function submitComment() {
    if (inputValue) {
      let data = {
        comment: inputValue,
        isInternalComment: selectedTab == "Internal" ? true : false,
      };

      dispatch({
        type: createRecord.type,
        payload: {
          ...data,
          url: `${props?.url}?${props?.param}=${props?.id}`,
          isFormatted: true,
          message: "Commented",
        },
      });
    }
  }

  function handleSearch(e) {
    dispatch({
      type: "records/setSearch",
      payload: {
        commentSearch: e.target.value,
      },
    });
  }

  return (
    <>
      {
        <ToggleCommentSection
          isCommentSectionOpen={props?.isCommentSectionOpen}
          toggleCommentSection={props?.toggleCommentSection}
        />
      }
      {props?.isCommentSectionOpen ? (
        <div
          style={{ marginTop: "-22px" }}
          className="comment-section  col-md-12"
        >
          <div className="comment-header px-1 py-2 col-md-12">
            <div className="d-flex flex-row gap-1  col-md-12">
              <div className="comment-header-data col-md-10 ">
                <SearchIcon />
                <span style={{ fontSize: "12px" }}>
                  <input
                    placeholder="Search"
                    type="text"
                    className="border-0"
                    value={searchText?.commentSearch}
                    onChange={handleSearch}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="comment-body col-md-12">
            {commentOptions && !canView(["NGO User"], userRoles) && (
              <div className="comment-options col-md-10">
                <button
                  onClick={() => setSelectedTab("Internal")}
                  className={`comment-options-child col-md-6 px-0 mx-0 ${
                    selectedTab == "Internal" ? "selected" : ""
                  }`}
                >
                  Internal
                </button>
                <button
                  onClick={() => setSelectedTab("External")}
                  className={`comment-options-child right col-md-6 px-0 mx-0
                ${selectedTab == "External" ? "selected" : ""}
                `}
                >
                  External
                </button>
              </div>
            )}
            {props?.canComment && (
              <>
                <textarea
                  className="comment-body-data  col-md-11"
                  placeholder="Make a comment"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />

                <div
                  className="d-flex flex-row gap-4 col-md-11 justify-content-end"
                  style={{ height: "30px" }}
                >
                  <Button
                    onClick={() => setInputValue("")}
                    className="bg-white text-black border-black   px-3 d-flex flex-column justify-content-center"
                  >
                    <span className="comment-btn-text ">Cancel</span>
                  </Button>
                  <Button
                    onClick={submitComment}
                    className="text-white  px-3  d-flex flex-column justify-content-center "
                  >
                    <span className="comment-btn-text">Submit</span>
                  </Button>
                </div>
              </>
            )}
          </div>

          <Seperator width="100%" height="1px" />

          <div
            className={`previous-comments col-md-12 ${
              filterData?.length <= 0 ? "no-data" : ""
            }`}
          >
            {(typeof filterData === "boolean" && filterData !== true) ||
            filterData?.length <= 0 ? (
              <div className="no-comments col-md-12">
                <NoMessageIcon fill="#DCDCDC" />
                <span className="nc-text">No comments-yet</span>
                <span className="nc-text-2">
                  Give feedback, ask questions, or start a discussion in the
                  comments
                </span>
              </div>
            ) : (
              <div className="mt-3">
                {filterData?.length > 0 &&
                  filterData?.map((item) => {
                    return (
                      <div
                        className="previous-comments-child col-md-12"
                        key={item?.id}
                      >
                        <div className="d-flex flex-column gap-2 p-2 bg-white shadow-sm border rounded">
                          <div className="d-flex flex-row gap-2 jutsify-content-start align-items-center">
                            <span>
                              <PersonCircle size={24} />
                            </span>
                            <span className="comment-username">
                              {item?.createdBy}
                            </span>
                          </div>
                          <div className="d-flex flex-column p-1">
                            <span className="comment-text-data">
                              {item?.comment}
                            </span>
                          </div>

                          <div className="d-flex flex-row gap-3 jutsify-content-start align-items-center">
                            <span className="comment-time">
                              <Clock />{" "}
                              {new Date(item?.createdOn).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{ marginTop: "-22px" }}
          className="col-md-12 h-100 bg-white border"
        >
          <div className="col-md-12 mt-5 text-center">
            <div onClick={props?.toggleCommentSection}>
              <ChatDots size={32} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentSection;
