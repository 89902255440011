import React, { useEffect, useState } from "react";
import Form from "../../form/Form";
import { formatDate, getFieldsByCategory } from "../../../utils/util";
import { applicationFields } from "../../../assets/fields/applicationFields";
import { extensionFields } from "../../../assets/fields/extensionFields";
import ActivityAndSubActivityTable from "./ActivityAndSubActivityTable";
import ReadableTable from "../../readableTable/ReadableTable";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../Utility";

const WorkAndPlanForm = ({ extension, ...props }) => {
  const workplanHeader = [
    // "Sr",
    "Work Type",
    "Description",
    "Start Date",
    "End Date	",
    "Responsible",
    "Means of Verification",
  ];
  const initialWorkPlans = props?.initialData;
  const [rows, setRows] = useState([]);


  const formateData = (data) => {
    console.log("🚀 ~ formateData ~ data:", data)
    return data?.map(({ id, verificationMeanId, ...item }, index) => ({
      // ...item,
      // sr: index + 1,
      workType: item?.workType,
      description: item?.description,
      startDate: formatDate(item?.startDate),
      endDate: formatDate(item?.endDate),
      responsible: item?.responsible,
      verificationMean: item?.verificationMean,

      workPlanSubActivities: item.workPlanSubActivities.map(
        ({ id, verificationMeanId,workType, ...subItem }, index2) => ({
        // ...subItem,
          // sr: index2 + 1,
          workType: workType,
          description: subItem?.description,
          responsible: subItem?.responsible,
          startDate: formatDate(subItem?.startDate),
          endDate: formatDate(subItem?.endDate),
          verificationMean: subItem?.verificationMean,
        })
      ),
    }));
  };

  useEffect(() => {
    if (extension) {

      setRows(formateData(initialWorkPlans ?? []));
    }
  }, [initialWorkPlans]);
  return (
    <>
      <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded">
        {/* <Form
          formData={getFieldsByCategory(
            extension ? extensionFields : applicationFields,
            "workPlan"
          )}
          withValidation={false}
        /> */}

        {extension && (
          <>
            <h3>Initial Work Plan</h3>
            <ReadableTable
              headers={workplanHeader}
              data={rows ?? []}
              isCollapsible={true}
              label={"Details of Initial Work Plan Activities"}
            />
          </>
        )}
        {/*TODO: IN PROGRESS*/}
        <ActivityAndSubActivityTable extension={extension} />
      </div>
      {!extension && (
        <>
          <div className="col-md-12 shadow-sm bg-white mb-2 p-4 rounded inputTableFieldAuto inputTableOverflow">
            <Form
              formData={getFieldsByCategory(
                applicationFields,
                "logicalFramework"
              )}
              withValidation={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default WorkAndPlanForm;
