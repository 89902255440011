import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/loader";
import usePageMeta from "../../hooks/usePageMeta";
import { getRecords } from "../../redux/reducers";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const UserManagement = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, created } = useSelector((state) => state.records);
  const { data = {} } = useSelector((state) => state.records.current);

  useEffect(() => {
    getUserManagement();
  }, [id, created]);

  useEffect(() => {
    setMeta({ title: data?.name });
  }, [data?.name]);

  const getUserManagement = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/User/${id}/OrganizationUser`,
        appendCurrentReducerKey: "data",
      },
    });
  };

  return (
    <div className="px-5 py-5 mt-5">
      <div className=" d-flex flex-row justify-content-end ">
        <Icon.X
          size={32}
          color="red"
          onClick={() => {
            navigate(-1);
          }}
          className="cursor-pointer"
        />
      </div>
      <div
        className="bg-white shadow-sm rounded p-5"
        style={{ marginLeft: "10px" }}
      >
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col col-lg-10 mb-4 mb-lg-0">
            <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
              <div className="row g-0">
                <div className="col-md-4 gradient-custom  d-flex flex-column align-items-center justify-content-center ">
                  <img
                    // src={
                    //   userDetail?.imageUrl
                    //     ? `${baseUrl}${userDetail?.imageUrl}`
                    //     : require("../../assets/profile.png")
                    // }

                    src={require("../../assets/profile.png")}
                    alt="Avatar"
                    className="img-fluid"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                  {/* <h5>{userDetail?.name}</h5>
                  <p>{userDetail?.designation}</p> */}
                </div>

                <div className="col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="card-body p-4 col-md-10 justify-content-center">
                    <span
                      className="font-weight-bold "
                      style={{ fontSize: "18px" }}
                    >
                      User Details
                    </span>

                    <hr className="mt-0 mb-4" />
                    <div className="pt-1">
                      <div className="col-md-12 mb-3">
                        <h6>Full Name</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {data?.name ?? "Loading.."}
                        </p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Email</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {data?.email ?? "Loading.."}
                        </p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Phone</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {data?.phone ?? "Loading.."}
                        </p>
                      </div>
                      {data?.role === "NGO User" && (
                        <div className="col-md-12 mb-3">
                          <h6>Organization</h6>
                          <p className="text-muted border p-2 rounded-1">
                            {data?.userOrganizationResponse?.organizationName ??
                              "Loading.."}
                          </p>
                        </div>
                      )}
                      <div className="col-md-12 mb-3">
                        <h6>Role</h6>
                        <p className="text-muted border p-2 rounded-1">
                          {data?.role ?? "Loading.."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
