import React from "react";
import { ThreeDots } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const DropdownButton = ({
  actions = [],
  rowId,
  isExtension,
  applicationStatus,
  ...otherProps
}) => {
  return (
    <div className="cursor-pointer">
      <div className="btn-group dropstart">
        <button
          className="btn btn-sm dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <ThreeDots width={30} height={30} />
        </button>
        <ul className="dropdown-menu">
          {actions.map((action, index) => (
            <div
              key={index}
              className={`dropdown-item ${action.className || ""}`}
              onClick={() =>
                action.onClick(rowId, isExtension, applicationStatus)
              }
            >
              {action.icon && <action.icon className="mr-2" />} {action.label}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownButton;
