import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "./table.scss";
import { object } from "prop-types";

const Table = ({
  columns = [],
  data = [],
  styles = {},
  customColumnNames = "",
  multiSheetKey = "",
  show = false,
  ...otherProps
}) => {
  function downloadXLS() {
    const XLSX = window.XLSX;
    const ws = XLSX.utils.json_to_sheet([...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "report");
    XLSX.writeFile(wb, "records.xlsx");
  }

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={{ ...customStyles, ...styles }}
      pagination
      dense
      {...otherProps}
    />
  );
};

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#F8F9FA",
      paddingBottom: 10,
      color: "#162E4A",
      paddingTop: 10,
      fontSize: "14px",
    },
  },
  cells: {
    style: {
      padding: 16,
      fontSize: 15,
      maxWidth: 100,
    },
  },
};

export default Table;
