import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithPageHeader from "../../components/ui/withPageHeader";
import Loader from "../../components/loader/loader";
import Table from "../../components/table/Table";
import { customStyles } from "../../assets/styles/CustomStyle";
import { ReportColumns } from "../../assets/columns/ReportColumns";
import FilterIcon from "../../assets/images/Filter.svg";
import ReportFilters from "../../components/Filters/ReportFilters";
import "./Report.scss";
import { clearForm, getRecords, resetData } from "../../redux/reducers";
const Report = () => {
  const { loading, current } = useSelector((state) => state.records);
  const report = current?.report;
  const { values } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const formValues = values;
  const [showFilters, setShowFilters] = useState(false);
  const columns = [...ReportColumns];

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/Dashboard/NOCProjectSummary",
        appendCurrentReducerKey: "report",
      },
    });
  }, []);

  const onFilterClick = () => {
    setShowFilters(!showFilters);
    dispatch({ type: resetData.type });
    dispatch({ type: clearForm.type });
  };
  return (
    <div className="px-4 py-2 mt-5">
      <Loader loading={loading} />
      <WithPageHeader
        heading="Reports"
        text="Review and manage incomming applications"
        buttons={[]}
      />
      <div className="bg-white shadow-sm rounded p-3 table-container-anim">
        {/* <div
          id="report-filters"
          className={`collapse ${showFilters ? "show animate-fade-in" : ""}`}
        >
          <ReportFilters />
        </div>
        <div className="float-end mt-2 mb-2">
          <button
            onClick={onFilterClick}
            className="btn btn-success bg-white border-2 "
            aria-expanded={showFilters}
            aria-controls="report-filters"
          >
            <img src={FilterIcon} alt="Filter Icon" />
          </button>
          {showFilters && <button className="btn btn-success">Submit</button>}
        </div> */}

        <div className="mt-3">
          <Table
            data={report}
            columns={columns}
            customStyles={customStyles}
            dense
          />
        </div>
      </div>
    </div>
  );
};

export default Report;
