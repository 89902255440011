import REPORT_STATUS from "../enums/REPORT_STATUS";

const reportStatus = {
  [REPORT_STATUS.ALL]: "All",
  [REPORT_STATUS.ADC_APPROVED]: "ADC Approved",
  [REPORT_STATUS.REVISION_REQUIRED]: "Revision Required",
  [REPORT_STATUS.PENDING]: "Pending",
  [REPORT_STATUS.REJECTED]: "Rejected",
  [REPORT_STATUS.UNDER_PROCESS]: "Under Process",
};
export default reportStatus;
